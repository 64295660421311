export const validate = (values, formType = "") => {
  const errors = {};
  const phoneregex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;


    if (!values.phone && formType === "guest") {
      errors.phone = "Phone number is required!";
    } else if (
      !phoneregex.test(values.phone) &&
      formType === "guest"
    ) {
      errors.phone = "Phone number should be of 10 digits!";
    }


  if (!values.email && formType === "login") {
    errors.email = "Email is required!";
  } else if (!emailregex.test(values.email) && formType === "login") {
    errors.email = "This is not a valid email format!";
  }

  if (!values.phone && formType === "login") {
    errors.phone = "Phone is required!";
  } else if (
    !phoneregex.test(values.phone) &&
    formType === "login"
  ) {
    errors.phone = "Phone number should be of 10 digits!";
  }
    
  if (!values.password && formType === "login") {
    errors.password = "Password is required";
  }

  if (!values.isOtpVerified && values.otp && formType === "guest") {
    errors.otp = "OTP is not verified!";
  }

    if (!values.email && formType === "gettingStartedLoginEmail") {
      errors.email = "Email is required!";
    } else if (!emailregex.test(values.email) && formType === "gettingStartedLoginEmail") {
      errors.email = "This is not a valid email format!";
    }

    if (!values.phone && formType === "gettingStartedLoginPhone") {
      errors.phone = "Phone number is required!";
    } else if (
      !phoneregex.test(values.phone) &&
      formType === "gettingStartedLoginPhone"
    ) {
      errors.phone = "Phone number should be of 10 digits!";
    }

  return errors;
};
