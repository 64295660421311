import React, { useEffect, useState } from "react";
import DashBoardHeader from "../components/layouts/DashBoardHeader";
import DashBoardFooter from "../components/layouts/DashBoardFooter";
import DashBoardContent from "../components/layouts/DashBoardContent";
import MessengerSlider from "../components/layouts/MessengerSlider";
import MeetingInfo from "../components/layouts/MeetingInfo";
import LoginPage from "./LoginPage";
import styled from "styled-components";
import { connect } from "react-redux";
import DemoLoginPage from "./DemoLoginPage";
import axios from "axios";
import DemoTable from "../components/layouts/DemoTable";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/layouts/Loading";
import DemoClassLogin from "./DemoClassLogin";
import Slider from "react-slick";
import { BookDemoCarousal } from "../utils/carouselData";
import Carousel from "../components/common/Carousel";
import { BookMyDemoCarousal } from "../utils/carouselData";
import { demoLoginBrandDetails } from "../utils/brandUtilDetails";
const DemoDashboard = ({ isAuthenticated }) => {
  const { id } = useParams();

  let navigate = useNavigate()

  useEffect(() => {
      if (!isAuthenticated) {
        navigate("/demo");
      }
  },[isAuthenticated])

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};
  const domainName = window.location.hostname.split(".")[1];

  const brand = demoLoginBrandDetails.getBrand(domainName);

  const brandArray = ["IELTS NINJA", "FLUENT LIFE"]
  let isCarouselPresent = brandArray.includes(brand)
  
  return (
    <DashBoardContainer>
      <DashBoardHeader />
      <DashBoardContent>
      {id === undefined ? <DemoTable/> : <MeetingInfo/>}
      </DashBoardContent>
      {isCarouselPresent ? (<Slider {...settings} className="slider">
						{BookMyDemoCarousal[brand].map((item, index) => {
							return (
								
									<Carousel item={item} />
								
							);
						})}
					</Slider>) : null}
    </DashBoardContainer>
  );
};

const DashBoardContainer = styled.div`
 background:#F9F9FA;
.slick-arrow{
  display:none !important
}
.slider{
  background:#F9F9FA;
  width: 100%;
    margin: 0 auto;
    max-width: 800px;
    padding: 0 0.5rem;
}
`;

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps)(DemoDashboard);
