import React, { useEffect, useRef } from "react";
import { useState } from "react";
import OtpPopUp from "../components/layouts/OtpPopUp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import {
  login,
  guestRegister,
  demoGuestLogin,
  validateClassLink,
  verifyOtpGuestLogin,
} from "../actions/auth";
import { validate } from "../utils/validation";
import styles from "../styles/css/layouts/loginPage.module.css";
import { demoLoginBrandDetails } from "../utils/brandUtilDetails";

const DemoClassLogin = ({
  guestRegister,
  auth: { isAuthenticated },
  demoGuestLogin,
  error,
}) => {
  const domainName = window.location.hostname.split(".")[1];

  const brand = demoLoginBrandDetails.getBrand(domainName);


  const { loginFormBackgroundColor, loginButtonBackgroundColor, loginPageImage, loginPageHeading, loginPageDescription, brandLogo, brandWebsiteUrl } = demoLoginBrandDetails.getDemoLoginBrandDetails(brand)

  
  const [otpPopUp, setOtpPopUp] = useState(false);

  const otpPopUpToggle = () => setOtpPopUp(!otpPopUp);

  const [loginFormData, setLoginFormData] = useState({
    phone: "",
  });

  const { phone } = loginFormData;

  const onLoginChange = (e) => {
    setLoginFormData({ ...loginFormData, [e.target.name]: e.target.value });
  };

  const validatePhone = validate(loginFormData, "guest");
  useEffect(() => {
    if (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  }, [error]);


  const onGuestSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(validatePhone).length === 0) {
      guestRegister(phone);
      localStorage.clear();
      localStorage.setItem("phone", phone);
      // demoGuestLogin("username", phone, "DEMO");
      otpPopUpToggle();
    } else {
      toast.error(validatePhone.phone, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  return (
    <>
      <div className={`${styles.loginMain}`}>
        <ToastContainer />
        <div className={`${styles.loginMainOne}`}>
          <div className={`${styles.loginMainWrapper}`}>
            <div className={`${styles.loginLeftWrapper}`}>
              <h1>{loginPageHeading}</h1>
              <div className={`${styles.loginBrandLogo}`}>
                <img src={brandLogo} alt="Brand-Logo" />
              </div>
              <h1>{loginPageDescription}</h1>
              <div className={`${styles.loginBrandImage}`}>
                <img src={loginPageImage} alt="Brand-Image" />
              </div>
            </div>
            <div className={`${styles.loginRightWrapper}`}>
              <form
                className={`${styles.loginForm}`}
                onSubmit={onGuestSubmit}
                style={{ background: `${loginFormBackgroundColor}` }}
              >
                <div className={`${styles.loginFormBrandLogo}`}>
                  <img src={brandLogo} alt="Brand-Logo" />
                </div>
                <h2>Login</h2>
                <p>to see class details </p>
                <input
                  className={`${styles.formInputs}`}
                  type="phone"
                  name="phone"
                  placeholder="Phone Number"
                  onChange={onLoginChange}
                  value={loginFormData.phone}
                />
                <button
                  className={`${styles.formButtons}`}
                  style={{ background: `${loginButtonBackgroundColor}` }}
                  id={`${styles.joinClassroomButton}`}
                  type="submit"
                >
                  <span className={`${styles.joinClassroomIcon}`}>
                    <img
                      src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/5c279f51a264fdade78fe622ea8ade78.1673589918561130"
                      alt="Join-Classroom-Icon"
                    />
                  </span>{" "}
                  Join Classroom
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <OtpPopUp show={otpPopUp} close={otpPopUpToggle} classType="Demo" brandDetails={ brand } />
    </>
  );
};

const mapStateToProps = (state) => ({
  guestDetails: state.auth?.guest_regester,
  auth: state.auth,
  class_link_details: state.auth.link_details,
  error: state.auth.error,
});

export default connect(mapStateToProps, {
  login,
  guestRegister,
  demoGuestLogin,
  validateClassLink,
  verifyOtpGuestLogin,
})(DemoClassLogin);
