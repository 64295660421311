export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const AUTH_ERROR = "AUTH_ERROR";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const MESSAGE_RECEIVED = "MESSAGE_RECEIVED";
export const GET_MESSAGES = "GET_MESSAGES";
export const GUEST_REGISTER_SUCCESS = "GUEST_REGISTER_SUCCESS";
export const GUEST_REGISTER_FAIL = "GUEST_REGISTER_FAIL";
export const VALIDATE_CLASS_LINK_SUCCESS = "VALIDATE_CLASS_LINK_SUCCESS";
export const VALIDATE_CLASS_LINK_FAIL = "VALIDATE_CLASS_LINK_FAIL";
export const MEETING_DETAILS_SUCCESS = "MEETING_DETAILS_SUCCESS";
export const MEETING_DETAILS_FAIL = "MEETING_DETAILS_FAIL";
export const VERIFY_OTP_GUEST_LOGIN_SUCCESS = "VERIFY_OTP_GUEST_LOGIN_SUCCESS";
export const VERIFY_OTP_GUEST_LOGIN_FAIL = "VERIFY_OTP_GUEST_LOGIN_FAIL";
export const SLOT_BOOKING_QUESTION_SUCCESS = "SLOT_BOOKING_QUESTION_SUCCESS";
export const SLOT_BOOKING_QUESTION_FAIL = "SLOT_BOOKING_QUESTION_FAIL";
export const VALID_BOOK_MY_DEMO = "VALID_BOOK_MY_DEMO";
export const INVALID_BOOK_MY_DEMO = "INVALID_BOOK_MY_DEMO";
export const GET_SLOT_BOOKING_SUCCESS = "GET_SLOT_BOOKING_SUCCESS";
export const GET_SLOT_BOOKING_FAIL = "GET_SLOT_BOOKING_FAIL";
export const SLOT_BOOKING_SUCCESS = "SLOT_BOOKING_SUCCESS";
export const SLOT_BOOKING_FAIL = "SLOT_BOOKING_FAIL";
export const POST_SLOT_BOOKING_QUESTION_SUCCESS =
	"POST_SLOT_BOOKING_QUESTION_SUCCESS";
export const POST_SLOT_BOOKING_QUESTION_FALI =
	"POST_SLOT_BOOKING_QUESTION_FALI";
export const GET_BOOKED_DEMO_DETAILS_SUCCESS =
	"GET_BOOKED_DEMO_DETAILS_SUCCESS";
export const GET_BOOKED_DEMO_DETAILS_FAIL = "GET_BOOKED_DEMO_DETAILS_FAIL";
export const GETTING_STARTED_LOGIN_SUCCESS = "GETTING_STARTED_LOGIN_SUCCESS";
export const GETTING_STARTED_LOGIN_FAIL = "GETTING_STARTED_LOGIN_FAIL";
export const GETTING_STARTED_VERIFY_OTP_SUCCESS = "GETTING_STARTED_VERIFY_OTP_SUCCESS";
export const GETTING_STARTED_VERIFY_OTP_FAIL = "GETTING_STARTED_VERIFY_OTP_FAIL";
export const GETTING_STARTED_SIGNUP_SUCCESS = "GETTING_STARTED_SIGNUP_SUCCESS";
export const GETTING_STARTED_SIGNUP_FAIL = "GETTING_STARTED_SIGNUP_FAIL";
export const GETTING_REGULAR_AND_BATCH_CLASS_DETAILS_SUCCESS =
	"GETTING_REGULAR_AND_BATCH_CLASS_DETAILS_SUCCESS";
  export const GETTING_REGULAR_AND_BATCH_CLASS_DETAILS_FAIL =
	  "GETTING_REGULAR_AND_BATCH_CLASS_DETAILS_FAIL";
export const GETTING_DEMO_CLASS_DETAILS_SUCCESS = "GETTING_DEMO_CLASS_DETAILS_SUCCESS";
export const GETTING_DEMO_CLASS_DETAILS_FAIL = "GETTING_DEMO_CLASS_DETAILS_FAIL";
