import React from "react";
import styled from "styled-components";
const FlashMessage = ({type,message}) => {
	return (
		<FlashMessageStyled>
			<div className={`message  message--${type}`}>
				<p>
				{message}
				</p>
			</div>

			{/* <div class="message  message--warning">
				<p>
					Pastrami fatback frankfurter ground round pork belly.
					Meatloaf landjaeger boudin pork strip steak. Bresaola tail
					capicola, salami landjaeger jerky pork loin tenderloin bacon
					filet mignon.
				</p>
			</div>

			<div class="message  message--success">
				<p>
					Pig tri-tip ground round venison bresaola fatback prosciutto
					porchetta. Bresaola strip steak rump, turkey meatball pork
					pig t-bone porchetta cupim ham hock pancetta ham.
				</p>
			</div> */}
		</FlashMessageStyled>
	);
};
const FlashMessageStyled = styled.div`
display:flex;
align-items: center;
justify-content: center;
width: 100%;
/* margin: 20px; */
margin-top:5rem;
  .message {
    background-color: white;
    padding: 1em 1em 1em 1.5em;
    border-left-width: 6px;
    border-left-style: solid;
    border-radius: 3px;
    position: relative;
    line-height: 1.5;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);

    & + & {
      margin-top: 2em;
    }

    &:before {
      color: white;
      width: 1.5em;
      height: 1.5em;
      position: absolute;
      top: 1em;
      left: -3px;
      border-radius: 50%;
      transform: translateX(-50%);
      font-weight: bold;
      line-height: 1.5;
      text-align: center;
    }

    p {
      margin: 0 0 1em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .message--error {
    border-left-color: firebrick;

    &:before {
      background-color: firebrick;
      content: "‼";
    }
  }

  .message--warning {
    border-left-color: darkorange;

    &:before {
      background-color: darkorange;
      content: "!";
    }
  }

  .message--success {
    border-left-color: darkolivegreen;

    &:before {
      background-color: darkolivegreen;
      content: "✔";
    }
  }

  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    height: 100%;
  }

  body {
    background-color: gray;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
export default FlashMessage;
