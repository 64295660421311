import React from "react";
import "../../styles/css/loading.css";

const Loading = () => {
  return (
    <div>
      <div className="loading_container">
        <div className="spinner">
          <div className="spinner-item"></div>
          <div className="spinner-item"></div>
          <div className="spinner-item"></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
