import React from "react";
import reschedule from "../../assets/images/reschedule.png";
import logo from "../../assets/images/ielts_name.png";
import styled from "styled-components";
function ReschedleDemo() {
	return (
		<ReschedleDemoStyled>
			<div className="main">
				<div className="header-top">
					<div className="header-inner">
						<img src={logo} alt="" />
					</div>
				</div>
				<div className="mid">
					<div className="mid-inner">
						<img src={reschedule} alt="" />
						<p className="p1">This action will cancel</p>
						<p className="p2">your current slot booking of:</p>
						<p className="p3">Friday, 13 Nov 2020, 3.30 PM</p>
						<p className="p4">Confirm Rescheduling?</p>
						<div className="inner-btn">
							<button className="confirm">Confirm</button>
							<button
								onclick="location.href='/booked-demo/?id={{demo}}';"
								className="cancel"
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>
		</ReschedleDemoStyled>
	);
}

const ReschedleDemoStyled = styled.div`
	background-color: #fdf4f4;
	.header-top {
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;
		img {
			width: 190px;
			height: 22px;
		}
	}
	.header-inner {
		width: 1110px;
	}
	.mid {
		display: flex;
		justify-content: center;
	}
	.mid-inner {
		width: 410px;
		height: 430px;
		background-color: white;
		display: flex;
		align-items: center;
		flex-direction: column;
		margin-top: 48px;
		margin-bottom: 50px;
		img {
			width: 109px;
			height: 115px;
			margin-top: 30px;
		}
		.p1 {
			text-align: center;
			font-size: 16px;
			font-family: "Montserrat";
			font-weight: 300;
			margin: 0 0;
			margin-bottom: 5px;
			margin-top: 30px;
		}
		.p2 {
			text-align: center;
			font-size: 16px;
			font-family: "Montserrat";
			font-weight: 300;
			margin: 0 0;
		}
		.p3 {
			text-align: center;
			font-size: 18px;
			font-family: "Montserrat";
			font-weight: 500;
			margin-bottom: 8px;
		}
		.p4 {
			text-align: center;
			font-size: 18px;
			font-family: "Montserrat";
			font-weight: 500;
			margin-bottom: 40px;
		}
	}
	.inner-btn {
		button {
			width: 160px;
			height: 45px;
			border-radius: 5px;
			cursor: pointer;
		}
	}
	.confirm {
		background-color: #ff1d1d;
		color: white;
		border: none;
		margin-right: 30px;
		font-size: 18px;
		font-family: "Montserrat";
		font-weight: 600;
	}
	.cancel {
		background-color: white;
		color: #ff1d1d;
		border: 1px solid #ff1d1d;
		font-size: 18px;
		font-family: "Montserrat";
		font-weight: 600;
	}
	@media screen and (max-width: 1160px) and (min-width: 850px) {
		.header-inner {
			width: 800px;
		}
	}
	@media screen and (max-width: 850px) and (min-width: 650px) {
		.header-inner {
			width: 620px;
		}
	}
	@media screen and (max-width: 700px) {
		.header-inner {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	@media screen and (max-width: 400px) {
		.mid-inner {
			width: 95%;
			img {
				width: 78px;
				height: 82px;
			}
			.p1 {
				font-size: 15px;
			}
			.p2 {
				font-size: 15px;
			}
		}
		.inner-btn {
			button {
				width: 130px;
				height: 45px;
			}
		}
		.confirm {
			margin-right: 10px;
		}
	}
	@media screen and (max-width: 306px) {
		.inner-btn {
			button {
				width: 100px;
				height: 40px;
				font-size: 15px;
			}
		}
	}
`;
export default ReschedleDemo;
