export const BookDemoCarousal = [
	{
		id: 1,
		backgroundImage:
			"https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/3c7d4b979a6e4853bdeb3f17bec62a20.svg",
		backgroundStyle: {
			background: "linear-gradient(180deg,#b0faff -7.14%,#36afca 67.63%,#197c92 100%)",
			top: "50%",
			left: "18%",
		},
		headerTitle: "Welcome to Fluent Life",
		headerSubTitle: "App that focuses on making people fluent in the english language",
		headerSpan: "Download the app now",
		img: "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/9ff500731fe446d0aa3cb389aa032b2b.svg",
		button: "",
		redirect_link: "",
		customStyle: {
			headerColor: "#1f688c",
			paraColor: "#194C65",
		},
	},
	{
		id: 2,
		backgroundImage:
			"https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/b205d1ebc069491496189902aa1572df.1658759945590256",
		backgroundStyle: {
			background: "linear-gradient(138.45deg, #FFF2CF 0%, #2FA7C1 78.91%)",
			top: "16%",
			left: "50%",
		},
		headerTitle: "Live Events",
		headerSubTitle: "Take part in community events and competitions daily",
		headerSpan: "",
		img: "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/c25e910d175d4541ad441fed49749fe6.1658759896618256",
		button: "Participate Now",
		redirect_link: "https://fluentlife.page.link/ArhK",
		customStyle: {
			headerColor: "#165C7A",
			paraColor: "#194C65",
		},
	},
	{
		id: 3,
		backgroundImage:
			"https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/cc31b10034d04420a06ceaf2a20f206f.svg",
		backgroundStyle: {
			background: "linear-gradient(138.45deg, #9D4943 0%, #FFE3C2 78.91%)",
			top: "0%",
			left: "0%",
		},
		headerTitle: "AI Friend Bots",
		headerSubTitle: "Chat with 20+ unique AI Bots to learn conversational grammer on the fly",
		headerSpan: "",
		img: "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/3197f0e81e35497db765cb11ca389fee.svg",
		button: "Chat Now",
		redirect_link: "https://fluentlife.page.link/eQkP",
		customStyle: {
			headerColor: "#FFFFFF",
			paraColor: "#FFFFFF",
		},
	},
	{
		id: 4,
		backgroundImage:
			"https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/448807b7a76e4ae2a2dd82f887ff827c.svg",
		backgroundStyle: {
			background: "linear-gradient(138.45deg, #7B1850 0%, #FFC2C2 78.91%)",
			top: "0%",
			left: "20%",
		},
		headerTitle: "Daily Masterclass",
		headerSubTitle: "Attend informative and interactive classes with expert trainers",
		headerSpan: "",
		img: "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/77389b934f864ea9a3a3b60bee4e816e.svg",
		button: "Attend Masterclass",
		redirect_link: "https://fluentlife.page.link/TFWs",
		customStyle: {
			headerColor: "#FFFFFF",
			paraColor: "#FFFFFF",
		},
	},
	{
		id: 5,
		backgroundImage:
			"https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/32ea57b5e9644f07b37a433bb92fb206.svg",
		backgroundStyle: {
			background: "linear-gradient(134.5deg, #0C706A 0%, #C2D3FF 99.97%)",
			top: "50%",
			left: "20%",
		},
		headerTitle: "Fluency Meter",
		headerSubTitle: "Assess your english skills with Fluency test",
		headerSpan: "",
		img: "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/88e151060b1f48e1a820319ea9b0b00b.svg",
		button: "Practice Fluency",
		redirect_link: "https://fluentlife.page.link/uCRE",
		customStyle: {
			headerColor: "#FFFFFF",
			paraColor: "#FFFFFF",
		},
	},
	{
		id: 6,
		backgroundImage:
			"https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/85147bfda00f44b7a1c8e6ba455bcd0e.svg",
		backgroundStyle: {
			background: "linear-gradient(121.85deg, #263238 -4.74%, #7AD7FF 113.05%)",
			top: "10%",
			left: "0%",
		},
		headerTitle: "Speaking Partner",
		customStyle: {
			headerColor: "#FFFFFF",
			paraColor: "#FFFFFF",
		},
		headerSubTitle: "Speak with your peers on topics and practice you conversational english",
		headerSpan: "",
		img: "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/25da999d74d544b78ab5aad09a2a7e1e.svg",
		button: "Practice with peers",
		redirect_link: "https://fluentlife.page.link/5btp",
	},
];

export const BookDemoFluentCarousal = [
	{
		id: 1,
		backgroundImage: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/e737834b67a7ee088607945d13722acd.svg",
		backgroundStyle: {
			background: "linear-gradient(121.85deg, #263238 -4.74%, #7AD7FF 113.05%)",

			top: "-40%",
			left: "0%",
		},
		headerTitle: "Speaking Partner",
		headerSubTitle: "Speak with your peers on topics and practice you conversational english",
		headerSpan: "",
		img: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/c795459d9b7c2aa3d704214bab762d9c.svg",
		button: "Practice with peers",
		redirect_link: "https://fluentlife.page.link/5btp",
		customStyle: {
			headerColor: "#FFFFFF",
			paraColor: "#FFFFFF",
		},
	},
	// {
	// 	id: 2,
	// 	backgroundImage:
	// 		"https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/32ea57b5e9644f07b37a433bb92fb206.svg",
	// 	backgroundStyle: {
	// 		background: "linear-gradient(134.5deg, #0C706A 0%, #C2D3FF 99.97%)",
	// 		top: "50%",
	// 		left: "20%",
	// 	},
	// 	headerTitle: "Fluency Meter",
	// 	headerSubTitle: "Assess your english skills with Fluency test",
	// 	headerSpan: "",
	// 	img: "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/88e151060b1f48e1a820319ea9b0b00b.svg",
	// 	button: "Practice Fluency",
	// 	redirect_link: "https://fluentlife.page.link/uCRE",
	// 	customStyle: {
	// 		headerColor: "#FFFFFF",
	// 		paraColor: "#FFFFFF",
	// 	},
	// },
	// {
	// 	id: 3,
	// 	backgroundImage:
	// 		"https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/448807b7a76e4ae2a2dd82f887ff827c.svg",
	// 	backgroundStyle: {
	// 		background:
	// 			"linear-gradient(138.45deg, #7B1850 0%, #FFC2C2 78.91%)",
	// 		top: "0%",
	// 		left: "20%",
	// 	},
	// 	headerTitle: "Daily Masterclass",
	// 	headerSubTitle:
	// 		"Attend informative and interactive classes with expert trainers",
	// 	headerSpan: "",
	// 	img: "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/77389b934f864ea9a3a3b60bee4e816e.svg",
	// 	button: "Attend Masterclass",
	// 	redirect_link: "https://fluentlife.page.link/TFWs",
	// 	customStyle: {
	// 		headerColor: "#FFFFFF",
	// 		paraColor: "#FFFFFF",
	// 	},
	// },
];

export const BookDemoIeltsCarousal = [
	{
		id: 1,
		backgroundImage: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/e737834b67a7ee088607945d13722acd.svg",
		backgroundStyle: {
			background: "linear-gradient(121.85deg, #263238 -4.74%, #7AD7FF 113.05%)",

			top: "7%",
			left: "0%",
		},
		headerTitle: "Band <br/> Predictor Test",
		headerSubTitle: "Put yourself to the test and find out how much you would score in the IELTS!",
		headerSpan: "",
		img: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/5bc846bb00158757ca90619cb39d96ff.svg",
		button: "Evaluate Now",
		redirect_link: "https://ieltsninjaapp.page.link/BandPredictorTest",
		customStyle: {
			headerColor: "#FFFFFF",
			paraColor: "#FFFFFF",
		},
	},
	// {
	// 	id: 2,
	// 	backgroundImage:
	// 		"https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/32ea57b5e9644f07b37a433bb92fb206.svg",
	// 	backgroundStyle: {
	// 		background: "linear-gradient(134.5deg, #0C706A 0%, #C2D3FF 99.97%)",
	// 		top: "50%",
	// 		left: "20%",
	// 	},
	// 	headerTitle: "Essay <br/> Evaluation",
	// 	headerSubTitle:
	// 		"Attempt IELTS writing Task 2 Essays and calculate your writing Band Score",
	// 	headerSpan: "",
	// 	img: "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/88e151060b1f48e1a820319ea9b0b00b.svg",
	// 	button: "Evaluate Now",
	// 	redirect_link: "https://fluentlife.page.link/uCRE",
	// 	customStyle: {
	// 		headerColor: "#FFFFFF",
	// 		paraColor: "#FFFFFF",
	// 	},
	// },
	{
		id: 3,
		backgroundImage:
			"https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/448807b7a76e4ae2a2dd82f887ff827c.svg",
		backgroundStyle: {
			background: "linear-gradient(138.45deg, #7B1850 0%, #FFC2C2 78.91%)",
			top: "0%",
			left: "20%",
		},
		headerTitle: "Cue Card <br/> Practice",
		headerSubTitle: "Speak with your peers on topics and practice you conversational english",
		headerSpan: "",
		img: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/1187d99b625674233b323ba9c45d1b9d.1663053601158138",
		button: "Evaluate Now",
		redirect_link: "https://ieltsninjaapp.page.link/CueCardPractice",
		customStyle: {
			headerColor: "#FFFFFF",
			paraColor: "#FFFFFF",
		},
	},
	// {
	// 	id: 4,
	// 	backgroundImage:
	// 		"https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/448807b7a76e4ae2a2dd82f887ff827c.svg",
	// 	backgroundStyle: {
	// 		background:
	// 			"linear-gradient(138.45deg, #7B1850 0%, #FFC2C2 78.91%)",
	// 		top: "0%",
	// 		left: "20%",
	// 	},
	// 	headerTitle: "Stategy  <br/> Videos",
	// 	headerSubTitle:
	// 		"Learn the best appraoches to IELTS preparation directly from our experts",
	// 	headerSpan: "",
	// 	img: "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/77389b934f864ea9a3a3b60bee4e816e.svg",
	// 	button: "View Now",
	// 	redirect_link: "https://fluentlife.page.link/TFWs",
	// 	customStyle: {
	// 		headerColor: "#FFFFFF",
	// 		paraColor: "#FFFFFF",
	// 	},
	// },
];

const IeltsCarousalHeader = {
	header: "Before the demo, try computer-based test for free now!",
};

const FluentCarousalHeader = {
	header: "Before the demo, try Speaking Partner for free now!",
};
export const BookMyDemoCarousal = {
	"IELTS NINJA": BookDemoIeltsCarousal,
	"FLUENT LIFE": BookDemoCarousal,
};

export const BookMyDemoCarousalHeader = {
	"IELTS NINJA": IeltsCarousalHeader,
	"FLUENT LIFE": FluentCarousalHeader,
};

export const BookMyDemoCard = {
  "THE FLUENTLIFE": [
    {
      id: "1",
      img: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/b17e6e9f0a0b606972c08d3d01f5f4a1.png",
      footerData: "",
      link: "https://fluentlife.page.link/bQ9u",
    },
  ],
  "IELTS NINJA": [
    {
      id: "1",
      img: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/b5cd94a028a41e477fc8162477b2ed74.1665991550555179",
      footerData: "",
      link: "https://ieltsninjaapp.page.link/s5JG",
    },
  ],
  "UPSC PATHSHALA": [
    {
      id: "1",
      img: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/70c19ac9fb7de011cf014af93ca99800.svg",
      footerData: "View daily news reader",
      link: "https://upscpathshala.page.link/qLbj",
    },
    {
      id: "1",
      img: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/da89e38d2085d08a94af46c0706b4cae.svg",
      footerData: "View daily quiz",
      link: "https://upscpathshala.page.link/qLbj",
    },
  ],
  "EMAESTER": [
    {
      id: "1",
      img: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/70c19ac9fb7de011cf014af93ca99800.svg",
      footerData: "View daily news reader",
      link: "https://upscpathshala.page.link/qLbj",
    },
    {
      id: "1",
      img: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/da89e38d2085d08a94af46c0706b4cae.svg",
      footerData: "View daily quiz",
      link: "https://upscpathshala.page.link/qLbj",
    },
  ],
};
