import styled from "styled-components";
import React from "react";
import { InputItem } from "../components/common/InputItem";
import { SelectItem } from "../components/common/SelectItem";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import CheckBoxButton from "../components/common/CheckBoxButton";
const Option = (props) => {
	return (
		<div>
			<components.Option {...props}>
				<input
					type="checkbox"
					checked={props.isSelected}
					onChange={() => null}
				/>{" "}
				<label>{props.label}</label>
			</components.Option>
		</div>
	);
};
const SelectComponent = (
	questions,
	optionSelected,
	index,
	handleChangeQuestion,
	handleOnclickCheckOption,
	onChangeMultiSelect,
	selectedcheckOption,
	brandName
) => {
	let { question, type, tag, choices } = questions;
	if (type === "short_text") {
		return (
			<FlexRow4 key={index}>
				<InputItem
					type="text"
					name={tag}
					placeholder={tag}
					padding={"12px 20px 12px 15px"}
					onChange={handleChangeQuestion}
					data-label={question}
				/>
			</FlexRow4>
		);
	} else if (type === "choice") {
		return (
			<FlexRow4 key={index} onChange={handleChangeQuestion}>
				<SelectItem name={tag} data-label={question}>
					<option value="">Select</option>
					{choices.map((choice, index) => {
						return (
							<option key={index} value={choice}>
								{choice}
							</option>
						);
					})}
				</SelectItem>
			</FlexRow4>
		);
	} else if (type === "multi-choice") {
		// object for value and label
		const options = choices.map((choice, index) => {
			return { value: choice, label: choice, tag: tag };
		});

		return (
			<FlexRow4 key={index}>
				<ReactSelect
					options={options}
					isMulti
					closeMenuOnSelect={false}
					hideSelectedOptions={false}
					components={{
						Option,
					}}
					onChange={(selected) =>
						onChangeMultiSelect({ selected, question, tag })
					}
					allowSelectAll={true}
					value={optionSelected}
					className="react-select"
					name={tag}
					data-label={question}
				/>
			</FlexRow4>
		);
	} else if (type === "radio-choice") {
		return (
			<CheckBoxButton
				choices={choices}
				handleOnclickCheckOption={handleOnclickCheckOption}
				question={question}
				tag={tag}
				selectedcheckOption={selectedcheckOption}
				brandName={brandName}
			/>
		);
	}
};
const FlexRow4 = styled.div`
	align-self: stretch;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 17px;
	align-items: center;
	margin: 0px 0px 27px 0px;
`;

export default SelectComponent;
