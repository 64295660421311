import React, { useEffect, useState } from "react";
import styled from "styled-components";
import movies from "../../assets/images/movie.mp4";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { formatDateToDisplay, getMeetingLink } from "../../utils";
import { SlotBookingBrandColor } from "../../utils/slotBookingBrandUtilDetails";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CountdownPopUp from "./CountdownPopUp";
import flGooglePlayImg from "../../assets/images/fl-google-play.png";
import flAppleStoreImg from "../../assets/images/fl-apple-store.png";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

const BookDemoDesktop = ({ bookedDemoDetails, brandName }) => {
	let { primaryColor, secondaryColor, buttonColor, logo, websiteURL } =
		SlotBookingBrandColor.getColor(brandName);
	let bookedDemoDetail = bookedDemoDetails?.data;
	let isDemoDraft = bookedDemoDetail?.is_draft;
	let trainer_dp_link = bookedDemoDetail?.trainer_dp_link || "";
	let trainer_name = bookedDemoDetail?.trainer_name || "";
	let meeting_link = "";
	let demo_date = bookedDemoDetail?.demo_date || "";
	let demo_time = bookedDemoDetail?.demo_slot
		? bookedDemoDetail?.demo_slot[0]
		: "";
	const demo_package_name = bookedDemoDetail?.demo_package_name;
	const demo_course_name = bookedDemoDetail?.demo_course_name;
	// const demo_timestamp = 1654847723000
	const demo_timestamp = bookedDemoDetail?.demo_timestamp * 1000;
	const currentTimestamp = Date.now();
	const remainingTimestamp = demo_timestamp - currentTimestamp;

	const oneMinuteInMillis = 60000;
	const minutesToWait = 30;

	let format_date = formatDateToDisplay(demo_date);
	const fJoinClass = async () => {
		if (remainingTimestamp > oneMinuteInMillis * minutesToWait) {
			setShowCountdownPopup(true);
		} else {
			try {
				meeting_link = await getMeetingLink(bookedDemoDetail.id);
				window.open(meeting_link, "_blank");
			} catch (e) {
				toast.info(e.message || "Meeting Link is not generated yet.", {
					position: toast.POSITION.TOP_LEFT,
				});
			}
		}
	};

	const [showCountdownPopup, setShowCountdownPopup] = useState(false);
	console.log(brandName);
	return (
		<BookDemoDesktopStyle>
			<ToastContainer />
			<div className="main">
				<div className="header-top">
					<div className="header-inner">
						<img src={logo} alt="" />
					</div>
				</div>
				<div className="mid">
					<div
						className="mid-sec1"
						style={{
							background: secondaryColor,
						}}
					>
						<div className="mid-sec1-inner">
							<h1>Congratulations!</h1>
							<p>
								Your
								<span className="fw-bolder"> Trial class </span>
								for {brandName} is confirmed. Alongside are the
								details.
							</p>
						</div>
					</div>
					<div
						className="mid-sec2"
						style={{
							background: secondaryColor,
						}}
					>
						<div className="mid-sec2-inner">
							<div className="mid-sec2-inner-top">
								<h1>DEMO CLASS</h1>
								<h6>Date &amp; Time</h6>
								<p className="p1">
									{format_date} {demo_time}
								</p>
								{isDemoDraft ? (
									<p className="p2">Mentor to be assgined</p>
								) : (
									<React.Fragment>
										<h6>Mentor Details</h6>
										<div className="mentor-details">
											<img src={trainer_dp_link} alt="" />
											<div className="ps-3 mt-3">
												<p className="p2">
													{trainer_name}
												</p>
												<p className="p3"></p>
											</div>
										</div>
									</React.Fragment>
								)}
							</div>
							<div className="mid-sec2-inner-bottom">
								<button
									onClick={fJoinClass}
									className="bt1"
									style={{
										background: buttonColor,
									}}
								>
									Join Class
								</button>
								{/* <button
									className="bt2"
									style={{
										color: buttonColor,
										border: `${buttonColor} 1px solid`,
									}}
								>
									Reschedule
								</button> 
								<button className="bt3">
									Get Ready for Class
								</button> */}
							</div>
						</div>
					</div>
				</div>
				<div
					className="bottom"
					style={{
						background: primaryColor,
					}}
				>
					{brandName === "THE FLUENTLIFE" && (
						<>
							<div className="bottom-sec1">
								<div className="explore-more-main-container">
									<div className="explore-more-left">
										<h2>Meet Our App </h2>
										<h3>
											Your Secret Weapon For 24 / 7
											Learning
										</h3>
										<ul className="checkmark">
											<li>
												Chat with bots and get live
												feedback
											</li>
											<li>
												20+ personalities to practice
												conversations with
											</li>
											<li>
												Get instant evaluation on your
												messages answers
											</li>
											<li>
												Make real conversation with
												Personality bots
											</li>
											<li>
												Increase accuracy with Grammar
												bots
											</li>
											<li>Attend Live master classes</li>
											<li>
												Improve contextual Vocabulary
											</li>
										</ul>
										<div className="app-button">
											<a href="https://play.google.com/store/apps/details?id=com.ufaber.fluentlife">
												<img
													src={flGooglePlayImg}
													alt="Get it on Google Play"
												/>
											</a>
											<a href="https://apps.apple.com/in/app/the-fluent-life/id1547973466">
												<img
													src={flAppleStoreImg}
													alt="Get it on apple Play"
												/>
											</a>
										</div>
										{/* <div className="explore-more-btn">
											<button
												style={{
													background: buttonColor,
												}}
											>
												Explore More
											</button>
										</div> */}
									</div>
									<div className="explore-more-right">
										{/* <div className="explore-more-img"> */}
										<img
											src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/04cbd3acd53045d5b2893c43bdd8c835.png"
											alt=""
										/>
										{/* </div> */}
									</div>
								</div>
							</div>
						</>
					)}

					{brandName === "IELTS NINJA" && (
						<>
							<div className="bottom-sec1">
								<div className="bottom-sec1-inner">
									<p>
										If you are still curious about how this
										class will benefit you, here are some
										videos to help you.
									</p>
								</div>
							</div>
							<>
								<div
									className="bottom-sec2"
									style={{
										background: primaryColor,
									}}
								>
									<div className="bottom-sec2-inner">
										<div className="row">
											<OwlCarousel
												className="owl-carousel owl-theme owl-loaded"
												loop
												items={1.5}
												center={true}
												margin={50}
											>
												<div className="item">
													<ReactPlayer
														className="react-player"
														url="https://player.vimeo.com/video/339555167?h=e7165aa97c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
														width="100%"
														height="100%"
														controls={true}
														touchDrag={true}
														autoWidth={true}
													/>

													<div className="desc">
														Introduction to IELTS
														Writing Task
													</div>
												</div>
												<div className="item">
													<ReactPlayer
														className="react-player"
														url="https://player.vimeo.com/video/339557568?h=9bc894a476&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
														width="100%"
														height="100%"
														controls={true}
													/>

													<div className="desc">
														Step1 Thesis Statements
													</div>
												</div>
												<div className="item">
													<ReactPlayer
														className="react-player"
														url="https://player.vimeo.com/video/339557022?h=a909c6e342&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
														width="100%"
														height="100%"
														controls={true}
													/>

													<div className="desc">
														Writing Task 2 An
														Overview
													</div>
												</div>
											</OwlCarousel>
										</div>
									</div>
								</div>
							</>
						</>
					)}
				</div>
				<div
					className="footer"
					style={{
						background: primaryColor,
					}}
				>
					<p>
						{new Date().getFullYear()} © uFaber, All rights reserved
						uFaber Edutech
					</p>
				</div>
			</div>
			{showCountdownPopup && (
				<CountdownPopUp
					showPopup={setShowCountdownPopup}
					brandName={brandName}
					timestamp={demo_timestamp}
				/>
			)}
		</BookDemoDesktopStyle>
	);
};

const BookDemoDesktopStyle = styled.div`
	.header-top {
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white !important;
		img {
			width: 190px;
			height: 22px;
		}
	}
	.header-inner {
		width: 1110px;
	}
	.mid {
		display: flex;
	}
	.mid-sec1 {
		width: 45%;
		height: 60vh;

		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.mid-sec1-inner {
		width: 344px;
		height: 42%;
		margin-right: 40px;
		h1 {
			font-family: "Montserrat";
			font-size: 40px;
			font-weight: 600;
			margin-bottom: 25px;
		}
		p {
			font-family: "Montserrat";
			font-size: 18px;
			font-weight: 400;
		}
	}
	.mid-sec2 {
		width: 55%;
	}
	.mid-sec2-inner {
		width: 51%;
		box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;

		position: absolute;
		top: 120px;
		background-color: white;
		border-radius: 10px;
	}
	.mid-sec2-inner-top {
		padding-left: 40px;
		h1 {
			font-family: "Montserrat";
			font-size: 18px;
			font-weight: 700;
			margin-top: 20px;
			margin-bottom: 25px;
		}
		h6 {
			font-family: "Montserrat";
			font-size: 15px;
			font-weight: 300;
			color: #7f8396;
		}
		.p1 {
			font-family: "Montserrat";
			font-size: 20px;
			font-weight: 500;
			margin-bottom: 20px;
		}
		img {
			width: 65px;
			height: 65px;
			border-radius: 65px;
		}
		.p2 {
			font-family: "Montserrat";
			font-size: 20px;
			font-weight: 500;
			font-family: "Montserrat";
			font-size: 20px;
			font-weight: 500;
			margin-bottom: 0;
		}
		.p3 {
			font-family: "Montserrat";
			font-size: 14px;
			font-weight: 400;
		}
	}
	.mentor-details {
		display: flex;
		align-items: center;
		margin-top: -5px;
	}
	.mid-sec2-inner-bottom {
		display: flex;
		align-items: center;
		padding-left: 65px;
		border-top: solid 1px #00000029;
		margin-top: 10px;
		padding: 22px 0px 22px 60px;
		.bt1 {
			width: 149px;
			height: 40px;
			font-size: 15px;
			color: white;

			border: none;
			margin-right: 16px;
			border-radius: 5px;
			cursor: pointer;
		}
		.bt2 {
			width: 149px;
			height: 40px;
			font-size: 15px;

			background-color: white;

			margin-right: 16px;
			border-radius: 5px;
			cursor: pointer;
		}
		.bt3 {
			width: 149px;
			height: 40px;
			font-size: 14px;
			color: #1d1d1d;
			background-color: white;
			border: #1d1d1d 1px solid;
			margin-right: 16px;
			border-radius: 5px;
			cursor: pointer;
		}
	}
	.bottom {
		display: flex;
	}
	.bottom-sec1 {
		width: 100%;
		/* height: 320px; */
		height: 90vh;

		display: flex;
		align-items: center;
		justify-content: center;
		/* height: 576px; */
	}
	.bottom-sec1-inner {
		width: 100%;
		height: 125px;
		width: 45%;
		p {
			font-size: 18px;
			font-family: "Montserrat";
			font-weight: 500;
			text-align: center;
		}
	}
	.bottom-sec2 {
		width: 55%;

		height: 576px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.bottom-sec2-inner {
		width: 97%;
		margin-top: 180px;
	}
	.footer {
		height: 0px;

		background-color: #ffeeee;
		text-align: center;
		padding-top: 10px;
		p {
			font-family: "Montserrat";
			font-size: 13px;
			font-weight: 400;
		}
	}
	.play-icon {
		position: absolute;
		left: 140px;
		top: 90px;
		color: #ff1d1d;
		border: solid 1px #ff1d1d;
		width: 57px;
		height: 57px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 25px;
		border-radius: 60px;
		cursor: pointer;
	}
	.pause-icon {
		position: absolute;
		left: 140px;
		top: 90px;
		color: #ff1d1d;
		border: solid 1px #ff1d1d;
		width: 57px;
		height: 57px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 25px;
		border-radius: 60px;
		cursor: pointer;
		display: none;
	}
	.unhidden {
		display: flex !important;
	}
	.hidden {
		display: none !important;
	}
	.owl-stage {
		left: -51px;
	}
	.desc {
		font-size: 16px;
		font-weight: 500;
		margin-top: 10px;
		margin-bottom: 30px;
	}
	.owl-dot {
		margin-right: 10px;
	}
	.mb-mid {
		background-color: #fdf4f4;
		padding: 20px 0;
		h1 {
			font-size: 28px;
			font-family: "Montserrat";
			font-weight: 600;
			text-align: center;
		}
		.p1 {
			font-size: 18px;
			font-family: "Montserrat";
			font-weight: 400;
			text-align: center;
			margin-bottom: 0;
			span {
				font-size: 18px;
				font-family: "Montserrat";
				font-weight: 600;
				text-align: center;
			}
		}
		.p2 {
			font-size: 18px;
			font-family: "Montserrat";
			font-weight: 400;
			text-align: center;
		}
		.p3 {
			font-size: 16px;
			font-family: "Montserrat";
			font-weight: 500;
			text-align: center;
		}
	}
	.mb-mid2 {
		background-color: white;
		padding: 20px 0;
		.p1 {
			font-size: 15px;
			font-family: "Montserrat";
			color: #7f8396;
			padding-left: 15px;
			font-weight: 300;
			margin-bottom: 8px;
		}
		.p2 {
			font-size: 20px;
			font-family: "Montserrat";
			padding-left: 15px;
			font-weight: 500;
		}
		.p3 {
			font-size: 15px;
			font-family: "Montserrat";
			color: #7f8396;
			padding-left: 15px;
			font-weight: 300;
			margin-bottom: 8px;
		}
		img {
			width: 65px;
			height: 65px;
			border-radius: 65px;
		}
		.p4 {
			font-size: 20px;
			font-family: "Montserrat";
			padding-left: 15px;
			font-weight: 500;
			margin-bottom: 0;
			margin-top: 8px;
		}
		.p5 {
			font-size: 14px;
			font-family: "Montserrat";
			padding-left: 15px;
			font-weight: 400;
		}
	}
	.mb-mentor {
		padding-left: 15px;
		display: flex;
	}
	.mb-btn {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.mb-btn1 {
		width: 95%;
		height: 40px;
		background-color: #ff1d1d;
		border-radius: 5px;
		color: white;
		border: none;
		cursor: pointer;
		margin-bottom: 15px;
	}
	.mb-btn2 {
		width: 95%;
		height: 40px;
		background-color: white;
		border-radius: 5px;
		color: #ff1d1d;
		border: 1px solid #ff1d1d;
		cursor: pointer;
		margin-bottom: 15px;
	}
	.mb-btn3 {
		width: 95%;
		height: 40px;
		background-color: white;
		border-radius: 5px;
		color: black;
		border: 1px solid black;
		cursor: pointer;
	}
	.mb-bottom {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fdf4f4;
		.p1 {
			font-size: 16px;
			font-family: "Montserrat";
			text-align: center;
			font-weight: 500;
			margin-bottom: 8px;
			width: 280px;
			margin-top: 20px;
		}
	}
	.mb-bottom2 {
		background-color: #fdf4f4;
		padding-top: 20px;
		video {
			width: 277px;
			height: 190px;
		}
		.desc {
			padding-left: 10px;
		}
	}
	.explore-more-main-container {
		top: 30rem;
		width: 75%;
		box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
		position: absolute;
		background-color: white;
		border-radius: 10px;
		display: flex;
	}
	.explore-more-container {
	}
	.explore-more-left {
		width: 55%;
		padding: 5rem;
		h2 {
			font-size: 20px;
			font-family: "Montserrat";
			color: #1d3a6a;
			font-weight: 600;
		}
		h3 {
			font-size: 18px;
			font-family: "Montserrat";
			color: #1d3a6a;
			font-weight: 500;
		}
	}
	.explore-more-right {
		width: 45%;
		padding: 5rem;
		h2 {
			font-size: 18px;
			font-family: "Montserrat";
			color: #1d3a6a;
			font-weight: 500;
		}
		img {
			width: 100%;
		}
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	ul.checkmark li {
		margin: 0px;
		padding: 0px;
		font-family: "Montserrat";
		font-size: 16px; // this determines the size of the list text and therefore checkmark size
		list-style-type: none; // remove default list style
		margin-bottom: 1em; // lists need to be able to breathe
		padding: 0.25em 0 0 2.5em; // make room for the circle
		position: relative; // because we want to position the circle in an absolute fashion
	}

	ul.checkmark li:before {
		content: " "; // the circle itself is empty
		display: block;
		border: 2px solid #3fff93; // create a colored area...
		border-radius: 0.8em; // ... and turn it into a circle
		height: 25px;
		width: 25px;
		position: absolute; // the circle is then put into place
		left: 0.5em;
		top: 40%;
		margin-top: -0.5em;
	}

	ul.checkmark li:after {
		content: " ";

		display: block;
		width: 0.4em;
		height: 0.7em;
		border: solid #3fff93;
		border-width: 0 0.2em 0.2em 0; // creates the inverted "L" shape
		position: absolute;
		left: 17px;
		top: 41%;
		margin-top: -0.2em;
		-webkit-transform: rotate(45deg); // tilts the shape
		-moz-transform: rotate(45deg);
		-o-transform: rotate(45deg);
		transform: rotate(45deg);
	}
	.app-button {
		display: flex;
		width: 100%;
		gap: 81px;

		margin-top: 3rem;
		img {
			width: 140%;
		}
	}

	.explore-more-btn {
		button {
			background: rgb(7, 44, 87);
			width: 100%;
			height: 40px;
			font-size: 15px;
			color: white;
			border: none;
			margin-right: 16px;
			border-radius: 5px;
			cursor: pointer;
			margin-top: 20px;
		}
	}
`;

export default BookDemoDesktop;
