import { ADD_MESSAGE, GET_MESSAGES } from "../actions/types";

const messages = (state = [], action) => {
	switch (action.type) {
		case ADD_MESSAGE:
		case GET_MESSAGES:
			return action.payload.conversations;
		default:
			return state;
	}
};

export default messages;
