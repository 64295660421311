import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
import { SlotBookingBrandColor } from "../../utils/slotBookingBrandUtilDetails";
import Header from "../common/Header";
import teacherImg from "../../assets/images/teacher.jpg";
import { P1, P2, P3, P4, Text, P5 } from "../common/Text";
import { PrimaryButton } from "../common/PrimaryButton";

import Collapse from "react-css-collapse";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
// Or, if you have to support IE9
import "@egjs/react-flicking/dist/flicking-inline.css";
import Carousel from "../common/Carousel";
import Slider from "react-slick";
import { BookDemoCarousal } from "../../utils/carouselData";
import { useWindowDimensions } from "../../utils/index";

import { formatDateToDisplay, getMeetingLink } from "../../utils";
import CountdownPopUp from "./CountdownPopUp";
import ConfirmationPopUp from "./ConfirmationPopUp";
const aFaq = [
	{
		id: 1,
		question: "What are the requirements to attend the demo class?",
		answer: "You need a laptop/mobile and a good internet connection",
		isButton: false,
	},
	{
		id: 2,
		question: "What if I have problems joining the demo class?",
		answer: "For any queries related to your demo class, please contact to the <<<<<Counselor Name>>>>>>",
		isButton: false,
	},
	{
		id: 3,
		question: "What should I do before to the demo class?",
		answer: "Install the Fluent Life app and take a fluency meter test.",
		isButton: false,
	},
	{
		id: 4,
		question: "How can I cancel my demo class?",
		answer: "Sorry to see you go. if you want to cancel your demo class, please contact to the <<<<<Counselor Name>>>>>>  <<<<<Counselor contact number>>>>>",
		isButton: true,
	},
];

const NewBookDemoDesktop = ({ bookedDemoDetails, brandName }) => {
	let { buttonColor, logo, expert } =
		SlotBookingBrandColor.getColor(brandName);
	let bookedDemoDetail = bookedDemoDetails?.data;

	let demoCounselorName =
		bookedDemoDetail?.demo_counselor_name || "Demo Couselor";
	let demoCounselorPhone = bookedDemoDetail?.demo_counselor_phone || "";
	let isDemoDraft = bookedDemoDetail?.is_draft;
	let trainer_dp_link = bookedDemoDetail?.trainer_dp_link || teacherImg;
	let trainer_name = bookedDemoDetail?.trainer_name || "Demo Teacher";
	let meeting_link = "";
	let demo_date = bookedDemoDetail?.demo_date || "";
	let demo_time = bookedDemoDetail?.demo_slot
		? bookedDemoDetail?.demo_slot[0]
		: "";
	const demo_package_name = bookedDemoDetail?.demo_package_name;
	const demo_course_name = bookedDemoDetail?.demo_course_name;
	// const demo_timestamp = 1654847723000
	const demo_timestamp = bookedDemoDetail?.demo_timestamp * 1000;
	const currentTimestamp = Date.now();
	const remainingTimestamp = demo_timestamp - currentTimestamp;

	const oneMinuteInMillis = 60000;
	const minutesToWait = 30;

	const [showCountdownPopup, setShowCountdownPopup] = useState(false);
	const [openItemIndex, setOpenItemIndex] = useState(undefined);
	const [isConfirmed, setIsconfirmed] = React.useState(true);
	let format_date = formatDateToDisplay(demo_date);
	const fJoinClass = async () => {
		if (remainingTimestamp > oneMinuteInMillis * minutesToWait) {
			setShowCountdownPopup(true);
		} else {
			try {
				meeting_link = await getMeetingLink(bookedDemoDetail.id);
				window.open(meeting_link, "_blank");
			} catch (e) {
				toast.info(e.message || "Meeting Link is not generated yet.", {
					position: toast.POSITION.TOP_LEFT,
				});
			}
		}
	};

	function toggle(id) {
		setOpenItemIndex(openItemIndex === id ? undefined : id);
	}
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	const { width } = useWindowDimensions();
	const new_settings = {
		dots: width > 768 ? false : true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		arrows: false,
		responsive: [
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1.5,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
		],
	};

	return (
		<NewBookDemoDesktopStyle direction="column" gap="1rem">
			<ToastContainer />
			<Header logo={logo} />
			<FlexContainer direction="column" gap="1rem">
				<div className="demo-class-detail-container container">
					<P1
						margin="0 0 10px 0"
						size={width < 768 ? "1.2rem" : "1.75rem"}
						opacity={width < 768 ? 0.75 : 1}
						weight={width < 768 ? 400 : 700}
					>
						Demo Class Details
					</P1>
					<div className="demo-class-detail-container-inner">
						<FlexContainer direction="column" gap="0.6rem">
							<Text
								className="date-time-header"
								tag="p"
								size="16px"
								weight="400"
								opacity={0.5}
								margin={width < 768 ? "0" : "0 0 9px 0"}
							>
								Date &amp; Time
							</Text>
							<P3 className="header-date">{format_date}</P3>
							{/* <P3 className="header-date">23-23-33</P3> */}
							<P1 className="header-time">{demo_time}</P1>
							{/* <P1 className="header-time">22</P1> */}
						</FlexContainer>
						<div className="demo-class-detail-container-inner-mid">
							<div className="mentor-detail">
								<Text
									tag="p"
									size="16px"
									weight="400"
									opacity={0.5}
									margin="0 0 9px 0"
								>
									Mentor Details
								</Text>
								<DemoMentorStyle>
									<img
										src={trainer_dp_link}
										alt="trainer-logo"
									/>
									<FlexContainer
										direction="column"
										gap="0.4rem"
									>
										<P2>{trainer_name}</P2>
										{/* <P2>wfdef</P2> */}
										<Text
											tag="p"
											size="16px"
											weight="400"
											opacity={0.5}
										>
											{expert}
										</Text>
									</FlexContainer>
								</DemoMentorStyle>
							</div>
						</div>
						<div className="demo-class-detail-container-inner-right">
							<PrimaryButton
								width={"180px"}
								height={"50px"}
								size={"1.2rem"}
								padding={"20px 45px"}
								background={buttonColor}
								onClick={fJoinClass}
							>
								Join Class
							</PrimaryButton>
						</div>
					</div>
				</div>
				<div className="before-class-container container">
					<P1
						margin="0 0 10px 0"
						size={width < 768 ? "1.2rem" : "1.75rem"}
						opacity={width < 768 ? 0.75 : 1}
						weight={width < 768 ? 400 : 800}
						center={width > 768 ? true : false}
					>
						Steps to take before class
					</P1>

					<div
						style={{
							width: "49em",
							// width: 24em;
							// display: this.state.display ? "block" : "none",
						}}
						className="zoom-slide-container"
					>
						<Slider {...new_settings} className="zoom-slider">
							<div className="panel">
								<img
									src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/790c88fd96164adfbacbd4ac75073204.1658734290018256"
									alt="trainer-logo"
								/>
							</div>

							<div className="panel">
								<img
									src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/779d587327e34052b147ea5daa12a7fb.svg"
									alt="trainer-logo"
								/>
							</div>

							<div className="panel">
								<img
									src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/c6e30bd4fd2b422cb76e0a1b7aa9c3c5.svg"
									alt="trainer-logo"
								/>
							</div>
							{/* <div className="panel">
								<img
									src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/790c88fd96164adfbacbd4ac75073204.1658734290018256"
									alt="trainer-logo"
								/>
							</div>

							<div className="panel">
								<img
									src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/779d587327e34052b147ea5daa12a7fb.svg"
									alt="trainer-logo"
								/>
							</div>

							<div className="panel">
								<img
									src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/c6e30bd4fd2b422cb76e0a1b7aa9c3c5.svg"
									alt="trainer-logo"
								/>
							</div> */}
						</Slider>
					</div>

					{/* <Flicking
						circular={true}
						align={"prev"}
						circularFallback={"bound"}
						draggable={true}
						style={{ zIndex: 0 }}
					>
						<div className="panel">
							<img
								src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/790c88fd96164adfbacbd4ac75073204.1658734290018256"
								alt="trainer-logo"
							/>
						</div>

						<div className="panel">
							<img
								src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/779d587327e34052b147ea5daa12a7fb.svg"
								alt="trainer-logo"
							/>
						</div>

						<div className="panel">
							<img
								src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/c6e30bd4fd2b422cb76e0a1b7aa9c3c5.svg"
								alt="trainer-logo"
							/>
						</div>
					</Flicking> */}
				</div>
				<div className="download-zoom-container container">
					<P4 opacity={1} center>
						Please, Download & Install{" "}
						<span className="zoom">Zoom</span> atleast 30 mins
						before your class begins
					</P4>
					<div className="download-zoom">
						<img
							src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/7c83d74d039445f299adcc5231bca839.svg"
							alt="zoom-logo"
						/>
						<a
							href="https://zoom.us/download"
							className="link-download"
							rel="noreferrer"
							target="_blank"
						>
							<h3>Download Zoom</h3>
						</a>
					</div>
				</div>
				<div className="fluent-life-main-carousel container">
					<Slider {...settings}>
						{BookDemoCarousal.map((item, index) => {
							return (
								<>
									<Carousel item={item} />
								</>
							);
						})}
					</Slider>
				</div>
				<div className="demo-fluency-app container">
					<div className="demo-fluency-app-inner">
						<div className="demo-fluency-app-inner-left">
							<img
								src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/f0ea33311fff4b09b276904cf5de9ce0.1658769937928256"
								alt="fluency-app-logo"
							/>
						</div>
						<div className="demo-fluency-app-inner-mid">
							<P1
								margin="0 0 10px 0"
								size={"2.1rem"}
								weight={900}
							>
								Fluent Life app
							</P1>
							<P4 padding={"0.4rem 0rem 1rem 0rem"}>
								Download and improve your communication skills
								with the <strong>fluent life</strong> app
							</P4>
						</div>
						<div className="demo-fluency-app-inner-right">
							<div className="app-download-button">
								<div>
									<a
										href="https://play.google.com/store/apps/details?id=com.ufaber.fluentlife"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/51d8b333a1e84958adda3240edbe7ef5.svg"
											alt="google-play-badge"
										/>
									</a>
								</div>
								<div>
									<a
										href="https://itunes.apple.com/us/app/fluent-life/id1498420000?mt=8"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/3cae1d1afb6c47db91b8d118c6f2b852.svg"
											alt="app-store-badge"
										/>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="demo-faq container">
					<P1 center>FAQ</P1>
					<List>
						{aFaq.map((x) => (
							<li key={x.id}>
								<Button onClick={() => toggle(x)}>
									<div>{`${x.question}`}</div>

									<i
										className={`arrow ${
											openItemIndex === x ? "down" : "up"
										}`}
									></i>
								</Button>
								<C isOpen={openItemIndex === x}>
									<Content>
										<P4
											color={"#62737B"}
											opacity={0.7}
										>{`${x.answer
											.replace(
												/<<<<<Counselor Name>>>>>>/g,
												`${demoCounselorName}`
											)
											.replace(
												/<<<<<Counselor contact number>>>>>/g,
												`${demoCounselorPhone}`
											)}`}</P4>
										{/* {x.isButton && (
											<div className="cancle-demo">
												<PrimaryButton
													width={"180px"}
													height={"50px"}
													size={"1.2rem"}
													padding={"20px 0px"}
													border={"1px solid #1A77CD"}
													color={"#1A77CD"}
													background={"#E8F7FF"}
												>
													Cancel Demo
												</PrimaryButton>
											</div>
										)} */}
									</Content>
								</C>
							</li>
						))}
					</List>
				</div>
				<div className="demo-footer">
					<P4 center padding={"1rem"}>
						2018 © uFaber, All rights reserved uFaber Edutech
					</P4>
				</div>
			</FlexContainer>
			{showCountdownPopup && (
				<CountdownPopUp
					showPopup={setShowCountdownPopup}
					brandName={brandName}
					timestamp={demo_timestamp}
				/>
			)}
			{isConfirmed && (
				<ConfirmationPopUp
					setIsconfirmed={setIsconfirmed}
					brandName={brandName}
				/>
			)}
		</NewBookDemoDesktopStyle>
	);
};
//
const FlexContainer = styled.div`
	display: flex;
	flex-direction: ${(props) => props.direction || "row"};
	gap: ${(props) => props.gap || "1rem"};
`;

const DemoMentorStyle = styled(FlexContainer)`
	img {
		width: 75px;
		height: 75px;
		border-radius: 50%;
		overflow: hidden;
		display: inline-block;
	}

	.demo-class-detail-container-inner-right {
		margin-top: 4%;
	}
`;

const NewBookDemoDesktopStyle = styled.div`
	h3 {
		margin: 0;
	}
	.container {
		height: 100%;
		width: 77%;
		margin: 2% auto;
	}
	.demo-fluency-app-inner-right {
		margin: 0 auto;
	}
	/* .flicking-viewport{
		z-index: -1 !important;
	} */
	//media query for desktop
	.demo-footer {
		background-color: var(--light-blue-color);
	}
	.cancle-demo {
		margin-top: 1rem;
	}

	.demo-fluency-app {
		.demo-fluency-app-inner {
			background: var(--white-color);
			border: 1px solid #c4c4c4;
			border-radius: 15px;
			display: flex;
			flex-wrap: wrap;
			padding: 1.5% 5% 1.5% 1.5%;
			gap: 8%;
			.demo-fluency-app-inner-mid {
				width: 37%;
			}
		}
		.app-download-button {
			display: flex;
			flex-direction: column;
			gap: 6px;
		}
	}
	.slick-arrow {
		background-color: #ffffff;
		z-index: 2;
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}
	.slick-next:before {
		font-size: 46px;
		line-height: 1;
		margin-left: -5px;
		opacity: 1;
		color: #333333;
	}
	.slick-prev:before {
		font-size: 46px;
		line-height: 1;

		opacity: 1;
		color: #333333;
	}
	.slick-dots li button:before {
		font-size: 13px;
	}

	.link-download {
		text-decoration: none;
		padding: 10px;
		border-radius: 5px;
		line-height: 28px;
		color: #2d8cff;
		font-size: 1.5rem;
		font-weight: 900;
		text-align: center;
	}
	.download-zoom {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 20px;
	}
	.zoom {
		color: #2d8cff;
		font-size: 1.2rem;
		font-weight: 900;
		text-align: center;
	}
	.panel {
		margin-right: 15px;
	}

	.panel:first-child {
		padding-left: 12px;
	}

	.before-class-container {
		height: 100%;
		width: 57%;
		margin: 2% auto;
		display: grid;
		justify-content: center;
	}
	.demo-class-detail-container {
		.demo-class-detail-container-inner {
			background: var(--white-color);
			border: 1px solid #c4c4c4;
			border-radius: 15px;
			display: flex;
			flex-wrap: wrap;
			padding: 1.5% 5%;
			gap: 18%;
		}

		.mentor-details {
			display: flex;
			img {
				width: 75px;
				height: 75px;
				border-radius: 50%;
				overflow: hidden;
				display: inline-block;
			}
		}
		.demo-class-detail-container-inner-right {
			margin-top: 4%;
		}
	}

	@media screen and (max-width: 768px) {
		.panel:first-child {
			padding-left: 135px;
		}
		.zoom-slide-container {
			width: 24em !important;
		}
		.before-class-container {
			/* width: 51em !important; */
		}
		.container {
			width: 100%;
			margin-top: 2%;
		}
		.demo-faq {
			margin-top: 3rem;
		}

		.slick-arrow {
			display: none !important;
		}
		.demo-fluency-app {
			margin-top: 3rem;
		}
		.demo-fluency-app-inner-left {
			margin-top: 20%;
			order: 3;
		}
		.demo-fluency-app-inner-mid {
			width: 100% !important;
			order: 1;
		}
		.demo-fluency-app-inner-right {
			margin-top: 5%;
			order: 2;
		}
		.app-download-button {
			flex-direction: row !important;
		}
		.demo-fluency-app-inner {
			padding: 10% 5% 0% 5% !important;
		}
		.demo-class-detail-container-inner-mid {
			margin: 1.5rem 0rem;
		}
		.demo-class-detail-container {
			margin-top: 3rem;
		}
		.demo-class-detail-container-inner {
			padding: 5.5% 5% !important;
			.date-time-header {
				order: 1;
			}
			.header-date {
				order: 3;
			}
			.header-time {
				order: 2;
			}
		}
		.demo-class-detail-container-inner-right {
			width: 100%;
			margin-top: 15% !important;

			button {
				width: 100% !important;
			}
		}
		.fluent-life-main-carousel {
			margin-top: 3rem;
		}
	}
`;

const List = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		background: #e8f7ff;
		border-radius: 10px;
		margin-top: 1rem;
	}
`;

const Button = styled.div`
	transition: all 0.5s ease-in-out;
	width: 100%;
	padding: 20px;
	font-family: Roboto;
	font-size: 1em;
	border-radius: 10px;
	text-align: left;
	color: #263238;
	cursor: pointer;
	background-color: #e8f7ff;
	width: 95%;
	margin: 0% auto;
	padding: 1rem 0rem;
	display: flex;
	flex-direction: row;
	gap: 0.4rem;
	@media screen and (max-width: 1000px) {
		font-size: 0.9em;
		max-height: 5em;
	}
	.arrow {
		height: 1rem;
		width: 1rem;
		border: 2px solid #263238;
		border-width: 2px 0px 0px 2px;
		// transform :rotate(45deg) ;
		display: inline-block;
		margin-left: auto;
	}
	.up {
		transform: rotate(-135deg);
		-webkit-transform: rotate(-135deg);
	}

	.down {
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
	}
`;

const Content = styled.div`
	padding: 1rem 0rem;
`;

const C = styled(Collapse)`
	width: 95%;
	margin: 0% auto;

	border-top: 1px solid rgba(30, 111, 185, 0.3);
	transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1);
`;

export default NewBookDemoDesktop;
