import React, { useEffect, useState } from "react";
import DashBoardHeader from "../components/layouts/DashBoardHeader";
import DashBoardFooter from "../components/layouts/DashBoardFooter";
import DashBoardContent from "../components/layouts/DashBoardContent";
import MessengerSlider from "../components/layouts/MessengerSlider";
import MeetingInfo from "../components/layouts/MeetingInfo";
import LoginPage from "./LoginPage";
import styled from "styled-components";
import { connect } from "react-redux";
import DemoLoginPage from "./DemoLoginPage";
import axios from "axios";
import DemoTable from "../components/layouts/DemoTable";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/layouts/Loading";
import BatchAndRegularClassTable from "../components/layouts/BatchAndRegularClassTable";
import { gettingRegularAndBatchClassDetails } from "../actions/auth";
import Login from "./Login";
import RegularAndBatchClassTable from "../components/layouts/RegularAndBatchClassTable";
const BatchAndRegularDashboard = ({isAuthenticated}) => {
let navigate = useNavigate();
const { id } = useParams();


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/myclass");
    }
  }, [isAuthenticated]);

  return (
    <DashBoardContainer>
      <DashBoardHeader />
      <DashBoardContent>
        {id === undefined ? <BatchAndRegularClassTable/> : <MeetingInfo/>}
      </DashBoardContent>
    </DashBoardContainer>
  );
};

const DashBoardContainer = styled.div``;

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  classDetails: state.auth.class_details,
});
export default connect(mapStateToProps, { gettingRegularAndBatchClassDetails })(
  BatchAndRegularDashboard
);
