import {
	SLOT_BOOKING_QUESTION_SUCCESS,
	SLOT_BOOKING_QUESTION_FAIL,
	VALID_BOOK_MY_DEMO,
	INVALID_BOOK_MY_DEMO,
	GET_SLOT_BOOKING_SUCCESS,
	GET_SLOT_BOOKING_FAIL,
	SLOT_BOOKING_SUCCESS,
	SLOT_BOOKING_FAIL,
	POST_SLOT_BOOKING_QUESTION_SUCCESS,
	POST_SLOT_BOOKING_QUESTION_FALI,
	GET_BOOKED_DEMO_DETAILS_SUCCESS,
	GET_BOOKED_DEMO_DETAILS_FAIL,
} from "../actions/types";

const initialState = {
	loading: true,
	brand_detail:
		JSON.parse(window.localStorage.getItem("brand_details")) || {},
};

function slotBookingReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case SLOT_BOOKING_QUESTION_SUCCESS:
			return {
				...state,
				loading: false,
				slot_booking_questions: payload,
			};
		case SLOT_BOOKING_QUESTION_FAIL:
			return {
				...state,
				loading: false,
				slot_booking_questions: payload,
			};
		case VALID_BOOK_MY_DEMO:
			return {
				...state,
				loading: false,
				brand_detail: payload,
			};
		case INVALID_BOOK_MY_DEMO:
			return {
				...state,
				loading: false,
				brand_detail: payload,
			};
		case GET_SLOT_BOOKING_SUCCESS:
			return {
				...state,
				loading: false,
				slot_booking: payload,
			};
		case GET_SLOT_BOOKING_FAIL:
			return {
				...state,
				loading: false,
				slot_booking: payload,
			};
		case SLOT_BOOKING_SUCCESS:
			return {
				...state,
				loading: false,
				slot_booked: payload,
			};
		case SLOT_BOOKING_FAIL:
			return {
				...state,
				loading: false,
				slot_booked: payload,
			};
		case POST_SLOT_BOOKING_QUESTION_SUCCESS:
			return {
				...state,
				loading: false,
				slot_booked_question: payload,
			};
		case POST_SLOT_BOOKING_QUESTION_FALI:
			return {
				...state,
				loading: false,
				slot_booked_question: payload,
			};
		case GET_BOOKED_DEMO_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				booked_demo_details: payload,
			};
		case GET_BOOKED_DEMO_DETAILS_FAIL:
			return {
				...state,
				loading: false,
				booked_demo_details: payload,
			};

		default:
			return state;
	}
}

export default slotBookingReducer;
