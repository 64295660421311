import React, { useEffect, useState } from "react";
import {
	convert24hourTo12HourFormat,
	formatDateToDisplay,
	convertTimeStampToDate,
} from "../utils";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { SlotBookingBrandColor } from "../utils/slotBookingBrandUtilDetails";
import { getCurrentDate } from "../utils";
import { getSlotBookingDetails } from "../actions/slotBooking";
import { connect } from "react-redux";
import LoadingSpinner from "../components/common/LoadingSpinner";
import { Link } from "react-router-dom";
const DemoSlot = ({ getSlotBookingDetails, slotBooking, brandDetails,test }) => {


	let navigate = useNavigate();
	const [slotBookingDetail, setSlotBookingDetail] = useState({
		notActivateSlotBooking: "",
		activateSlotBooking: "",
		proceedButton: true,
		time: "",
		date: "",
	});

	const handleClickAvaliableSlot = (event, timestamp, timeSlot, date) => {
		//activate current timestamp only
		setSlotBookingDetail({
			...slotBookingDetail,
			activateSlotBooking: timestamp,
			notActivateSlotBooking: "",
			proceedButton: true,
			time: timeSlot,
			date,
		});
	};

	const handleClickNotAvaliableSlot = (event, timestamp, timeSlot, date) => {
		//activate current timestamp only
		setSlotBookingDetail({
			...slotBookingDetail,
			notActivateSlotBooking: timestamp,
			activateSlotBooking: "",
			proceedButton: false,
			time: timeSlot,
			date,
		});
	};

	const handleClickProceed = (event) => {
		if (slotBookingDetail.time === "") {
			toast.warning("Please select a time slot", {
				position: toast.POSITION.TOP_LEFT,
			});
			return false;
		}
		if (slotBookingDetail.date === "") {
			toast.warning("Please select a date and time slot", {
				position: toast.POSITION.TOP_LEFT,
			});
			return false;
		}
		let slotBookingData = {
			id: brandDetails?.id,
			slot_booking_date: slotBookingDetail.date,
			slot_booking_time: slotBookingDetail.time,
		};
		window.localStorage.setItem(
			"slot_time_detail",
			JSON.stringify(slotBookingData)
		);

		let path = `/demo-question`;
		navigate(path);
	};

	let brandName = brandDetails?.pre_demo_brand_name || "IELTS NINJA";
	let pre_demo_package_id = brandDetails?.pre_demo_package_id || "";
	let lead_id = brandDetails?.id 
	let { primaryColor, secondaryColor, buttonColor, logo } =
		SlotBookingBrandColor.getColor(brandName);

	useEffect(() => {
		getSlotBookingDetails(lead_id);
	}, [getSlotBookingDetails, lead_id]);

	let slotLength = slotBooking?.slots?.length || 0;
	let slots = slotBooking?.slots || [];
	let todayDate = getCurrentDate(0);

	//if dslot is empty  render no slot
	if(slotBooking === undefined){
		return <LoadingSpinner />
	}

	return (
		<DemoSlotStyled>
			<ToastContainer />
			<div className="main ">
				<div className="header-top">
					<div className="header-inner">
						<img src={logo} alt="" />
					</div>
				</div>
				<div
					className="timing-slot"
					style={{
						backgroundColor: secondaryColor,
					}}
				>
					<div className="timing-slot-inner">
						<h1>Choose class timings</h1>
						{slotLength === 0 && (
							<>
								<h3>
									{" "}
									😔 No Slot Found Please try after some time{" "}
								</h3>
							</>
						)}
						{slots.map((day, index) => {
							return (
								<div className="slot-block" key={index}>
									<h3 id="date1">
										{todayDate === day.date
											? "Today . "
											: " "}
										{formatDateToDisplay(day.date)}
									</h3>
									<div className="timings">
										{day?.timeSlotsCount?.map(
											(slot, index) =>
												slot.inv_type == 'virtual' || slot.noOfSlots >= 5 ? (
													<div
														key={index}
														className={
															slotBookingDetail.activateSlotBooking ===
															slot.timestamp
																? "green common color_green"
																: "green common"
														}
														onClick={(event) =>
															handleClickAvaliableSlot(
																event,
																slot.timestamp,
																slot.timeSlot,
																day.date
															)
														}
													>
														<p>
															{convert24hourTo12HourFormat(
																slot.timeSlot
															)}
														</p>
													</div>
												) : (
													<div
														className={
															slotBookingDetail.notActivateSlotBooking ===
															slot.timestamp
																? "yellow common color_yellow"
																: "yellow common"
														}
														onClick={(event) =>
															handleClickNotAvaliableSlot(
																event,
																slot.timestamp,
																slot.timeSlot,
																day.date
															)
														}
														key={index}
													>
														<p>
															{convert24hourTo12HourFormat(
																slot.timeSlot
															)}
														</p>
													</div>
												)
										)}
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className="footer">
					<div className="inner-footer">
						<div className="sec1">
							<div className="sec1-top">- Available</div>
							<div className="sec1-bottom">- Few Seats left</div>
						</div>
						<div className="sec2">
							<button
								id="proceed-btn"
								style={{
									background: buttonColor,
								}}
								className={
									slotBookingDetail.proceedButton
										? "btn-enable"
										: "btn-enable"
								}
								onClick={handleClickProceed}
							>
								Proceed
							</button>
						</div>
					</div>
				</div>
			</div>
		</DemoSlotStyled>
	);
};

const DemoSlotStyled = styled.div`
	.header-top {
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;
		img {
			width: 190px;
			height: 22px;
		}
	}
	.header-inner {
		width: 1110px;
	}
	.timing-slot {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.timing-slot-inner {
		width: 1110px;
		height: calc(100% - 120px);
		h1 {
			font-size: 24px;
			font-family: "Montserrat";
			font-weight: 600;
			margin-top: 60px;
			margin-bottom: 30px;
		}
	}
	.slot-block {
		background-color: white;
		border-radius: 10px;
		margin-bottom: 30px;
		h3 {
			font-size: 21px;
			font-family: "Montserrat";
			font-weight: 600;
			height: 60px;
			padding-top: 19px;
			border-bottom: 1px solid #c4c4c4;
			padding-left: 65px;
			margin-bottom: 20px;
		}
	}
	.slot-block:last-child {
		margin-bottom: 120px;
	}
	.timings {
		padding-left: 65px;
		display: flex;
		flex-wrap: wrap;
	}
	.common {
		border: 1px solid;
		width: 125px;
		height: 30px;
		border-radius: 30px;
		margin-right: 10px;
		margin-bottom: 15px;
		text-align: center;
		cursor: pointer;
	}
	.green {
		border: 2px solid #00bf4b;
		color: #00bf4b;
	}
	.yellow {
		border: 2px solid #ffb41d;
		color: #ffb41d;
	}
	.footer {
		background-color: white;
		display: flex;
		justify-content: center;
		height: 110px;
		position: fixed;
		bottom: 0;
		width: 100%;
	}
	.inner-footer {
		width: 1110px;
		display: flex;
		align-items: center;
	}
	.sec1 {
		width: 40%;
	}
	.sec1-top {
		font-size: 15px;
		font-family: "Montserrat";
		font-weight: 600;
		color: #00bf4b;
	}
	.sec1-bottom {
		font-size: 15px;
		font-family: "Montserrat";
		font-weight: 600;
		color: #ffb41d;
	}
	.sec2 {
		button {
			padding: 15px 47px;
			text-align: center;
			text-decoration: none;
			border: none;

			color: white;
			border-radius: 5px;
		}
	}
	.last-block {
		margin-bottom: 200px;
	}
	.color_green {
		color: white;
		background-color: #00bf4b;
	}
	.color_yellow {
		color: white;
		background-color: #ffb41d !important;
	}

	.dis_none {
		display: none;
	}
	.pr-head {
		display: none;
		img {
			width: 140px;
			height: 104px;
		}
		.p1 {
			font-size: 16px;
			font-family: "Montserrat";
			font-weight: 300;
		}
		.p2 {
			font-size: 18px;
			font-family: "Montserrat";
			font-weight: 600;
		}
		.p3 {
			font-size: 18px;
			font-family: "Montserrat";
			font-weight: 600;
		}
	}

	.pr-show {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.pr-inner {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		background-color: white;
		border-radius: 10px;
		width: 410px;
		height: 380px;
		margin: 100px 0;
	}
	.last-btn {
		button {
			width: 160px;
			height: 45px;
			border-radius: 5px;
		}
		.bt1 {
			background-color: #ff1d1d;
			color: white;
			border: none;
		}
		.bt2 {
			border: 1px solid #ff1d1d;
			color: #ff1d1d;
			background-color: white;
			margin-left: 20px;
		}
	}
	@media screen and (max-width: 1160px) and (min-width: 850px) {
		.header-inner {
			width: 800px;
		}
		.timing-slot-inner {
			width: 800px;
		}
		.inner-footer {
			width: 800px;
		}
	}
	@media screen and (max-width: 850px) and (min-width: 650px) {
		.header-inner {
			width: 620px;
		}
		.timing-slot-inner {
			width: 620px;
		}
		.inner-footer {
			width: 620px;
		}
	}
	@media screen and (max-width: 650px) {
		.header-inner {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.header-top {
			img {
				width: 160px;
				height: 21px;
			}
		}
		.timing-slot-inner {
			h1 {
				text-align: center;
				margin-top: 40px;
				margin-bottom: 20px;
				font-size: 22px;
			}
			width: 90%;
		}
		.slot-block {
			h3 {
				text-align: center;
				padding-left: 0;
				width: 100%;
				font-size: 18px;
			}
			display: flex;
			align-items: center;
			flex-direction: column;
		}
		.common {
			width: 90px;
			height: 40px;
			padding-top: 6px;
			font-size: 16px;
		}
		.timings {
			padding-left: 0;
			width: 300px;
		}
		.inner-footer {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		.sec1 {
			width: auto;
			display: flex;
			flex-direction: row;
		}
		.sec1-bottom {
			margin-left: 15px;
		}
		.sec2 {
			button {
				padding: 15px 47px;
				text-align: center;
				text-decoration: none;
				margin-top: 12px;
			}
		}
	}
	@media screen and (max-width: 350px) {
		.timings {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 250px;
		}
		.common {
			width: 70px;
			height: 40px;
			padding-top: 6px;
			font-size: 16px;
		}
		.sec2 {
			button {
				width: 240px;
				height: 50px;
				margin-top: 12px;
			}
		}
	}
	@media screen and (max-width: 450px) {
		.pr-inner {
			width: 90%;
		}
		.last-btn {
			button {
				width: 110px;
			}
			.bt2 {
				margin-left: 10px;
			}
		}
	}
`;

const mapStateToProps = (state) => ({
	slotBooking: state.slotBooking?.slot_booking,
	brandDetails: state.slotBooking?.brand_detail,

});

export default connect(mapStateToProps, {
	getSlotBookingDetails,
})(DemoSlot);
