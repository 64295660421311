import { useState, useEffect } from "react";
import api from "./api";

export const formatDate = () => {
	let date = new Date();
	let hours = date.getHours();
	let minutes = date.getMinutes();
	let ampm = hours >= 12 ? "pm" : "am";
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? "0" + minutes : minutes;
	let strTime = hours + ":" + minutes + " " + ampm;
	return strTime;
};

export const randomNumberGenerator = () => {
	let randomNumber = Math.floor(Math.random() * 10) + 1;
	return randomNumber;
};

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowDimensions;
}

export const dispSecondsAsMins = (seconds) => {
	const mins = Math.floor(seconds / 60);
	const seconds_ = seconds % 60;

	return (
		mins.toString() + ":" + (seconds_ === 0 ? "00" : seconds_.toString())
	);
};
//get  Tue, 31 May, Wed, 1 Jun,Thurs, 2 Jun
export const getDayName = (date) => {
	const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
	const day = new Date(date).getDay();
	return days[day];
};

//get current date in format of yyy/mm/dd
export const getCurrentDate = (count) => {
	let date = new Date();
	date.setDate(date.getDate() + count);
	let month = `${date.getMonth() + 1}`.padStart(2, "0");
	let day = `${date.getDate()}`.padStart(2, "0");
	let year = date.getFullYear();
	return `${year}-${month}-${day}`;
};


export const validateDateTime = (date, time) => {
	let time_stamp = ``
	if (time.indexOf(":") < 0){
		const time_list = time.split("")
		for (let x=0; x < time_list.length; x++) {
			time_stamp += `${time_list[x]}`
			if (x === 1){
				time_stamp += `:`
			}
		}
	} else {
		time_stamp = time
	}
	let date_time = date + " " + time_stamp
	let given_date = new Date(date_time);
	let current_date = new Date()
	return current_date <= given_date ? true : false
};

//get 3 days from current date Wed, 1 Jun
export const formatDateToDisplay = (date) => {
	const month = [
		"Jan",
		"Feb",
		"March",
		"April",
		"May",
		"June",
		"July",
		"Aug",
		"Sept",
		"Oct",
		"Nov",
		"Dec",
	];

	let day = `${getDayName(date)}, ${date.split("-")[2]} ${
		month[date.split("-")[1] - 1]
	}`;

	return day;
};

export const convert24hourTo12HourFormat = (sTime) => {
	const time = sTime.replace(/(..)/g, "$1:").slice(0, -1);
	const time_part_array = time.split(":");
	let ampm = " AM";
	if (time_part_array[0] >= 12) {
		ampm = " PM";
	}
	if (time_part_array[0] > 12) {
		time_part_array[0] = time_part_array[0] - 12;
	}
	const formatted_time = time_part_array[0] + ":" + time_part_array[1] + ampm;
	return formatted_time;
};

export const sortDateFunction = (a, b) => {
	var dateA = new Date(a.date).getTime();
	var dateB = new Date(b.date).getTime();
	return dateA > dateB ? 1 : -1;
};

// format a date
// export const formatDateToDisplay = (date) => {
// 	//split a date 2022-06-06
// 	const date_array = date.split("-");
// 	//get month
// 	const month = date_array[1];
// 	//get day
// 	const day = date_array[2];
// 	//get year
// 	const year = date_array[0];

// 	return `${day}-${month}-${year}`;
// };
//
//convert the time stamp
export const convertTimeStampToDate = (timeStamp_value) => {
	let theDate = new Date(timeStamp_value * 1000);
	theDate.toGMTString();
	let month = `${theDate.getMonth() + 1}`.padStart(2, "0");
	let day = `${theDate.getDate()}`.padStart(2, "0");
	let year = theDate.getFullYear();
	return `${year}-${month}-${day}`;
};

export const padZeroToNumber = (number) => {
	number = number.toString();
	if (number.length === 1) {
		return `0${number}`;
	}
	return number;
};

export const addHours = (hours) => {
	let date = new Date();
	let addedHour = new Date(date.getTime() + hours * 60 * 60 * 1000);
	//pad hours with 0
	let hours_ = `${addedHour.getHours()}`.padStart(2, "0");
	//pad minutes with 0
	let minutes = `${addedHour.getMinutes()}`.padStart(2, "0");

	return `${hours_}${minutes}`;
};

export const getMeetingLink = async (demo_id) => {
	if (!demo_id) {
		throw new Error(
			"Could not generate meeting link. Please reachout to counselor or trainer."
		);
	}
	try {
		const apiResp = await api.get(
			`bookmydemo/demos/${demo_id}/class-link/`
		);
		return apiResp.data.data.meeting_link;
	} catch (e) {
		throw new Error(e.response.data.message);
	}
};
