import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { formatDateToDisplay, getMeetingLink } from "../../utils";
import { SlotBookingBrandColor } from "../../utils/slotBookingBrandUtilDetails";
import OwlCarousel from "react-owl-carousel";
import movies from "../../assets/images/movie.mp4";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CountdownPopUp from "./CountdownPopUp";
import { toast } from "react-toastify";
import flGooglePlayImg from "../../assets/images/google-play-svg.svg";
import flAppleStoreImg from "../../assets/images/apple-store-svg.svg";
import flOurApp from "../../assets/images/fl-our-app.svg";
import ReactPlayer from "react-player";
const BookDemoMobile = ({ bookedDemoDetails, brandName }) => {
	let { primaryColor, secondaryColor, buttonColor, logo, websiteURL } =
		SlotBookingBrandColor.getColor(brandName);
	let bookedDemoDetail = bookedDemoDetails?.data;
	let isDemoDraft = bookedDemoDetail?.is_draft
	let trainer_dp_link = bookedDemoDetail?.trainer_dp_link || "";
	let trainer_name = bookedDemoDetail?.trainer_name || "";
	let meeting_link = "";
	let demo_date = bookedDemoDetail?.demo_date || "";
	let demo_time = bookedDemoDetail?.demo_slot
		? bookedDemoDetail?.demo_slot[0]
		: "";

	let format_date = formatDateToDisplay(demo_date);
	const demo_package_name = bookedDemoDetail?.demo_package_name;
	const demo_course_name = bookedDemoDetail?.demo_course_name;
	// const demo_timestamp = 1654847723000
	const demo_timestamp = bookedDemoDetail?.demo_timestamp * 1000;
	const currentTimestamp = Date.now();
	const remainingTimestamp = demo_timestamp - currentTimestamp;

	const oneMinuteInMillis = 60000;
	const minutesToWait = 30;

	const [showCountdownPopup, setShowCountdownPopup] = useState(false);

	const fJoinClass = async () => {
		if (remainingTimestamp > oneMinuteInMillis * minutesToWait) {
			setShowCountdownPopup(true);
		} else {
			try {
				meeting_link = await getMeetingLink(bookedDemoDetail.id);
				window.open(meeting_link, "_blank");
			} catch (e) {
				toast.info(e.message || "Meeting Link is not generated yet.", {
					position: toast.POSITION.TOP_LEFT,
				});
			}
		}
	};

	return (
		<BookDemoMobileStyled>
			<div className="main">
				<div className="header-top">
					<div className="header-inner">
						<img src={logo} alt="" />
					</div>
				</div>
				<div
					className="mb-main"
					style={{
						background: `linear-gradient(to top, ${primaryColor} 40%, white 0% )`,
					}}
				>
					<div
						className="mb-mid"
						// style={{
						// 	background: secondaryColor,
						// }}
					>
						<h1>Congratulations!</h1>
						<p className="p1">
							Your <span> Trial class </span> for{" "}
						</p>
						<p className="p2">{brandName} is confirmed.</p>
						<p className="p3">Here are the details of the same</p>
					</div>
					<div className="mb-mid2">
						<p className="p2">DEMO CLASS</p>
						<p className="p1">Date &amp; Time</p>
						<p className="p2">
							{format_date} {demo_time}
						</p>
						{ isDemoDraft ? <p className="p4" >Mentor to be assgined</p> : 
							<React.Fragment>
								<p className="p3">Mentor Details</p>
								<div className="mb-mentor">
								<img src={trainer_dp_link} alt="" />
								<div>
									<p className="p4">{trainer_name}</p>
									<p className="p5"></p>
								</div>
							</div>
							</React.Fragment>
						}
						<hr></hr>
						<div className="mb-btn">
							<button
								onClick={fJoinClass}
								className="mb-btn1"
								style={{
									background: buttonColor,
								}}
							>
								Join Class
							</button>
							{/* <button
								onclick="location.href='/reschedule-demo/?id={{ demo_class_id }}';"
								className="mb-btn2"
								style={{
									color: buttonColor,
									border: `${buttonColor} 1px solid`,
								}}
							>
								Reschedule
							</button> 
							<button className="mb-btn3">
								Get Ready for Class
							</button> */}
						</div>
					</div>
					{brandName === "THE FLUENTLIFE" && (
						<>
							<div className="mb-bottom">
								<div className="explore-more-main-containe">
									<h2>Meet Our App </h2>
									<h3>
										Your Secret Weapon For 24 / 7 Learning
									</h3>

									<div className="mb-bottom-img">
										<img src={flOurApp} alt="our app" />
									</div>
									<div className="app-button">
										<a href="https://play.google.com/store/apps/details?id=com.ufaber.fluentlife">
											<img
												src={flGooglePlayImg}
												alt="Get it on Google Play"
											/>
										</a>
										<a href="https://apps.apple.com/in/app/the-fluent-life/id1547973466">
											<img
												src={flAppleStoreImg}
												alt="Get it on apple Play"
											/>
										</a>
									</div>
								</div>
							</div>
						</>
					)}
					{brandName === "IELTS NINJA" && (
						<>
							<div
								className="mb-bottom2"
								style={{
									background: secondaryColor,
								}}
							>
								<div className="row">
									<OwlCarousel
										className="owl-carousel owl-theme owl-loaded"
										loop
										items={1.5}
										center={true}
										margin={8}
									>
										<div className="item">
											<ReactPlayer
												className="react-player"
												url="https://player.vimeo.com/video/339555167?h=e7165aa97c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
												width="100%"
												height="100%"
											/>

											<div className="desc">
												Introduction to IELTS Writing
												Task
											</div>
										</div>
										<div className="item">
											<ReactPlayer
												className="react-player"
												url="https://player.vimeo.com/video/339557568?h=9bc894a476&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
												width="100%"
												height="100%"
											/>

											<div className="desc">
												Step1 Thesis Statements
											</div>
										</div>
										<div className="item">
											<ReactPlayer
												className="react-player"
												url="https://player.vimeo.com/video/339557022?h=a909c6e342&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
												width="100%"
												height="100%"
											/>

											<div className="desc">
												Writing Task 2 An Overview
											</div>
										</div>
									</OwlCarousel>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
			{showCountdownPopup && (
				<CountdownPopUp
					showPopup={setShowCountdownPopup}
					brandName={brandName}
					timestamp={demo_timestamp}
				/>
			)}
		</BookDemoMobileStyled>
	);
};
const BookDemoMobileStyled = styled.div`
	.app-button {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	.explore-more-main-containe {
		width: 92%;
		box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
		background-color: white;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		h2 {
			font-size: 20px;
			font-family: "Montserrat";
			color: #1d3a6a;
			font-weight: 600;
		}
		h3 {
			font-size: 18px;
			font-family: "Montserrat";
			color: #1d3a6a;
			font-weight: 500;
		}
	}
	.header-top {
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white !important;
		img {
			width: 190px;
			height: 22px;
		}
	}
	.header-inner {
		width: 1110px;
	}

	.play-icon {
		position: absolute;
		left: 140px;
		top: 90px;
		color: #ff1d1d;
		border: solid 1px #ff1d1d;
		width: 57px;
		height: 57px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 25px;
		border-radius: 60px;
		cursor: pointer;
	}
	.pause-icon {
		position: absolute;
		left: 140px;
		top: 90px;
		color: #ff1d1d;
		border: solid 1px #ff1d1d;
		width: 57px;
		height: 57px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 25px;
		border-radius: 60px;
		cursor: pointer;
		display: none;
	}

	.desc {
		font-size: 16px;
		font-weight: 500;
		margin-top: 10px;
		margin-bottom: 30px;
	}
	.owl-dot {
		margin-right: 10px;
	}
	.mb-mid {
		padding: 20px 0;
		h1 {
			font-size: 28px;
			font-family: "Montserrat";
			font-weight: 600;
			text-align: center;
		}
		.p1 {
			font-size: 18px;
			font-family: "Montserrat";
			font-weight: 400;
			text-align: center;
			margin-bottom: 0;
			span {
				font-size: 18px;
				font-family: "Montserrat";
				font-weight: 600;
				text-align: center;
			}
		}
		.p2 {
			font-size: 18px;
			font-family: "Montserrat";
			font-weight: 400;
			text-align: center;
		}
		.p3 {
			font-size: 16px;
			font-family: "Montserrat";
			font-weight: 500;
			text-align: center;
		}
	}
	.mb-mid2 {
		background-color: white;
		margin: 6px 16px;
		box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

		.p1 {
			font-size: 15px;
			font-family: "Montserrat";
			color: #7f8396;
			padding-left: 15px;
			font-weight: 300;
			margin-bottom: 8px;
		}
		.p2 {
			font-size: 20px;
			font-family: "Montserrat";
			padding-left: 15px;
			font-weight: 500;
		}
		.p3 {
			font-size: 15px;
			font-family: "Montserrat";
			color: #7f8396;
			padding-left: 15px;
			font-weight: 300;
			margin-bottom: 8px;
		}
		img {
			width: 65px;
			height: 65px;
			border-radius: 65px;
		}
		.p4 {
			font-size: 20px;
			font-family: "Montserrat";
			padding-left: 15px;
			font-weight: 500;
			margin-bottom: 0;
			margin-top: 8px;
		}
		.p5 {
			font-size: 14px;
			font-family: "Montserrat";
			padding-left: 15px;
			font-weight: 400;
		}
	}
	.mb-mentor {
		padding-left: 15px;
		display: flex;
	}
	.mb-btn {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 20px;
	}
	.mb-btn1 {
		width: 95%;
		height: 40px;

		border-radius: 5px;
		color: white;
		border: none;
		cursor: pointer;
		margin-bottom: 15px;
	}
	.mb-btn2 {
		width: 95%;
		height: 40px;
		background-color: white;
		border-radius: 5px;

		cursor: pointer;
		margin-bottom: 15px;
	}
	.mb-btn3 {
		width: 95%;
		height: 40px;
		background-color: white;
		border-radius: 5px;
		color: black;
		border: 1px solid black;
		cursor: pointer;
	}
	.mb-bottom {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.p1 {
			font-size: 16px;
			font-family: "Montserrat";
			text-align: center;
			font-weight: 500;
			margin-bottom: 8px;
			width: 280px;
			margin-top: 20px;
		}
	}
	.mb-bottom2 {
		padding-top: 20px;
		video {
			width: 277px;
			height: 190px;
		}
		.desc {
			padding-left: 10px;
		}
	}

	@media screen and (max-width: 1160px) and (min-width: 850px) {
		.header-inner {
			width: 800px;
		}
	}
	@media screen and (max-width: 850px) and (min-width: 650px) {
		.header-inner {
			width: 620px;
		}
	}
	@media screen and (max-width: 650px) {
		.header-inner {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.header-top {
			img {
				width: 160px;
				height: 21px;
			}
		}
	}
	@media screen and (max-width: 1360px) and (min-width: 1100px) {
		video {
			width: 250px;
		}
		.play-icon {
			top: 60px;
			left: 110px;
		}
		.pause-icon {
			top: 60px;
			left: 110px;
		}
	}
	@media screen and (max-width: 1100px) and (min-width: 900px) {
		video {
			width: 200px;
		}
		.play-icon {
			top: 50px;
			left: 75px;
		}
		.pause-icon {
			top: 50px;
			left: 75px;
		}
		.bottom-sec2-inner {
			margin-bottom: 100px;
		}
	}
	@media screen and (max-width: 1150px) {
		.mid-sec2-inner {
			width: 53%;
		}
	}
	@media screen and (min-width: 900px) {
		.mb-main {
			display: none;
		}
	}
	@media screen and (max-width: 900px) and (min-width: 0px) {
		.mid {
			display: none;
		}
		.bottom {
			display: none;
		}
		.footer {
			display: none;
		}
		.owl-stage {
			left: -20px;
		}
		.play-icon {
			top: 68px;
			left: 115px;
		}
		.pause-icon {
			top: 50px;
			left: 75px;
		}
	}
`;
export default BookDemoMobile;
