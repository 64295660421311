import React from 'react'
import styled from 'styled-components'
const Header = ({logo}) => {
  return (
    <HeaderStyle>
        <div className="main">
            <div className="header-top">
                <div className="header-inner">
                    <img src={logo} alt="" />
                </div>
            </div>
        </div>
    </HeaderStyle>
  )
}

const HeaderStyle = styled.div`
    .header-top {
        box-shadow: 0px 4px 25px rgb(0 0 0 / 10%);
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		img {
			width: 15%;
		}
	}
	.header-inner {
        width: 75%;
	}
    //media query
    @media (max-width: 768px) {
        .header-top {
            /* background-color: black; */
            height: 4.3rem;
            img {
			width: 50%;
		}
        }
        .header-inner {
            width: 90%;
        }
    }

`;
export default Header