import { Route, Routes} from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import NoMatch from "../pages/NoMatch";
import LoginPage from "../pages/LoginPage";
import React, { useEffect } from "react";
import store from "../store";
import { loadUser } from "../actions/auth";
import setAuthToken from "../utils/setAuthToken";
import { LOGOUT } from "../actions/types";
import SlotBooking from "../pages/SlotBooking";
import DemoSlot from "../pages/DemoSlot";
import DemoQuestion from "../pages/DemoQuestion";
import DemoQuestions from "../pages/DemoQuestions";
import BookDemo from "../pages/BookDemo";
import ReschedleDemo from "../components/layouts/ReschedleDemo";
import FlashMessage from "../components/common/FlashMessage";
import NewBookDemoDesktop from "../components/layouts/test";
import DemoLoginPage from "../pages/DemoLoginPage";
import DemoDashboard from "../pages/DemoDashboard";
import BatchAndRegularDashboard from "../pages/BatchAndRegularDashboard";
import Login from "../pages/Login";
import OtpPopUp from "../components/layouts/OtpPopUp";
import SignUp from "../components/layouts/SignUp";
import GoogleLoginAuthHooks from "../components/layouts/GoogleLoginAuthHooks";
import RegularBatchClassLogin from "../pages/RegularBatchClassLogin";
import DemoClassLogin from "../pages/DemoClassLogin";


const Router = () => {

  let domainType = window.location.hostname.split(".")[0];

  return (
    <Routes>
      <Route
        path="/"
        element={
          domainType === "trial" ? (
            <DemoClassLogin />
          ) : (
            <RegularBatchClassLogin />
          )
        }
      />
      <Route path="/demo" element={<DemoClassLogin />} />
      <Route path="/myclass" element={<RegularBatchClassLogin />} />
      <Route path="/student/classes" element={<DemoLoginPage />} />
      <Route path="/meeting/:id" element={
          domainType === "trial" ? (
            <DemoDashboard />
          ) : (
            <BatchAndRegularDashboard />
          )
        } />
      <Route path="/demo-dashboard/" element={<DemoDashboard />} />
      <Route
        path="/regular-and-batch-dashboard/"
        element={<BatchAndRegularDashboard />}
      />
      <Route path="/:id/slot-booking/" element={<SlotBooking />} />
      <Route path="/demo-slots" element={<DemoSlot />} />
      <Route path="/demo-question" element={<DemoQuestions />} />
      <Route path="/:id/book-demo" element={<BookDemo />} />
      <Route path="/reschedule-demo" element={<ReschedleDemo />} />
      <Route path="/:id/new" element={<NewBookDemoDesktop />} />
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default Router;
