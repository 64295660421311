import React, { useState } from "react";
import DashBoardHeader from "../components/layouts/DashBoardHeader";
import DashBoardFooter from "../components/layouts/DashBoardFooter";
import DashBoardContent from "../components/layouts/DashBoardContent";
import MessengerSlider from "../components/layouts/MessengerSlider";
import MeetingInfo from "../components/layouts/MeetingInfo";
import LoginPage from "./LoginPage";
import styled from "styled-components";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import DemoLoginPage from "./DemoLoginPage";
import Loading from "../components/layouts/Loading";
import { useEffect } from "react";
import axios from "axios";
const Dashboard = ({ isAuthenticated }) => {
  const { id } = useParams();

  const [isMessenger, setIsMessenger] = useState(false);
  const [isActiveUser, setIsActiveUser] = useState(false);

  console.log(isAuthenticated, "AUTH");

  if (!isAuthenticated) {
    return <DemoLoginPage class_link={id} />;
  }

  return (
    <DashBoardContainer>
      <DashBoardHeader />
      <DashBoardContent>
        {" "}
        <MeetingInfo/>
      </DashBoardContent>
    </DashBoardContainer>
  );
};

const DashBoardContainer = styled.div``;

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps)(Dashboard);
