

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = firebase.initializeApp({
  apiKey: "AIzaSyBfASqSFwR09JcOUnqJXPJV_VxoAqzstGY",
  authDomain: "chat-fa6d0.firebaseapp.com",
  projectId: "chat-fa6d0",
  storageBucket: "chat-fa6d0.appspot.com",
  messagingSenderId: "796253997255",
  appId: "1:796253997255:web:1f31009e8e6974bceb56e2",
  measurementId: "G-D23CVBEXLX"
});

export { firebaseConfig as firebase };
