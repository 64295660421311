import React from "react";
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { LabelItem } from "../components/common/LabeItem";
import { InputItem } from "../components/common/InputItem";
import { FormInputWrapper } from "../components/common/FormInputWrapper";
import { connect } from "react-redux";
import {
	login,
	guestRegister,
	guestLogin,
	validateClassLink,
	verifyOtpGuestLogin,
} from "../actions/auth";
import Dashboard from "./Dashboard";
import { dispSecondsAsMins } from "../utils";
import { validate } from "../utils/validation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

const LoginPage = ({
	class_link,
	login,
	guestRegister,
	auth: { isAuthenticated },
	guestDetails,
	guestLogin,
	validateClassLink,
	class_link_details,
	verifyOtpGuestLogin,
	error,
}) => {
	const RESENT_OTP_TIMER = 60;
	const [toggleState, setToggleState] = useState(1);
	const [showPassword, setShowPassword] = useState(false);
	const [startTimer, setStartTimer] = useState(false);
	const timerIdRef = useRef(0);
	const [count, setCount] = useState(RESENT_OTP_TIMER);
	const [isOtpVerified, setIsOtpVerified] = useState(false);
	const [formErrors, setFormErrors] = useState({});

	const [loginFormData, setLoginFormData] = useState({
		email: "",
		password: "",
		username: "",
		phone: "",
		otp: "",
		isOtpVerified: false,
	});

	const { email, password, username, phone, otp } = loginFormData;

	const onLoginChange = (e) => {
		setFormErrors({ ...formErrors, [e.target.name]: "" });
		setLoginFormData({ ...loginFormData, [e.target.name]: e.target.value });
	};
	useEffect(() => {
		if (error) {
			toast.error(error.message, {
				position: toast.POSITION.TOP_LEFT,
			});
		}
	}, [error]);
	const onLoginSubmit = (e) => {
		e.preventDefault();
		setFormErrors(validate(loginFormData, "login"));
		if (class_link_details?.status === "SUCCESS") {
			email && password && login(email, password);
		} else {
			toast.error("Invalid ClassRoom Link.!", {
				position: toast.POSITION.TOP_LEFT,
			});
		}
	};

	const onGuestSubmit = (e) => {
		e.preventDefault();

		const class_type = class_link_details?.class_type;
		setFormErrors(validate(loginFormData, "guest"));
		if (class_link_details?.status === "SUCCESS") {
			username &&
				isOtpVerified &&
				guestLogin(username, phone, class_link, class_type);
		} else {
			toast.error("Invalid ClassRoom Link.!", {
				position: toast.POSITION.TOP_LEFT,
			});
		}
	};

	const verifyOtp = (e) => {
		e.preventDefault();
		verifyOtpGuestLogin(phone, otp);
		if (guestDetails?.data?.otp !== otp) {
			setFormErrors({ otp: "Invalid OTP" });
			return;
		}
		setIsOtpVerified(true);
		setLoginFormData({ ...loginFormData, isOtpVerified: true });
	};

	const sendOTP = (e) => {
		e.preventDefault();
		if (
			phone === "" ||
			phone === undefined ||
			phone.length < 10 ||
			phone.length > 10
		) {
			setFormErrors({ ...formErrors, phone: "Invalid Phone Number" });
			return;
		}
		!startTimer && guestRegister(username, phone, class_link);
		setStartTimer(true);
		startHandler();
	};

	const onClickShowPassword = (e) => {
		setShowPassword(!showPassword);
	};

	const startHandler = () => {
		if (timerIdRef.current) {
			return;
		}
		timerIdRef.current = setInterval(() => setCount((c) => c - 1), 1000);
	};

	const stopHandler = () => {
		clearInterval(timerIdRef.current);
		timerIdRef.current = 0;
		setCount(RESENT_OTP_TIMER);
		setStartTimer(false);
	};

	if (count === 0) {
		stopHandler();
	}

	const toggleTab = (index) => {
		setToggleState(index);
	};

	useEffect(() => {
		validateClassLink(class_link);
	}, [class_link, validateClassLink]);

	useEffect(() => {
		if (guestDetails?.status === "ERROR") {
			toast.error(guestDetails.message, {
				position: toast.POSITION.TOP_LEFT,
			});
		}

		setStartTimer(false);
	}, [guestDetails]);

	if (isAuthenticated) {
		return <Dashboard />;
	}
	return (
		<LoginStyleContainer>
			<LoginWrapper>
				<ToastContainer />
				<div className="bloc-tabs">
					<button
						className={
							toggleState === 1
								? "classGuest tabs active-tabs"
								: "classGuest tabs"
						}
						onClick={() => toggleTab(1)}
					>
						<FontAwesomeIcon className="icon" icon={faUser} />
						Guest Login
					</button>
					<button
						className={
							toggleState === 2
								? "classLogin tabs active-tabs"
								: "classLogin tabs"
						}
						onClick={() => toggleTab(2)}
					>
						<FontAwesomeIcon
							className="icon"
							icon={faRightFromBracket}
						/>
						Login
					</button>
				</div>

				<div className="content-tabs">
					<div
						className={
							toggleState === 1
								? "content  active-content"
								: "content"
						}
					>
						<h2>Guest</h2>

						<hr />
						<div id="login">
							<form className="form" onSubmit={onGuestSubmit}>
								<FormInputWrapper>
									<LabelItem className="username">
										UserName
									</LabelItem>
									<InputItem
										type="text"
										name="username"
										placeholder="Username"
										onChange={onLoginChange}
									></InputItem>
									<span
										className={
											formErrors.username
												? "error-message is-visible"
												: ""
										}
									>
										{formErrors.username}
									</span>
								</FormInputWrapper>
								<FormInputWrapper>
									<LabelItem className="phone">
										phone
									</LabelItem>

									<InputItem
										type="text"
										placeholder="Phone"
										name="phone"
										onChange={onLoginChange}
									></InputItem>
									<a
										href="#0"
										className="append-text"
										onClick={sendOTP}
									>
										{startTimer
											? dispSecondsAsMins(count)
											: "Send-OTP"}
									</a>
									<span
										className={
											formErrors.phone
												? "error-message is-visible"
												: ""
										}
									>
										{formErrors.phone}
									</span>
								</FormInputWrapper>

								<FormInputWrapper>
									<LabelItem className="otp">Otp</LabelItem>

									<InputItem
										type="text"
										placeholder="OTP"
										name="otp"
										onChange={onLoginChange}
									></InputItem>
									<a
										href="#0"
										className="append-text"
										onClick={verifyOtp}
									>
										{isOtpVerified ? "Verified" : "Verify"}
									</a>
									<span
										className={
											formErrors.otp
												? "error-message is-visible"
												: ""
										}
									>
										{formErrors.otp}
									</span>
								</FormInputWrapper>
								<FormInputWrapper>
									<InputItem
										type="submit"
										placeholder="Enter ClassRoom"
										padding={"16px 0"}
										backgroundColor={"#1267ed"}
										fontWeight={"bold"}
										value="Enter ClassRoom"
										color={"#fff"}
									></InputItem>
								</FormInputWrapper>
							</form>
						</div>
					</div>

					<div
						className={
							toggleState === 2
								? "content  active-content"
								: "content"
						}
					>
						<h2> Login</h2>
						<hr />
						<div id="login">
							<form className="form" onSubmit={onLoginSubmit}>
								<FormInputWrapper>
									<LabelItem className="email">
										Email
									</LabelItem>
									<InputItem
										type="email"
										name="email"
										placeholder="E-mail"
										onChange={onLoginChange}
									></InputItem>
									<span
										className={
											formErrors.email
												? "error-message is-visible"
												: ""
										}
									>
										{formErrors.email}
									</span>
								</FormInputWrapper>

								<FormInputWrapper>
									<LabelItem className="password">
										Password
									</LabelItem>

									<InputItem
										type={`${
											showPassword ? "text" : "password"
										}`}
										placeholder="password"
										name="password"
										onChange={onLoginChange}
									></InputItem>
									<a
										href="#0"
										onClick={onClickShowPassword}
										className="append-text"
									>
										{showPassword ? "Hide" : "Show"}
									</a>
									<span
										className={
											formErrors.password
												? "error-message is-visible"
												: ""
										}
									>
										{formErrors.password}
									</span>
								</FormInputWrapper>

								<FormInputWrapper>
									<InputItem
										type="submit"
										placeholder="Login"
										padding={"16px 0"}
										backgroundColor={"#1267ed"}
										fontWeight={"bold"}
										value="Enter ClassRoom"
										color={"#fff"}
									></InputItem>
								</FormInputWrapper>
							</form>
						</div>
					</div>
				</div>
			</LoginWrapper>
		</LoginStyleContainer>
	);
};

const LoginWrapper = styled.div`
	min-width: 320px;
	max-width: 450px;
	margin: 150px auto;
	background: #fff;
	border-radius: 15px;
	box-shadow: 0px 0px 15px 5px #0000002b;
`;
const LoginStyleContainer = styled.div`
	.bloc-tabs {
		display: flex;
	}
	.tabs {
		padding: 15px;
		text-align: center;
		width: 50%;
		background: rgba(128, 128, 128, 0.075);
		cursor: pointer;
		border-bottom: 1px solid rgba(0, 0, 0, 0.274);
		box-sizing: content-box;
		position: relative;
		outline: none;
		display: flex;
		column-gap: 23px;
		justify-content: center;
	}
	.tabs:not(:last-child) {
		border-right: 1px solid rgba(0, 0, 0, 0.274);
	}

	.active-tabs {
		background: white;
		border-bottom: 1px solid transparent;
	}

	.active-tabs::before {
		content: "";
		display: block;
		position: absolute;
		top: -5px;
		left: 50%;
		transform: translateX(-50%);
		width: calc(100% + 2px);
		height: 5px;
		background: rgb(88, 147, 241);
	}

	button {
		border: none;
	}
	.content-tabs {
		flex-grow: 1;
	}
	.content {
		padding: 20px;
		height: 100%;
		display: none;
	}
	.content h2 {
		padding: 0px 0 5px 0px;
	}
	.content hr {
		width: 100px;
		height: 2px;
		background: #222;
		margin-bottom: 5px;
	}
	.content p {
		width: 100%;
		height: 100%;
	}
	.active-content {
		display: block;
	}

	.form {
		padding: 1.4em;
	}

	.form label.username {
		background-image: url("https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/5264720312c148bf9fbf81770a221ffb.1651219630882293");
	}
	.form label.email {
		background-image: url("https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/2d5ac6c8729c4e008527fb55c68f57a7.svg");
	}
	.form label.otp {
		background-image: url("https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/9222fc6daf424dafbfecd78fab25af88.svg");
	}

	.form label.phone {
		background-image: url("https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/bb3a89cf20ac4aa09b71917b71176192.svg");
	}

	.form label.password {
		background-image: url("https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/9689759c50644c28a1ce3b2c2b2bd8be.svg");
	}

	.form .append-text {
		background: #ffff;
		display: inline-block;
		position: absolute;
		text-decoration: none;
		right: 1px;
		top: 0;
		padding: 13px 15px;
		border-left: 1px solid #d2d8d8;
		top: 50%;
		bottom: auto;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		font-size: 14px;
		font-size: 0.875rem;
		color: #343642;
	}

	.error-message {
		display: inline-block;
		position: absolute;
		left: -5px;
		bottom: -35px;
		background: rgba(215, 102, 102, 0.9);
		padding: 0.8em;
		z-index: 2;
		color: #fff;
		font-size: 13px;
		font-size: 0.8125rem;
		border-radius: 0.25em;
		/* prevent click and touch events */
		pointer-events: none;
		visibility: hidden;
		opacity: 1;
		-webkit-transition: opacity 0.2s 0, visibility 0 0.2s;
		transition: opacity 0.2s 0, visibility 0 0.2s;
	}

	.form .error-message::after {
		/* triangle */
		content: "";
		position: absolute;
		left: 22px;
		bottom: 100%;
		height: 0;
		width: 0;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-bottom: 8px solid rgba(215, 102, 102, 0.9);
	}
	.form .error-message.is-visible {
		opacity: 1;
		visibility: visible;
		-webkit-transition: opacity 0.2s 0, visibility 0 0;
		transition: opacity 0.2s 0, visibility 0 0;
	}
`;
//
// state.auth?.details
const mapStateToProps = (state) => ({
	guestDetails: state.auth?.guest_regester,
	auth: state.auth,
	class_link_details: state.auth.link_details,
	error: state.auth.error,
});

export default connect(mapStateToProps, {
	login,
	guestRegister,
	guestLogin,
	validateClassLink,
	verifyOtpGuestLogin,
})(LoginPage);
