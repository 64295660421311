import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

ReactDOM.render(
	<React.Fragment>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.Fragment>,
	document.getElementById("root")
);
