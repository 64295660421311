import React, { useEffect } from "react";
import Router from "./routes/Router";
import store from "./store";
import { Provider } from "react-redux";
import "./styles/css/style.css";
import AuthInit from "./routes/AuthProvider";
function App() {
	return (
		<div className="App">
			<Provider store={store}>
				<AuthInit>
					<Router />
				</AuthInit>
			</Provider>
		</div>
	);
}

export default App;
