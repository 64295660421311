import { combineReducers } from "redux";
import auth from "./auth";
import messages from "./message";
import slotBooking from "./slotBooking";

export default combineReducers({
	auth,
	messages,
	slotBooking,
});
