import React, { useState } from "react";
import { getSlotBookingQuestions ,bookSlot, postSlotBookQuestion} from "../actions/slotBooking";
import { connect } from "react-redux";
import { useEffect } from "react";
import styled from "styled-components";
import { SlotBookingBrandColor } from "../utils/slotBookingBrandUtilDetails";

import SelectComponent from "./SelectComponent";
import { InputItem } from "../components/common/InputItem";
import { PrimaryButton } from "../components/common/PrimaryButton";
import SlotBookingPopup from "../components/layouts/SlotBookingPopup";
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../components/common/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { formatDateToDisplay, convert24hourTo12HourFormat, validateDateTime } from "../utils";

const DemoQuestions = ({
  getSlotBookingQuestions,
  questions,
  brandDetails,
  bookSlot, 
  postSlotBookQuestion,
  slot_booked_question,
  slot_booked,
}) => {

  console.log(brandDetails,"BRAND DETAILS")
  const [optionSelected, setOptionSelected] = React.useState("");
  const [inputs, setInputs] = React.useState({});
  const [showPopup, setShowPopup] = React.useState(false);
  const [selectedcheckOption, setSelectedcheckOption] = React.useState({});
  const [payment_transaction_id, setpayment_transaction_id] = useState(null);
  let leadID = brandDetails?.id;
  let hex_lead_id = brandDetails?.hex_lead_id;


  let navigate = useNavigate();
  	//get slot_time_detail from local storage
	const slot_time_detail = JSON.parse(
		localStorage.getItem("slot_time_detail")
	);
	let date = slot_time_detail?.slot_booking_date ?? "";
	let time = slot_time_detail?.slot_booking_time ?? "";
	let id = slot_time_detail?.id ?? "";

  let format_date = formatDateToDisplay(date);
	let format_time = convert24hourTo12HourFormat(time);

  useEffect(() => {
    getSlotBookingQuestions(leadID);
  }, [getSlotBookingQuestions, leadID]);

  console.log(payment_transaction_id,"Payment Transaction Id") 
  useEffect(() => {
    console.log(payment_transaction_id,"SLOT BOOK QUESTION")
    if (slot_booked_question?.status === "success") {

      bookSlot(date, time, id , payment_transaction_id);
    } else if (slot_booked_question?.status === "failure") {
      console.log(slot_booked_question?.message,"ERROR")
      toast.error(slot_booked_question?.message, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  }, [slot_booked_question, bookSlot,payment_transaction_id]);

  useEffect(() => {
    if (slot_booked?.status === "success") {
      toast.success("Slot Booked Successfully", {
        position: toast.POSITION.TOP_LEFT,
      });
      // let path = `https://test.therealschool.in/`;
      // navigate(path);
      window.location.href="https://test.therealschool.in/demo"
    } else if (slot_booked?.status === "failure") {
      toast.error(slot_booked?.message, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  }, [slot_booked, hex_lead_id, navigate, setShowPopup]);

  if (date === "" || time === "" || id === "") {
		toast.error("Please Select the Proper Slot", {
			position: toast.POSITION.TOP_LEFT,
		});
		navigate("/demo-slots");
		return false;
	}


  //Function to load razorpay script for the display of razorpay payment SDK.
  const loadRazorpayScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

//function will get called when clicked on the pay button.
async function displayRazorpayPaymentSdk(razorpaydetails) {
  console.log(razorpaydetails,"RAZOR PAY DETAILS")
  const res = await loadRazorpayScript(
      "https://checkout.razorpay.com/v1/checkout.js"
  );

  if (!res) {
      alert("Razorpay SDK failed to load. please check are you online?");
      return;
  }

    const merchantId = "rzp_test_tAG4Ez7D6Nyyfj";
    const amount = razorpaydetails.invoice_price * 100;
    const currency = null;
    const orderId = razorpaydetails.razor_pay_order_id;

  const options = {
    "key": "rzp_test_tAG4Ez7D6Nyyfj",
    "amount": amount, // 2000 paise = INR 20
    "name": "Ufaber Edutech Private Limited",
    "description": "cust_desc",
    "handler": function (response){
      console.log(response.razorpay_payment_id,"RESPONSE OF RAZOR PAY")
      setpayment_transaction_id(response.razorpay_payment_id)
      console.log(payment_transaction_id,"gkkk")
      postSlotBookQuestion(finalQuestionInput, id);
      // window.location.href = "https://test.therealschool.in/demo"
  },

    "prefill": {
        "name": razorpaydetails.prefill.name,
        "email": razorpaydetails.prefill.email,
        "contact": razorpaydetails.prefill.contact,
    },
    "notes": {
      "address": "cust_address",
      "order_id": razorpaydetails.razor_pay_order_id,
      "transaction_id": razorpaydetails.notes.transaction_id,
      "paymentsystem_id": razorpaydetails.notes.paymentsystem_id,
      "demo_id": razorpaydetails.notes.demo_id,
      "is_paid_demo": razorpaydetails.notes.is_paid_demo,
      "package_id": razorpaydetails.notes.package_id,
      "product_info": razorpaydetails.notes.product_info,
      "brand": razorpaydetails.notes.brand
    },
    "theme": {
        "color": "#F37254"
    }
};

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}



  let finalQuestionInput = [];
  Object.entries(inputs).map((item) => {
    if (item[1].answer !== "" && item[1].answer !== undefined) {
      finalQuestionInput.push(item[1]);
    }
  });

  const showSlotBookingPopup = (noForm) => {
    if ((noForm = "noForm")) {
      setShowPopup(true);
    } else {
      let isAllQuestionsAnswered = questions?.data.every(
        (question) =>
          finalQuestionInput.find((input) => input.tag === question.tag) !==
          undefined
      );

      // let language =
      // 	optionSelected.length > 0 ? "" : (isAllQuestionsAnswered = false);
      // 	console.log(isAllQuestionsAnswered)

      if (isAllQuestionsAnswered) {
        setShowPopup(true);
      } else {
        toast.error("Please Select the Question", {
          position: toast.POSITION.TOP_LEFT,
        });
      }
    }
  };

  const allQuestion = questions?.data;

  // if (allQuestion === null) {
  // 	setShowPopup(true);
  // }

  const handleChangeQuestion = (event) => {
    const tag = event.target.name;
    const answer = event.target.value;
    const question = event.target.getAttribute("data-label");

    setInputs((values) => ({
      ...values,
      [tag]: { question, answer, tag },
    }));
  };

  const handleOnclickCheckOption = (event) => {
    const tag = event.target.name;
    const answer = event.target.value;
    const question = event.target.getAttribute("data-label");

    answer &&
      setInputs((values) => ({
        ...values,
        [tag]: { question, answer, tag },
      }));

    tag &&
      setSelectedcheckOption((values) => ({
        ...values,
        [tag]: { question, answer, tag },
      }));
  };

  const onChangeMultiSelect = ({ selected, question, tag }) => {
    setOptionSelected(selected);

    let answer = selected.map((item) => item.value);

    setInputs((values) => ({
      ...values,
      [tag]: { question, answer, tag },
    }));
  };

  let brandName = brandDetails?.pre_demo_brand_name || "IELTS NINJA";

  let { primaryColor, secondaryColor, buttonColor, logo } =
    SlotBookingBrandColor.getColor(brandName);

    if (questions?.status === "Failure") {
      toast.error(questions?.message, {
        position: toast.POSITION.TOP_LEFT,
      });


	
	

    return (
      <DemoQuestionsStyled
        style={{
          background: ` linear-gradient(180deg, #ffffff 60%, ${secondaryColor} 40%)`,
        }}
      >
        <ToastContainer />
        <div className="main ">
          <div className="header-top">
            <div className="header-inner">
              <img src={logo} alt="" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="content-inner">
            <div className="content-inner-inner">
              Please Contact to the Support Team
            </div>
          </div>
        </div>
      </DemoQuestionsStyled>
    );
  }

  return questions === undefined || questions === null ? (
    <LoadingSpinner />
  ) : (
    <DemoQuestionsStyled
      style={{
        background: ` linear-gradient(180deg, ${secondaryColor} 60%, ${primaryColor} 40%)`,
      }}
    >
      <ToastContainer />

      <div className="main ">
        <div className="header-top">
          <div className="header-inner">
            <img src={logo} alt="" />
          </div>
        </div>

        <FlexColumn>
          {allQuestion === null ? (
            <WhiteFlexColumn>
              <HeaderQuestions>
                <h1>We have noted the Slot you selected. Please Continue</h1>
              </HeaderQuestions>
              <FlexRow4>
                <PrimaryButton
                  width={"180px"}
                  height={"50px"}
                  size={"1.2rem"}
                  padding={"20px 45px"}
                  background={buttonColor}
                  onClick={() => showSlotBookingPopup("noForm")}
                >
                  Continue
                </PrimaryButton>
              </FlexRow4>
            </WhiteFlexColumn>
          ) : (
            <WhiteFlexColumn>
              <HeaderQuestions>
                <h1>Demo Booking Form</h1>
                <p>Please tell us a little about yourself.</p>
              </HeaderQuestions>

              {allQuestion.map((questions, index) => {
                return (
                  <>
                    <Text10>{questions?.question}</Text10>
                    {SelectComponent(
                      questions,
                      optionSelected,
                      index,
                      handleChangeQuestion,
                      handleOnclickCheckOption,
                      onChangeMultiSelect,
                      selectedcheckOption,
                      brandName
                    )}
                  </>
                );
              })}

              {questions?.payment_details != undefined ? (
                
                <FlexRow4>
                  <h5>Amount : Rs. {questions?.payment_details?.razorpay.invoice_price} /-</h5>
                  <PaymentButton
                    width={"180px"}
                    height={"50px"}
                    size={"1.2rem"}
                    padding={"20px 45px"}
                    onClick={() => displayRazorpayPaymentSdk(questions?.payment_details.razorpay)}
                  >
                    Pay Now
                  </PaymentButton>
                </FlexRow4>
              ) : (
                <FlexRow4>
                  <PrimaryButton
                    width={"180px"}
                    height={"50px"}
                    size={"1.2rem"}
                    padding={"20px 45px"}
                    background={buttonColor}
                    onClick={showSlotBookingPopup}
                  >
                    Submit
                  </PrimaryButton>
                </FlexRow4>
              )}
            </WhiteFlexColumn>
          )}
        </FlexColumn>
      </div>
      {showPopup && (
        <SlotBookingPopup
          setShowPopup={setShowPopup}
          finalQuestionInput={finalQuestionInput}
          brandName={brandName}
          hex_lead_id={hex_lead_id}
        />
      )}
    </DemoQuestionsStyled>
  );
};

const HeaderQuestions = styled.div`
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }

  color: #1d1d1d;
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 161.4%;
    padding-bottom: 20px;

    color: #696969;
  }
`;

const FlexRow4 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 17px;
  align-items: center;
  margin: 0px 0px 27px 0px;
  h5{
    margin-bottom: 0px;
  }
`;

const Text10 = styled.div`
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 600;
  margin: 0px 0px 27px 2px;
`;

const DemoQuestionsStyled = styled.div`
  height: 100%;

  .header-top {
    box-shadow: 6px 6px 28px -12px rgb(4 4 4 / 10%);

    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    img {
      width: 190px;
      height: 22px;
    }
  }
  .header-inner {
    width: 1110px;
  }

  @media screen and (max-width: 850px) and (min-width: 650px) {
    .header-inner {
      width: 620px;
    }
  }

  @media screen and (max-width: 650px) {
    .header-top {
      img {
        width: 160px;
        height: 21px;
      }
    }
    .header-inner {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .react-select {
    width: 100%;
  }
  .test-check {
    position: relative;
    cursor: pointer;
    font-size: 1.1em;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;
const FlexColumn = styled.div`
  margin-top: 10px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  @media screen and (max-width: 650px) {
    margin-top: 10px;
  }
`;

const WhiteFlexColumn = styled.div`
  width: 60%;
  border: 1px solid rgba(3, 44, 87, 0.3);
  box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  padding: 71px 165px 61.5px 165px;

  @media screen and (max-width: 650px) {
    padding: 50px 20px;
    width: 90%;
  }
`;

const PaymentButton = styled.div`
	display: flex;
	align-items:center;
	justify-content:center;
	width: 180px;
	height:50px;
	padding: 20px 45px;
	cursor:pointer;
	border: 2px solid black;
	border-radius: 8px;
  	img{
		display: flex;
		align-items:center;
		justify-content:center;
		width:100%;
		min-width:100%;
		object-fit:cover;
	}
`
const mapStateToProps = (state) => ({
  questions: state.slotBooking?.slot_booking_questions,
  brandDetails: state.slotBooking?.brand_detail,
	slot_booked: state.slotBooking?.slot_booked,
	slot_booked_question: state.slotBooking?.slot_booked_question,

});

export default connect(mapStateToProps, {
  getSlotBookingQuestions,
  bookSlot,
	postSlotBookQuestion,
})(DemoQuestions);
