import React from "react";
import styled from "styled-components";
import { useWindowDimensions } from "../../../utils/index";
import { P1 } from "../../common/Text";
import { BookMyDemoCard } from "../../../utils/carouselData";
const CurrentAffairCard = ({ brandName }) => {
	const { width } = useWindowDimensions();
	const Card = BookMyDemoCard[brandName];
	return (
		<>
			{/* <P1
				margin="23px 0 0px 15px"
				size={width < 768 ? "2rem" : "2rem"}
				opacity={width < 768 ? "0.7" : "1"}
			>
				Before the demo, try Current Affairs for free now!
			</P1> */}
			<CarouselStyle style={{ marginTop: "24px" }} className="container">
				<div className="css-slider" style={{ background: "#FFFFFF" }}>
					{/* <div className="css-slider-left">
						<h1>Current Affairs</h1>
						<p style={{ color: "#333333" }}>Daily magazine, Quiz, video analysis and more…</p>
						<div className="carousal-button ">
							<a
								href="https://upscpathshala.page.link/CurrentAffairs"
								target="__blank"
								style={{
									color: "#333333",
									border: `2px solid #333333`,
								}}
							>
								View Now
							</a>
						</div>
					</div> */}
					{Card.map((data) => (
						<div className="current-affair-images">
							{
								<>
									<a href={data.link} target="_blank">
										<img src={data.img} alt="logo" />
									</a>
									{data.footerData !== "" && <a href="#">{data.footerData}</a>}
								</>
							}
						</div>
					))}
					{/* <div className="current-affair-images">
						
						<img
							src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/70c19ac9fb7de011cf014af93ca99800.svg"
							alt="logo"
						/>
						<a href="https://upscpathshala.page.link/CurrentAffairs">View daily news reader</a>
					</div>
					<div className="current-affair-images">
						<img
							src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/da89e38d2085d08a94af46c0706b4cae.svg"
							alt="logo"
						/>
						<a href="https://upscpathshala.page.link/CurrentAffairs">View daily quiz</a>
					</div> */}
				</div>
			</CarouselStyle>
		</>
	);
};

const CarouselStyle = styled.div`
	.current-affair-images {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		justify-content: center;
		align-items: center;
		/* a {
			color: #263238;
			font-weight: 600;
			font-size: 16px;
			line-height: 19px;
			text-decoration: none;
			opacity: 0.75;
			margin-bottom: 2.5rem;
		} */
	}
	@media screen and (max-width: 950px) {
		.css-slider-left {
			width: 100% !important;
			height: 100%;
			padding: 40px 0px 0px 20px !important;
			z-index: 4;
		}

		.css-slider {
			flex-direction: column;
			gap: 1rem !important;
			.css-slider-left {
				/* display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center; */
				h1 {
					font-weight: 900 !important;
					font-size: 40px !important;
					line-height: 36px !important;
				}
				p {
					font-weight: 400 !important;
					font-size: 16px !important;
					line-height: 19px !important;
				}
				h2 {
					font-weight: 700 !important;
					font-size: 22px !important;
					line-height: 26px !important;
				}
				.carousal-button {
					margin-bottom: 65px !important;
				}
			}
		}
	}
	background: #fff;
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;

	.css-slider {
		justify-content: center;
		display: flex;
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: relative;
		align-items: center;
		flex-direction: column;
		gap: 4rem;
		border-radius: 22px;

		.css-slider-left {
			h1 {
				font-weight: 900;
				font-size: 80px;
				line-height: 68px;
				margin-bottom: 20px;
			}
			p {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 400;
				font-size: 24px;
				line-height: 28px;
				margin-bottom: 20px;

				opacity: 0.75;
			}
			h2 {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 700;
				font-size: 24px;
				line-height: 28px;
				margin-bottom: 20px;

				opacity: 0.75;
			}
		}

		.carousal-button {
			margin-top: 45px;
			a {
				padding: 16px 32px;
				border-radius: 10px;
				font-weight: 400;
				font-size: 20px;
				line-height: 23px;
				text-decoration: none;
			}
		}
	}
`;
export default CurrentAffairCard;
