import styled from 'styled-components'

export const TextBox = styled.input`
	width: ${(props) => props.width || "100%"};
	height: ${(props) => props.height || "auto"};
	padding: ${(props) => props.padding || "10px 20px"};
	font-size: ${(props) => props.fontSize || "16px"};
	color: ${(props) => props.color || "#000000"};
	border-radius: 5px;
	box-shadow: ${(props) => props.shadow || ""};
	border:${(props) => props.border || "1px solid #DBDBDB"};
	margin: ${(props) => props.margin || "0px"};
	background-color: ${(props) => props.backgroundColor || "#ffffff"};
	text-align: ${(props) => props.textAlign || "left"};
`;