import styled from "styled-components";
import React from "react";

const Carousel = ({ item }) => {
	const {
		backgroundImage,
		img,
		backgroundStyle,
		headerTitle,
		headerSubTitle,
		headerSpan,
		button,
		customStyle,
		redirect_link,
	} = item;
	return (
		<CarouselStyle style={{ marginTop: "24px" }}>
			<div
				className="background-img"
				style={{
					top: 0,
					left: 0,
				}}
			>
				<img src={backgroundImage} alt="background" />
			</div>
			<div
				className="css-slider"
				style={{ background: backgroundStyle.background }}
			>
				<div className="css-slider-left">
					<h1
						style={{ color: customStyle.headerColor }}
						dangerouslySetInnerHTML={{ __html: headerTitle }}
					/>
					<p style={{ color: customStyle.paraColor }}>
						{headerSubTitle}
					</p>
					{headerSpan && (
						<h2 style={{ color: customStyle.paraColor }}>
							{headerSpan}
						</h2>
					)}
					{!button && (
						<div className="app-download-button">
							<a
								href="https://play.google.com/store/apps/details?id=com.ufaber.fluentlife"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/51d8b333a1e84958adda3240edbe7ef5.svg"
									alt="google-play-badge"
								/>
							</a>
							<a
								href="https://itunes.apple.com/us/app/fluent-life/id1498420000?mt=8"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/3cae1d1afb6c47db91b8d118c6f2b852.svg"
									alt="app-store-badge"
								/>
							</a>
						</div>
					)}
					{button && (
						<div className="carousal-button ">
							<a
								href={redirect_link}
								target="__blank"
								style={{
									color: customStyle.paraColor,
									border: `2px solid ${customStyle.paraColor}`,
								}}
							>
								{button}
							</a>
						</div>
					)}
					{/* <div className="bottom-logo">
						<img
							src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/b18b3c319cbb455b8f9dccd0785e22a9.svg"
							alt="logo"
						/>
					</div> */}
				</div>
				<div className="css-slider-right">
					<div className="right-img">
						<img src={img} alt="logo" />
					</div>
				</div>
			</div>
		</CarouselStyle>
	);
};

const CarouselStyle = styled.div`
	@media screen and (max-width: 700px) {
		.css-slider-right:not(:first-child) {
			 /* margin-top: 40px !important; */
		}
		.app-download-button img{
			width:100%;
			max-width:150px;
			
		}
		.app-download-button{
			gap:1rem;
			justify-content:center;
			align-items:center;
			width:100%;
		}
		.bottom-logo {
			display: none;
		}
		.css-slider-left {
			width: 100% !important;
			height: 100%;
			padding: 2.5rem 1rem !important;
			z-index: 4;
		}
		.css-slider-right {
			width: 100% !important;
		}
		.css-slider {
			flex-direction: column;
			.css-slider-left {
				h1 {
					font-weight: 900 !important;
					font-size: 40px !important;
					line-height: 36px !important;
				}
				p {
					font-weight: 400 !important;
					font-size: 16px !important;
					line-height: 19px !important;
				}
				h2 {
					font-weight: 700 !important;
					font-size: 22px !important;
					line-height: 26px !important;
				}
				.carousal-button {
					margin-bottom: 65px !important;
				}
			}
		}
	}
	/* background: #fff; */
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	.background-img {
		position: absolute;
		z-index: 2;
	}

	.css-slider {
		display: flex;
		width: 100%;
		height: 646px;
		overflow: hidden;
		position: relative;
		height: 646px;

		border-radius: 40px;
		.app-download-button {
			display: flex;
			

		}
		
		.css-slider-left {
			width: 50%;
			padding: 100px 0px 0px 50px;
			z-index: 4;
			h1 {
				font-weight: 900;
				font-size: 80px;
				line-height: 68px;
				margin-bottom: 20px;
			}
			p {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 400;
				font-size: 24px;
				line-height: 28px;
				margin-bottom: 20px;

				opacity: 0.75;
			}
			h2 {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 700;
				font-size: 24px;
				line-height: 28px;
				margin-bottom: 20px;

				opacity: 0.75;
			}
			.bottom-logo {
				position: absolute;
				bottom: 45px;
			}
		}
		.css-slider-right {
			width: 50%;
			display: flex;
			justify-content: center;
			text-align: center;
		}
		.carousal-button {
			margin-top: 45px;
			a {
				padding: 16px 32px;
				border-radius: 10px;
				font-weight: 400;
				font-size: 20px;
				line-height: 23px;
				text-decoration: none;
			}
		}
	}
`;

export default Carousel;
