import styled from 'styled-components';

export const LabelItem = styled.label`

		display: inline-block;
		position: absolute;
		left: 15px;
		top: 50%;
		bottom: auto;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		width: ${(props) => props.width || "20px"};
		height: ${(props) => props.height || "20px"};
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		color: transparent;
		text-shadow: none;
		background-repeat: no-repeat;
		background-position: 50% 0;
`;