import { ADD_MESSAGE, GET_MESSAGES } from "./types";
import { formatDate } from "../utils";
import { firebase } from "../firebase";

export const addMessage = (message, author, userId, class_link) => async (dispatch) => {
	try {
		const msgObj = {
			userId: userId,
			message,
			author,
			timestamp: formatDate(),
		};

		updateMessage(msgObj, class_link);

		dispatch({ type: ADD_MESSAGE, ...msgObj });
	} catch (err) {
		console.log(err);
	}
};

export const updateMessage = (msgObj, class_link) => {
	const db = firebase.firestore();
	db.collection("conversations")
		.doc(class_link)
		.collection("messages")
		.add({
			...msgObj,
			isView: false,
			createdAt: new Date(),
		})
		.then((data) => {
		
		})
		.catch((error) => {
		
		});
};

export const getRealtimeConversations = (class_link) => {
	return async (dispatch) => {
		const db = firebase.firestore();
		db.collection("conversations")
			.doc(class_link)
			.collection("messages")
			.orderBy("createdAt", "asc")
			.onSnapshot((querySnapshot) => {
				const conversations = [];

				querySnapshot.forEach((doc) => {
					conversations.push(doc.data());
				});

				dispatch({
					type: GET_MESSAGES,
					payload: { conversations },
				});
			});
	};
};
