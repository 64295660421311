import React, { useEffect } from "react";
import { useWindowDimensions } from "../utils";
import SlotBookingHomeDesktop from "../components/layouts/SlotBookingHomeDesktop";
import SlotBookingHomeMobile from "../components/layouts/SlotBookingHomeMobile";
import { useParams } from "react-router-dom";
import { getBookMyDemo } from "../actions/slotBooking";
import { connect } from "react-redux";
import LoadingSpinner from "../components/common/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import FlashMessage from "../components/common/FlashMessage";
const SlotBooking = ({ getBookMyDemo, bookMydemo }) => {
	// localStorage.removeItem("brand_details");
	let navigate = useNavigate();
	const { width } = useWindowDimensions();
	const { id } = useParams();
	useEffect(() => {
		localStorage.removeItem("brand_details");
		localStorage.removeItem("slot_time_detail");
		getBookMyDemo(id);
	}, [getBookMyDemo, id]);

	const demoData = bookMydemo;
	let existingDemo = demoData?.existing_demos || "";

	useEffect(() => {
		if (bookMydemo?.hex_lead_id !== id) {
			return false;
		}

		if (existingDemo) {
			let path = `/${id}/book-demo`;
			navigate(path);
		}
	}, [existingDemo, navigate, id, bookMydemo?.hex_lead_id]);

	if (bookMydemo?.status === "failure") {
		return <FlashMessage type="error" message={bookMydemo?.message} />;
	}
	
	return bookMydemo === undefined || Object.keys(bookMydemo).length === 0 ? (
		<LoadingSpinner />
	) : width > 950 ? (
		<SlotBookingHomeDesktop demoData={demoData} />
	) : (
		<SlotBookingHomeMobile demoData={demoData} />
	);
};

const mapStateToProps = (state) => ({
	bookMydemo: state.slotBooking?.brand_detail,
});
// brand_detail
export default connect(mapStateToProps, {
	getBookMyDemo,
})(SlotBooking);
