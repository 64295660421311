export const SlotBookingBrandColor = {
	getColor: (brand) => {
		switch (brand) {
      case "IELTS NINJA":
        return {
          primaryColor: "#fae2e2",
          secondaryColor: "#fdf4f4",
          buttonColor: "#ff1d1d",
          logo: "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/c498391ba3b3444ab13e1d6bed2ce919.png",
          websiteURL: "https://ieltsninja.com",
          expert: "IELTS Expert",
          contBackGroundColor: "#e8f5ff",
        };
      case "THE FLUENTLIFE":
        return {
          primaryColor: "#CBFEE2",
          secondaryColor: "#F1FFF7",
          buttonColor: "#072C57",
          logo: "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/6e41f66987394dddb3e729822442e29f.png",
          websiteURL: "https://thefluentlife.com",
          expert: "Fluency Expert",
          contBackGroundColor: "#e8f5ff",
        };
      case "THE REAL SCHOOL":
        return {
          primaryColor: "#FFF3E7",
          secondaryColor: "#FBFCF7",
          buttonColor: "#FF3A58",
          logo: "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/08abf17bfdfa4b5db0669afd40b7231a.png",
          expert: "REAL Expert",
          contBackGroundColor: "#e8f5ff",
        };
      case "UPSC PATHSHALA":
        return {
          primaryColor: "#FFF3E7",
          secondaryColor: "#FBFCF7",
          buttonColor:
            "linear-gradient(89.9deg, #433EE9 0.07%, #8E14FF 99.92%)",
          logo: "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/434215874d904edda4f414aa79e0c5a7.png",
          expert: "UPSC PATHSHALA Expert",
          contBackGroundColor: "#F6EAF5",
        };
      case "EMAESTER":
        return {
          primaryColor: "#FFF3E7",
          secondaryColor: "#FBFCF7",
          buttonColor: "#FF3A58",
          logo: "https://emaester.com/public/static/emaester_web_assets/img/logo.svg",
          expert: "EMAESTER Expert",
          contBackGroundColor: "#F6EAF5",
        };
      case "Abroad Ninja":
        return {
          primaryColor: "#fae2e2",
          secondaryColor: "#fdf4f4",
          buttonColor: "rgb(17, 58, 104)",
          logo: "https://s3.us-east-2.wasabisys.com/ufaber-lms/files/f9212a8993b3ee44fa979d321d52f7e9.svg",
          expert: "ABROAD NINJA Expert",
          contBackGroundColor: "#F6EAF5",
        };
      default:
        return {
          primaryColor: "#fae2e2",
          secondaryColor: "#fdf4f4",
          buttonColor: "#ff1d1d",
          logo: "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/c498391ba3b3444ab13e1d6bed2ce919.png",
          expert: "IELTS Expert",
          contBackGroundColor: "#F6EAF5",
        };
    }
	},
};
