import styled from "styled-components";

export const CheckBoxItem = styled.input`
	border: 1px solid #c4c4c4;
	border-radius: 4px;
	margin-left: 20px;
	height: 18px;
	width: 18px;
	&:checked {
		background-color: #c4c4c4;
	}
`;
