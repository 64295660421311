export const aFaq = [
	{
		id: 1,
		question: "What are the requirements to attend the demo class?",
		answer: "You need a laptop/mobile and a good internet connection",
		isButton: false,
	},
	{
		id: 2,
		question: "What if I have problems joining the demo class?",
		answer: "For any queries related to your demo class, please contact to the <<<<<Counselor Name>>>>>>",
		isButton: false,
	},
	{
		id: 3,
		question: "What should I do before to the demo class?",
		answer: "Install the Fluent Life app and take a fluency meter test.",
		isButton: false,
	},
	{
		id: 4,
		question: "How can I cancel my demo class?",
		answer: "Sorry to see you go. if you want to cancel your demo class, please contact to the <<<<<Counselor Name>>>>>>  <<<<<Counselor contact number>>>>>",
		isButton: true,
	},
];
