import styled from 'styled-components';

export const FormInputWrapper = styled.p`
  	position: relative;
	margin: 1.4em 0;

    &:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}

`;