import api from "../utils/api";
import axios from "axios";
import { getCurrentDate, sortDateFunction, addHours } from "../utils";
import {
	SLOT_BOOKING_QUESTION_SUCCESS,
	SLOT_BOOKING_QUESTION_FAIL,
	VALID_BOOK_MY_DEMO,
	INVALID_BOOK_MY_DEMO,
	GET_SLOT_BOOKING_SUCCESS,
	GET_SLOT_BOOKING_FAIL,
	SLOT_BOOKING_SUCCESS,
	SLOT_BOOKING_FAIL,
	POST_SLOT_BOOKING_QUESTION_SUCCESS,
	POST_SLOT_BOOKING_QUESTION_FALI,
	GET_BOOKED_DEMO_DETAILS_SUCCESS,
	GET_BOOKED_DEMO_DETAILS_FAIL,
} from "./types";
import testapi from "../utils/testapi";

export const getBookMyDemo = (id) => async (dispatch) => {
	// localStorage.removeItem("brand_details");
	try {
		const res = await testapi.get(`bookmydemo/leads/${id}/`);

		let response = { ...res.data.data, hex_lead_id: id };

		if (res.data.status === "success") {
			window.localStorage.setItem(
				"brand_details",
				JSON.stringify({ ...res.data.data, hex_lead_id: id })
			);
			dispatch({
				type: VALID_BOOK_MY_DEMO,
				payload: response,
			});
		} else {
			dispatch({
				type: INVALID_BOOK_MY_DEMO,
				payload: res,
			});
		}
	} catch (err) {
		const errors = err.response.data;

		if (errors) {
			console.log(errors);
		}

		dispatch({
			type: INVALID_BOOK_MY_DEMO,
			payload: errors,
		});
	}
};
export const getSlotBookingQuestions = (id) => async (dispatch) => {
	try {
		const res = await testapi.get(`bookmydemo/leads/${id}/questions/`);

		if (res.data.status === "success") {
			dispatch({
				type: SLOT_BOOKING_QUESTION_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: SLOT_BOOKING_QUESTION_FAIL,
				payload: res,
			});
		}
	} catch (err) {
		const errors = err.response.data;

		if (errors) {
			console.log(errors);
		}

		dispatch({
			type: SLOT_BOOKING_QUESTION_FAIL,
			payload: errors,
		});
	}
};

export const getSlotBookingDetails = (leadId) => async (dispatch) => {

	try {
		let dateRange = []
		// reducing the iteration to 2 from 3. Not allowing student to get and book slot for more than 1 day.
		for(let i = 0; i < 2; i ++){
			dateRange.push(getCurrentDate(i))
		}
		const responses = await Promise.all(dateRange.map(date => testapi.get(`/bookmydemo/leads/${leadId}/slots/v2/?date=${date}`)))
		let combinedSlotsData = []
		for(let response of responses){
			if(response.data.status !== "success"){
				return dispatch({
					type: GET_SLOT_BOOKING_FAIL,
					payload: response,
				});
			}
			combinedSlotsData = [ ...combinedSlotsData, ...response.data.slots ]
		}

		if (combinedSlotsData) {
			combinedSlotsData.sort(sortDateFunction);
			dispatch({
				type: GET_SLOT_BOOKING_SUCCESS,
				payload: { slots: combinedSlotsData },
			});
		}
	} catch (err) {
		// const errors = err.response.data;

		// if (errors) {
		// 	console.log(errors);
		// }

		dispatch({
			type: GET_SLOT_BOOKING_FAIL,
			payload: err.message,
		});
	}
};

export const bookSlot = (date, time, id , payment_transaction_id) => async (dispatch) => {
	// const body = { date, time };
	console.log(payment_transaction_id,"TRANSACTION ID")
	const body = { time: time, date: date , payment_transaction_id: payment_transaction_id};

	try {
		const res = await testapi.post(`bookmydemo/leads/${id}/demos/`, body);

		if (res.data.status === "success") {
			dispatch({
				type: SLOT_BOOKING_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: SLOT_BOOKING_FAIL,
				payload: res,
			});
		}
	} catch (err) {
		const errors = err.response.data;

		if (errors) {
			console.log(errors);
		}

		dispatch({
			type: SLOT_BOOKING_FAIL,
			payload: errors,
		});
	}
};

export const postSlotBookQuestion = (slotAnswer, id) => async (dispatch) => {
	// const body = { date, time };
	console.log(slotAnswer,"SLOT ANSWER")
	const body = { responses: [...slotAnswer] };

	try {
		const res = await testapi.post(`bookmydemo/leads/${id}/questions/`, body);

		if (res.data.status === "success") {
			dispatch({
				type: POST_SLOT_BOOKING_QUESTION_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: POST_SLOT_BOOKING_QUESTION_FALI,
				payload: res,
			});
		}
	} catch (err) {
		const errors = err.response.data;

		if (errors) {
			console.log(errors);
		}

		dispatch({
			type: POST_SLOT_BOOKING_QUESTION_FALI,
			payload: errors,
		});
	}
};

export const getBookedDemoDetails = (lead_id, demo_id) => async (dispatch) => {
	try {
		const res = await testapi.get(
			`bookmydemo/leads/${lead_id}/demos/?demo_id=${demo_id}`
		);

		if (res.data.status === "success") {
			dispatch({
				type: GET_BOOKED_DEMO_DETAILS_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_BOOKED_DEMO_DETAILS_FAIL,
				payload: res,
			});
		}
	} catch (err) {
		const errors = err.response.data;

		if (errors) {
			console.log(errors);
		}

		dispatch({
			type: GET_BOOKED_DEMO_DETAILS_FAIL,
			payload: errors,
		});
	}
};
