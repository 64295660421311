import React from "react";
import styled from "styled-components";
const LoadingSpinner = () => {
	return (
		<LoadingSpinnerStyled>
			<div className="spinner-container">
				<div className="loading-spinner"></div>
			</div>
		</LoadingSpinnerStyled>
	);
};

const LoadingSpinnerStyled = styled.div`
	@keyframes spinner {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	.loading-spinner {
		width: 200px;
		height: 200px;
		border: 10px solid #f3f3f3; /* Light grey */
		border-top: 10px solid #383636; /* Blue */
		border-radius: 50%;
		animation: spinner 1.5s linear infinite;
	}

	.spinner-container {
		display: grid;
		justify-content: center;
		align-items: center;
		height: 100vh;
	}
`;

export default LoadingSpinner;
