import React from "react";
import { Link } from "react-router-dom";
import { Flexbox } from "../common/FLexbox";
import { PrimaryButton } from "../common/PrimaryButton";
import styled from "styled-components";
import { Text } from "../common/Text";
import Loading from "./Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "../common/Card";
import styles from "../../styles/css/layouts/demoTable.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faClock } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { connect } from "react-redux";
import { gettingDemoClassDetails } from "../../actions/auth.js";
import FlashMessage from "../common/FlashMessage";
import store from "../../store";
import { loadUser } from "../../actions/auth.js";
import axios from "axios";
const DemoTable = ({ classDetails, gettingDemoClassDetails, userDetails ,auth}) => {
  let tokenValue = localStorage.getItem("token");

//   store.dispatch(loadUser())
// console.log('auth',auth)
  useEffect(() => {
    
    if(auth.isAuthenticated){
      gettingDemoClassDetails(tokenValue);
    }
  }, [auth.isAuthenticated]);

  let demoClassDetails = classDetails?.details;
  // console.log(demoClassDetails[2].payment_details,"DEMO CLASS DETAILS")

  const joinMeeting = (classroom_link) => {
    window.location.href = `/meeting/${classroom_link}`;
  };

  //Function to load razorpay script for the display of razorpay payment SDK.
  const loadRazorpayScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

//function will get called when clicked on the pay button.
async function displayRazorpayPaymentSdk(razorpaydetails) {
  console.log(razorpaydetails,"RAZOR PAY DETAILS")
  const res = await loadRazorpayScript(
      "https://checkout.razorpay.com/v1/checkout.js"
  );

  if (!res) {
      alert("Razorpay SDK failed to load. please check are you online?");
      return;
  }

    const merchantId = "rzp_test_tAG4Ez7D6Nyyfj";
    const amount = razorpaydetails.invoice_price * 100;
    const currency = null;
    const orderId = razorpaydetails.razor_pay_order_id;

  const options = {
    "key": "rzp_test_tAG4Ez7D6Nyyfj",
    "amount": amount, // 2000 paise = INR 20
    "name": "Ufaber Edutech Private Limited",
    "description": "cust_desc",
    "handler": function (response){
      window.location.reload();
  },

    "prefill": {
        "name": razorpaydetails.prefill.name,
        "email": razorpaydetails.prefill.email,
        "contact": razorpaydetails.prefill.contact,
    },
    "notes": {
        "address": "cust_address",
        "order_id": razorpaydetails.razor_pay_order_id,
        "transaction_id": razorpaydetails.notes.transaction_id,
        "paymentsystem_id": razorpaydetails.notes.paymentsystem_id,
        "demo_id": razorpaydetails.notes.demo_id,
        "is_paid_demo": razorpaydetails.notes.is_paid_demo,
        "package_id": razorpaydetails.notes.package_id,
        "product_info": razorpaydetails.notes.product_info,
        "brand": razorpaydetails.notes.brand
    },
    "theme": {
        "color": "#F37254"
    }
};

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}





  if (demoClassDetails === undefined) {
    return <Loading />;
  }

  return (
    <>
      {demoClassDetails.length === 0 ? (
        <FlashMessage
          type="warning"
          message="No Demo Found, Kindly Contact the Counsellor."
        />
      ) : (
        <div className={styles.demo_dashboard}>
          <div className={styles.demo_details_main_heading}>
            <h1>Demo Details</h1>
          </div>
          <div className={styles.demo_details_card_wrapper}>
            {demoClassDetails.map((demoDetails, index) => (
              <Card key={index}>
                {" "}
                <div className={styles.demo_details_mainwrapper}>
                  <div className={styles.demo_details_heading}>
                    <h2>{demoDetails.package}</h2>
                  </div>
                  <div className={styles.demo_details_subwrapper}>
                    <div className={styles.demo_details_subwrapper_one}>
                      <div className={styles.demo_details_specific}>
                        <FontAwesomeIcon
                          className={styles.demoDetailsIcon}
                          icon={faCalendarDays}
                        />
                        <p>
                          DATE : <span>{demoDetails.start_date}</span>
                        </p>
                      </div>
                      <div className={styles.demo_details_specific}>
                        <FontAwesomeIcon
                          className={styles.demoDetailsIcon}
                          icon={faClock}
                        />
                        <p>
                          SLOT : <span>{demoDetails.slot}</span>
                        </p>
                      </div>
                    </div>
                    <div className={styles.demo_details_subwrapper_two}>
                      <div className={styles.demo_details_specific}>
                        <p>
                          {demoDetails.demo_class_status != ""
                            ? demoDetails.demo_class_status
                            : "TO BE STARTED"}
                        </p>
                      </div>
                      <div className={styles.demo_details_specific}>
                        <p>
                        
                          {demoDetails?.payment_details != undefined
                            ? `AMOUNT : Rs. ${demoDetails?.payment_details?.razorpay?.invoice_price}/-`
                            : null}
                        </p>
                      </div>
                      <div className={styles.demo_details_specific}>
                        {" "}
                        {demoDetails.payment_details != undefined ? <button className={styles.paymentButton} onClick={()=>displayRazorpayPaymentSdk(demoDetails.payment_details.razorpay)}>Pay Now</button> :  demoDetails.demo_class_status === "Demo Cancelled" ? (
                          <button
                            disabled
                            className={styles.disabledStatusButton}
                          >
                            Demo Cancelled
                          </button>
                        ) : demoDetails.demo_class_status === "Present" ||  demoDetails.demo_class_status === "Absent" ? (
                          <button
                            disabled
                            className={styles.attendanceMarkedStatusButton}
                          >
                            Demo Attended
                          </button>
                          ) : demoDetails.demo_class_status === "Attendance not marked" ? (
                            <button
                              disabled
                              className={styles.attendanceNotMarkedStatusButton}
                            >
                              Join Class
                            </button>
                            ) : (
                          <button
                            className={styles.activeStatusButton}
                            onClick={() =>
                              joinMeeting(demoDetails.classroom_link)
                            }
                          >
                            Join Class
                          </button>
                        )}                           
                      </div>
                      {/* <div>
                        <form id='ecom-form' name="ecom" method="post" action="https://secure.payu.in/_payment">
                          <input type="hidden" name="key" value="gXMEKE" id='key'/>
                          <input type="hidden" name="txnid" value="XMP9Y4I8LQFJ5O5" id='txnid'/>
                          <input type="hidden" name="amount" value="85" id='amount'/>
                          <input type="hidden" name="productinfo" value="2263" id='productinfo'/>
                          <input type="hidden" name="firstname" value="Anubhav" id='firstname'/>
                          <input type="hidden" name="email" value="anubhavsharma9199@gmail.com" id='email'/>
                          <input type="hidden" name="hash" value="58ad63813ebf124cb143443cc11ce860c7ac6f16cfa21a0de15c94151aaf6d001575f47007f2411fc7655ac36bcd16e374b864b4f5edb1c0a6c3168bf7dcf3b7" id='hash'/>
                          <input type="hidden" name="udf1" value="1" id='udf1'/>
                          <input type="hidden" name="udf2" value="2" id='udf2'/>
                          <input type="hidden" name="udf3" value="3" id='udf3'/>
                          <input type="hidden" name="udf4" value="4" id='udf4'/>
                          <input type="hidden" name="udf5" value="5" id='udf5'/>
                          <input type="hidden" name="surl" value="https://trial.thefluentlife.com/pa/success/" id='surl'/>
                          <input type="hidden" name="furl" value="https://trial.thefluentlife.com/pa/failure/" id='furl'/>
                          <div className="text-center" onClick={()=>document.forms.ecom.submit()}>
                                <img src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/3b21a0f8754b499cb09bfbb95f755148.png"/>
                            </div>
                        </form>
                      </div> */}
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const DemoMessageWrapper = styled.div`
  padding: 30px;
  background: #fff;
  border-radius: 8px;
  margin: 20px;
  p {
    font-size: 22px;
    color: #000;
    font-weight: 500;
    text-align: left;
    margin-bottom: 0px;
  }
`;

const DemoTableWrapper = styled.div`
  width: 60%;
  padding: 30px;
  h1 {
    font-size: 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    text-align: left;
    margin-bottom: 15px;
  }
  table {
    width: 100%;
    table-layout: fixed;
  }
`;

const TableHeader = styled.div`
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  th {
    padding: 20px 15px;
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    text-transform: uppercase;
    border-radius: 10px;
    border-radius: 10px;
  }
`;

const TableContent = styled.div`
  overflow-x: auto;
  margin-top: 0px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  tr {
  }
  td {
    padding: 15px;
    text-align: left;
    vertical-align: middle;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    color: #000000;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

const mapStateToProps = (state) => ({
  userDetails: state.auth.user_login_details,
  auth: state.auth,
  classDetails: state.auth.class_details,
  error: state.auth.error,
});

export default connect(mapStateToProps, {
  gettingDemoClassDetails,
})(DemoTable);
