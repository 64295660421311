import React from 'react'
import styled from 'styled-components';
const DashBoardContent = ({ children }) => {
  return (
      <DashBoardContentStyle>
          {children}
      </DashBoardContentStyle>
   
  )
}
const DashBoardContentStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
  max-height: auto;
  width: 100%;
  object-fit: cover; // use "cover" to avoid distortion
  /* position: absolute; */
  z-index: -1;
  padding: 20px;
  background-color: #F9F9FA;
`;
export default DashBoardContent;