import React from "react";
import styled from "styled-components";
import img1 from "../../assets/images/img1.png";
import img2 from "../../assets/images/img2.png";
import img3 from "../../assets/images/img3.png";
import img4 from "../../assets/images/img4.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import { SlotBookingBrandColor } from "../../utils/slotBookingBrandUtilDetails";
import { useNavigate } from "react-router-dom";
import CorouselContentFactory from "./CorouselContent";

const SlotBookingHomeMobile = ({ demoData }) => {
	let navigate = useNavigate();
	let brandName = demoData?.pre_demo_brand_name || "IELTS NINJA";
	let name = demoData?.name || "Demo";
	let { primaryColor, secondaryColor, buttonColor, logo } =
		SlotBookingBrandColor.getColor(brandName);

	let pre_demo_package_id = demoData?.pre_demo_package_id ?? "";

	const noClickChooseSlot = () => {
		if (pre_demo_package_id !== "") {
			let path = `/demo-slots`;
			navigate(path);
		} else {
			toast.warning("Packge is not present", {
				position: toast.POSITION.TOP_LEFT,
			});
			return false;
		}
	};
	return (
		<StyledSlotBookingHomeMobile
			// background: linear-gradient(to top, white 76%, yellow 0% );
			// ${primaryColor}
			style={{
				background: `linear-gradient(to top, white 77%, ${secondaryColor} 0% )`,
			}}
		>
			<ToastContainer />
			<div className="mob-main">
				<div className="mob-top">
					<h1 className="mb-0">Hello {name} !</h1>
					<h2>Greetings from</h2>
					<img className="img-logo" src={logo} alt="" />
					<p>
						We are glad to offer you a{" "}
						<span> FREE TRIAL CLASS </span>
					</p>
					<OwlCarousel
						className="owl-carousel owl-theme owl-loaded"
						loop
						items={1}
						center={true}
						autoplay={true}
					>
						{CorouselContentFactory(brandName)}
					</OwlCarousel>
				</div>
				<div className="mob-bottom">
					<button
						onClick={noClickChooseSlot}
						style={{
							background: buttonColor,
						}}
					>
						Choose a slot
					</button>
				</div>
			</div>
		</StyledSlotBookingHomeMobile>
	);
};

const StyledSlotBookingHomeMobile = styled.div`
	box-sizing: border-box;
	height: 100vh;

	@media screen and (max-width: 1100px) {
		.right-sec1 {
			width: 470px;
		}
	}
	@media screen and (max-width: 950px) {
		.right-sec1 {
			width: 410px;
		}
		.right-top {
			width: 51.5vw;
		}
		.main {
			display: none;
		}
		.mob-main {
			display: flex;
			align-items: center;
			flex-direction: column;
		}
		.mob-top {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 300px;
			h1 {
				padding: 30px 0px 10px 0px;
				font-size: 21px;
				font-family: "Montserrat";
			
			}
			h2 {
				font-size: 26px;
				font-family: "Montserrat";
				font-weight: 600;
				margin-bottom: 5px;
			}
			p {
				text-align: center;
				font-size: 21px;
				font-family: "Montserrat";
				font-weight: 400;
			}
		}
		span {
			font-size: 21px;
			font-family: "Montserrat";
			font-weight: 600;
		}
		.img-logo {
			height: 28px;
			width: 212px;
			margin: 10px 0px;
		}
		.mob-carousel-heading {
			font-size: 18px;
			margin-top: 30px;
			margin-bottom: 15px;
		}
		.owl-dots {
			margin-top: 10px 
			margin-bottom: 20px ;
		}
		.mob-bottom {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: white;
			height: 100px;
			button {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 300px;
				height: 55px;
				color: white;
				border: none;
				border-radius: 5px;
				font-size: 20px;
			}
		}
	}
	@media screen and (min-width: 950px) {
		.mob-main {
			display: none;
		}
	}
	@media screen and (max-width: 400px) {
		.mob-top {
			h1 {
				font-size: 16px;
			}
			h2 {
				font-size: 18px;
			}
			p {
				font-size: 18px;
			}
			width: 280px;
		}
		span {
			font-size: 16px;
		}
		.mob-carousel-heading {
			font-size: 14px !important;
		}
	}
	.fp {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 700 !important;
		font-size: 16px !important;
		line-height: 19.5px !important;
		
	}
	.fp2 {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500 !important;
		font-size: 16px !important;
		line-height: 20px !important;
		margin-top: 0px !important;
	}
	.owl-carousel-main{
		width: 100%;
		background-color: #FFFFFF
	}
`;
export default SlotBookingHomeMobile;
