import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bookSlot, postSlotBookQuestion } from "../../actions/slotBooking";

export const CountDown = (timestamp) => {
	const distance = timestamp - new Date().getTime();
	const [timeData, setTimeData] = useState({
		hours: Math.floor(distance / (1000 * 60 * 60)),
		minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
		seconds: Math.floor((distance % (1000 * 60)) / 1000),
	});

	useEffect(() => {
		const interval = setInterval(() => {
			let { hours, minutes, seconds } = timeData;
			const distance = timestamp - new Date().getTime() - 1;
			hours = Math.floor(distance / (1000 * 60 * 60));

			minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			seconds = Math.floor((distance % (1000 * 60)) / 1000);
			setTimeData({ hours, minutes, seconds });
			if (hours === 0 && minutes === 5 && seconds === 0) {
				clearInterval(interval);
				console.log("clear interval");
			}
		}, 1000);
		if (
			timeData.hours <= 0 &&
			timeData.minutes <= 5 &&
			timeData.seconds <= 0
		) {
			clearInterval(interval);
		}
	}, []);

	return timeData;
};
