import React from "react";

export const Text = ({
	tag = "span",
	size,
	alt,
	center,
	weight,
	caps,
	opacity = 1,
	margin,
	padding,
	color,
	...props
}) => {
	const Tag = tag;
	const sx = {
		fontStyle: "normal",
		color: color ? color : "#263238",
		fontFamily: "Roboto",
		fontSize: size,
		fontWeight: weight || null,
		textAlign: center ? "center" : null,
		textTransform: caps ? "uppercase" : null,
		opacity: opacity,
		margin: margin || 0,
		padding: padding || 0,
		lineHeight: props.lineHeight || "",
	};

	return <Tag {...props} style={sx} />;
};

export const P1 = ({ opacity, margin, weight, center, color, size, ...props }) => (
	<Text
		{...props}
		tag="p"
		size={size || "28px"}
		weight={weight || 700}
		opacity={opacity}
		margin={margin}
		center={center}
		color={color}
	/>
);
export const P2 = ({ opacity, margin, weight, center, color, size, ...props }) => (
	<Text
		{...props}
		tag="p"
		size={size || "24px"}
		weight={weight || 500}
		opacity={opacity}
		margin={margin}
		center={center}
		color={color}
	/>
);
export const P3 = ({ opacity, margin, weight, center, color, size, ...props }) => (
	<Text
		{...props}
		tag="p"
		size={size || "24px"}
		weight={weight || 400}
		opacity={opacity}
		margin={margin}
		center={center}
		color={color}
	/>
);
export const P4 = ({ opacity, margin, weight, center, color, size, ...props }) => (
	<Text
		{...props}
		tag="p"
		size={size || "16px"}
		weight={weight || 400}
		opacity={opacity}
		margin={margin}
		center={center}
		color={color}
	/>
);
