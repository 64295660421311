import React from "react";
import styled from "styled-components";
import { useWindowDimensions } from "../../utils";
import { SlotBookingBrandColor } from "../../utils/slotBookingBrandUtilDetails";

const CheckBoxButton = ({
	choices,
	handleOnclickCheckOption,
	question,
	tag,
	selectedcheckOption,
	brandName,
}) => {
	//iterate throught object and get value selectedcheckOption
	let selected = selectedcheckOption[tag];
	let answer = selected?.answer;
	const { width } = useWindowDimensions();
	let mobilecardWidth1 = width < 768 ? "55px" : "100px";
	let mobilecardWidth2 = width < 768 ? "125px" : "140px";
	let mobilecardWidth3 = width < 768 ? "140px" : "190px";
	let { buttonColor } = SlotBookingBrandColor.getColor(brandName);
	return (
		<CheckBoxQuestion onClick={handleOnclickCheckOption}>
			{choices.map((choice, index) => {
				return (
					<div
						className={
							"check-box" + (answer === choice ? " active" : "")
						}
						key={index}
						style={
							answer === choice
								? {
										border: `1px solid  ${buttonColor}`,
										color: buttonColor,
								  }
								: {}
						}
					>
						<label
							style={
								choice.length >= 16
									? { width: mobilecardWidth3 }
									: choice.length < 5
									? { width: mobilecardWidth1 }
									: { width: mobilecardWidth2 }
							}
						>
							<input
								type="checkbox"
								value={choice}
								data-label={question}
								name={tag}
							/>
							<span>{choice}</span>
						</label>
					</div>
				);
			})}
		</CheckBoxQuestion>
	);
};

const CheckBoxQuestion = styled.div`
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
	padding-bottom: 1.4rem;

	.check-box {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 4px;
		background-color: #fff;
		border-radius: 4px;
		border: 1px solid #e2e2e2;
	}
	.active {
		border: 1px solid #072c57;
		color: #072c57;
	}

	label {
		float: left;
		line-height: 3em;
		/* padding: 0px 1.7rem; */
	}

	span {
		text-align: center;
		padding: 3px 0;
		display: block;
	}

	input {
		position: absolute;
		display: none;
		color: #fff !important;
	}
`;
export default CheckBoxButton;
