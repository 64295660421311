import React from "react";
import styled from "styled-components";
import { Text } from "../common/Text";
import Card from "../common/Card";
import styles from "../../styles/css/layouts/regularAndBatchTable.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCalendarDays,
  faClock,
  faHourglassStart,
  faChalkboardUser
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { gettingRegularAndBatchClassDetails } from "../../actions/auth.js";
import { useEffect } from "react";
import Loading from "../../components/layouts/Loading";
import { Link } from "react-router-dom";
import FlashMessage from "../common/FlashMessage";

const BatchAndRegularClassTable = ({
  classDetails,
  gettingRegularAndBatchClassDetails,
  userDetails,
}) => {
  let userPhoneNumber = localStorage.getItem("phone");
  let userEmailId = localStorage.getItem("email");
  useEffect(() => {
      gettingRegularAndBatchClassDetails(userPhoneNumber, userEmailId);
  }, [userPhoneNumber, userEmailId]);

  let batchAndRegularClassDetails = classDetails?.details;
  let batchClassDetails;
  let regularClassDetails;
  
  const joinMeeting = (meeting_id) => {
    window.location.href = `/meeting/${meeting_id}`;
  };



  if (classDetails?.message === undefined) {
    return <Loading/>;
  }

  if (batchAndRegularClassDetails != undefined) {
      batchClassDetails = batchAndRegularClassDetails.filter(
        (classes) => classes.class_details === "BATCH CLASS"
      );
      regularClassDetails = batchAndRegularClassDetails.filter(
        (classes) => classes.class_details === "REGULAR CLASS"
      );
  }
  

  return (
    <>
      {classDetails?.message === "User Authorized" && classDetails?.details.length === 0 ? (
        <FlashMessage
          type="warning"
          message="Thank you for logging in to join your classes. We are sorry to inform you that you do not have any classes scheduled for today. Please check your class schedule for the correct dates and times of your classes.If you believe there is an error in your class schedule or you have any other questions or concerns please contact our customer support team at contact@ufaber.com."
        />
      ) : classDetails?.message === "No Class Found." ? (
        <FlashMessage
          type="error"
          message="Thank you for signing up for our services. It appears that you have not entered your registered mobile number or email address. Please double-check the mobile number or email address you entered to ensure they are correct. If you continue to have trouble logging in, please contact our customer support team at contact@ufaber.com."
        />
      ) : (
        <div className={styles.regular_batch_dashboard_wrapper}>
          {batchClassDetails.length != 0 ? (
            <div className={styles.demo_dashboard}>
              <div className={styles.demo_details_main_heading}>
                <h1>Batch Class Details</h1>
              </div>
              <div className={styles.demo_details_card_wrapper}>
                {batchClassDetails.map((batchClassDetails, index) => (
                  <Card key={index}>
                    {" "}
                    <div className={styles.demo_details_mainwrapper}>
                      <div className={styles.demo_details_heading}>
                        <h2>{batchClassDetails.class_name}</h2>
                      </div>
                      <div className={styles.demo_details_subwrapper}>
                        <div className={styles.demo_details_subwrapper_one}>
                          <div className={styles.demo_details_specific}>
                            <FontAwesomeIcon
                              className={styles.demoDetailsIcon}
                              icon={faUser}
                            />
                            <p>
                              NAME :{" "}
                              <span>{batchClassDetails.student_name}</span>
                            </p>
                          </div>
                          <div className={styles.demo_details_specific}>
                            <FontAwesomeIcon
                              className={styles.demoDetailsIcon}
                              icon={faCalendarDays}
                            />
                            <p>
                              DATE : <span>{batchClassDetails.date}</span>
                            </p>
                          </div>
                          <div className={styles.demo_details_specific}>
                            <FontAwesomeIcon
                              className={styles.demoDetailsIcon}
                              icon={faClock}
                            />
                            <p>
                              CLASS TIME :{" "}
                              <span>{batchClassDetails.slot[0]}</span>
                            </p>
                          </div>
                          <div className={styles.demo_details_specific}>
                            <FontAwesomeIcon
                              className={styles.demoDetailsIcon}
                              icon={faHourglassStart}
                            />
                            <p>
                              CLASS DURATION :{" "}
                              <span>{batchClassDetails.class_duration}</span>
                            </p>
                          </div>
                          <div className={styles.demo_details_specific}>
                            <FontAwesomeIcon
                              className={styles.demoDetailsIcon}
                              icon={faChalkboardUser}
                            />
                            <p>
                              TEACHER :{" "}
                              <span>{batchClassDetails.teacher_name}</span>
                            </p>
                          </div>
                        </div>
                        <div className={styles.demo_details_subwrapper_two}>
                          <div className={styles.demo_details_specific}>
                            <p>{batchClassDetails.today_class_status}</p>
                          </div>
                          <div className={styles.demo_details_specific}>
                            {batchClassDetails.zoom_link != null ? (
                              <button
                                className={styles.activeStatusButton}
                                onClick={() =>
                                  joinMeeting(batchClassDetails.classroom_link)
                                }
                              >
                                Join Class
                              </button>
                            ) : (
                              <button className={styles.disabledStatusButton}>
                                Not Scheduled
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          ) : null}
          {regularClassDetails.length != 0 ? (
            <div className={styles.demo_dashboard}>
              <div className={styles.demo_details_main_heading}>
                <h1>Regular Class Details</h1>
              </div>
              <div className={styles.demo_details_card_wrapper}>
                {regularClassDetails.map((regularClassDetails, index) => (
                  <Card key={index}>
                    {" "}
                    <div className={styles.demo_details_mainwrapper}>
                      <div className={styles.demo_details_heading}>
                        <h2>{regularClassDetails.class_name}</h2>
                      </div>
                      <div className={styles.demo_details_subwrapper}>
                        <div className={styles.demo_details_subwrapper_one}>
                          <div className={styles.demo_details_specific}>
                            <FontAwesomeIcon
                              className={styles.demoDetailsIcon}
                              icon={faUser}
                            />
                            <p>
                              NAME :{" "}
                              <span>{regularClassDetails.student_name}</span>
                            </p>
                          </div>
                          <div className={styles.demo_details_specific}>
                            <FontAwesomeIcon
                              className={styles.demoDetailsIcon}
                              icon={faCalendarDays}
                            />
                            <p>
                              DATE : <span>{regularClassDetails.date}</span>
                            </p>
                          </div>
                          <div className={styles.demo_details_specific}>
                            <FontAwesomeIcon
                              className={styles.demoDetailsIcon}
                              icon={faClock}
                            />
                            <p>
                              CLASS TIME :{" "}
                              <span>{regularClassDetails.slot[0]}</span>
                            </p>
                          </div>
                          <div className={styles.demo_details_specific}>
                            <FontAwesomeIcon
                              className={styles.demoDetailsIcon}
                              icon={faHourglassStart}
                            />
                            <p>
                              CLASS DURATION :{" "}
                              <span>{regularClassDetails.class_duration}</span>
                            </p>
                          </div>
                          <div className={styles.demo_details_specific}>
                            <FontAwesomeIcon
                              className={styles.demoDetailsIcon}
                              icon={faChalkboardUser}
                            />
                            <p>
                              TEACHER :{" "}
                              <span>{regularClassDetails.teacher_name}</span>
                            </p>
                          </div>
                        </div>
                        <div className={styles.demo_details_subwrapper_two}>
                          <div className={styles.demo_details_specific}>
                            <p>{regularClassDetails.today_class_status}</p>
                          </div>

                          <div className={styles.demo_details_specific}>
                            {" "}
                            {regularClassDetails.zoom_link != null ? (
                              <button
                                className={styles.activeStatusButton}
                                onClick={() =>
                                  joinMeeting(
                                    regularClassDetails.classroom_link
                                  )
                                }
                              >
                                Join Class
                              </button>
                            ) : (
                              <button className={styles.disabledStatusButton}>
                                Not Scheduled
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};


const mapStateToProps = (state) => ({
  userDetails: state.auth.user_login_details,
  auth: state.auth,
  classDetails: state.auth.class_details,
  error: state.auth.error,
});

export default connect(mapStateToProps, {
  gettingRegularAndBatchClassDetails,
})(BatchAndRegularClassTable);
