export const demoLoginBrandDetails = {
    getBrand: (domainName) => {
        switch (domainName) {
          case "therealschool":
            return "REAL SCHOOL";
          case "ieltsninja":
            return "IELTS NINJA";
          case "thefluentlife":
            return "FLUENT LIFE";
          case "upscpathshala":
            return "UPSC PATHSHALA";
          case "abroadninja":
                return "ABROAD NINJA";
          case "emaester":
                return "EMAESTER";
          default:
            return "IELTS NINJA";
        }
    },

    getDemoLoginBrandDetails : (brand) => {
        switch (brand) {
          case "REAL SCHOOL":
            return {
              brandColor: "#FF3A58",
              loginFormBackgroundColor: "#FFF",
              loginButtonBackgroundColor: "#FF3A58",
              loginPageImage:
                "https://s3.us-east-2.wasabisys.com/ufaber-lms/files/95409bf0d78a7828b10b1e8da188155c.svg",
              loginPageHeading: "Experience",
              loginPageDescription: "Demo Live Session",
              brandLogo:
                "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/60b7df8bd06d3565248899365762e4e5.1673848839846160",
              brandWebsiteUrl: "https://therealschool.in/",
              otpVerifyButtonBackgroundColor: "#FF3A58",
            };
          case "IELTS NINJA":
            return {
              brandColor: "#D23939",
              loginFormBackgroundColor: "#FFF",
              loginButtonBackgroundColor: "#D23939",
              loginPageImage:
                "https://s3.us-east-2.wasabisys.com/ufaber-lms/files/cc66d1227529f4098a1364ed7637c94e.svg",
              loginPageHeading: "Experience",
              loginPageDescription: "Demo Live Session",
              brandLogo:
                "https://s3.us-east-2.wasabisys.com/ufaber-lms/files/cf77f0080544bf23953166da0d4bfec8.svg",
              brandWebsiteUrl: "https://ieltsninja.com/",
              otpVerifyButtonBackgroundColor: "#D23939",
            };
          case "FLUENT LIFE":
            return {
              brandColor: "#1D3A6A",
              loginFormBackgroundColor: "#FFF",
              loginButtonBackgroundColor: "#1D3A6A",
              loginPageImage:
                "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/0d6ebc98b938750d736c51bc5de73f03.167585599808781",
              loginPageHeading: "Experience",
              loginPageDescription: "Demo Live Session",
              brandLogo:
                "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/6e41f66987394dddb3e729822442e29f.png",
              brandWebsiteUrl: "",
              otpVerifyButtonBackgroundColor: "#1D3A6A",
            };
          case "UPSC PATHSHALA":
            return {
              brandColor: "#8E14FF",
              loginFormBackgroundColor: "#FFF",
              loginButtonBackgroundColor: "#8E14FF",
              loginPageImage:
                "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/c33cb0c5538961064b4b49ebffea122a.167585604388881",
              loginPageHeading: "Experience",
              loginPageDescription: "Demo Live Session",
              brandLogo:
                "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/434215874d904edda4f414aa79e0c5a7.png",
              brandWebsiteUrl: "",
              otpVerifyButtonBackgroundColor: "8E14FF",
            };
          case "ABROAD NINJA":
            return {
              brandColor: "#FC997C",
              loginFormBackgroundColor: "#FFF",
              loginButtonBackgroundColor: "#FC997C",
              loginPageImage:
                "https://s3.us-east-2.wasabisys.com/ufaber-lms/files/86a25db67d8aa2c2dbd3a451be611345.svg",
              loginPageHeading: "Experience",
              loginPageDescription: "Demo Live Session",
              brandLogo:
                "https://s3.us-east-2.wasabisys.com/ufaber-lms/files/f9212a8993b3ee44fa979d321d52f7e9.svg",
              brandWebsiteUrl: "",
              otpVerifyButtonBackgroundColor: "#FC997C",
            };
          case "EMAESTER":
            return {
              brandColor: "#FF1D1D",
              loginFormBackgroundColor: "#FFF",
              loginButtonBackgroundColor: "#FF1D1D",
              loginPageImage:
                "https://s3.us-east-2.wasabisys.com/ufaber-lms/files/0b2f4480aef04c66c1479d03e5020d0f.svg",
              loginPageHeading: "Experience",
              loginPageDescription: "Demo Live Session",
              brandLogo:
                "https://s3.us-east-2.wasabisys.com/ufaber-lms/files/96d6f2e7e1f705ab5e59c84a6dc009b2.svg",
              brandWebsiteUrl: "",
              otpVerifyButtonBackgroundColor: "#FF1D1D",
            };
          default:
            return {
              brandColor: "#FF3A58",
              loginFormBackgroundColor: "#FFF",
              loginButtonBackgroundColor: "#FF3A58",
              loginPageImage:
                "https://s3.us-east-2.wasabisys.com/ufaber-lms/files/95409bf0d78a7828b10b1e8da188155c.svg",
              loginPageHeading: "Experience",
              loginPageDescription: "Demo Live Session",
              brandLogo:
                "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/60b7df8bd06d3565248899365762e4e5.1673848839846160",
              brandWebsiteUrl: "https://therealschool.in/",
              otpVerifyButtonBackgroundColor: "#FF3A58",
            };
        }
    }
}