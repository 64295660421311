import React from "react";
import styled from "styled-components";
// https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/a1587fe3c8c5423cae3cc1cc1bea51c2.svg
const ConfirmationPopUp = ({ setIsconfirmed, brandName }) => {
	return (
		<ConfirmationPopUpStyled>
			<div className="pr-head pr-show">
				<div className="pr-inner">
					<img
						src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/a1587fe3c8c5423cae3cc1cc1bea51c2.svg"
						alt="confirm svg"
					/>
					<div className="book-container">
						<h1>Demo class booked</h1>
						<p>
							Your <span>Demo Class </span>for {brandName} is
							confirmed. Alongside are the details.
						</p>
						<button
							className="close-btn popup-close"
							onClick={() => setIsconfirmed(false)}
						>
							Close
						</button>
					</div>
				</div>
			</div>
		</ConfirmationPopUpStyled>
	);
};

const ConfirmationPopUpStyled = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 2;
	.pr-show {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 45%;
		height: 70%;
	}
	.pr-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		background-color: white;
		border-radius: 10px;
		margin: 124px 0;
		height: 100%;
		left: 15px;
		border-radius: 25px;
		padding: 30px 45px;
	}
	.book-container {
		text-align: center;
		margin-top: 25px;
		& h1 {
			font-family: "Roboto";
			font-style: normal;
			font-weight: 700;
			font-size: 24px;
			line-height: 28px;
			color: #333333;
		}
		& p {
			font-family: "Roboto";
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 19px;
			text-align: center;
			opacity: 0.8;

			color: #333333;
		}
		& span {
			font-weight: 900;
			font-size: 18px;
			line-height: 19px;
			opacity: 1;
			color: black;
		}
		.popup-close {
			text-decoration: none;
			color: #fff;
			background: #263238;
			border-radius: 65px;
			margin-top: 20px;
			padding: 12px;
			width: 18%;
		}
	}
	@media screen and (max-width: 768px) {
		.pr-show {
			width: 95%;
			height: 70%;
		}
		.pr-inner {
			padding: 33px 0px;
			margin: 148px 0;
		}
		.popup-close {
			width: 24% !important;
		}
	}
`;
export default ConfirmationPopUp;
