import React from "react";
import styled from "styled-components";
import logo from "../assets/images/ielts_name.png";
import { InputItem } from "../components/common/InputItem";
import { SelectItem } from "../components/common/SelectItem";
import { default as ReactSelect } from "react-select";
import { CheckBoxItem } from "../components/common/CheckBoxItem";
import { components } from "react-select";
import { getSlotBookingQuestions } from "../actions/slotBooking";
import { connect } from "react-redux";
import { useEffect } from "react";
const colourOptions = [
	{ value: "English", label: "English" },
	{ value: "Hindi", label: "Hindi" },
	{ value: "Marathi", label: "Marathi" },
	{ value: "Kannada", label: "Kannada" },
	{ value: "Telugu", label: "Telugu" },
	{ value: "Tamil", label: "Tamil" },
	{ value: "Malayalam", label: "Malayalam" },
	{ value: "Bengali", label: "Bengali" },
	{ value: "Others", label: "Others" },
];
const Option = (props) => {
	return (
		<div>
			<components.Option {...props}>
				<input
					type="checkbox"
					checked={props.isSelected}
					onChange={() => null}
				/>{" "}
				<label>{props.label}</label>
			</components.Option>
		</div>
	);
};

const DemoQuestion = ({ getSlotBookingQuestions }) => {
	const [optionSelected, setOptionSelected] = React.useState("");

	getSlotBookingQuestions(3543737);

	const handleChange = (selected) => {
		setOptionSelected(selected);
	};

	return (
		<DemoQuestionStyled>
			<div className="main ">
				<div className="header-top">
					<div className="header-inner">
						<img src={logo} alt="" />
					</div>
				</div>

				<FlexColumn>
					<WhiteFlexColumn>
						<FlexRow3>
							<label>Name</label>
							<label>Contact No.</label>
						</FlexRow3>
						<FlexRow4>
							<InputItem
								type="text"
								name="username"
								placeholder="Username"
								padding={"12px 20px 12px 15px"}
							></InputItem>
							<InputItem
								type="text"
								name="Contact No."
								placeholder="Contact No."
								padding={"12px 20px 12px 15px"}
							></InputItem>
						</FlexRow4>
						<Text10>Email ID</Text10>
						<FlexRow4>
							<InputItem
								type="text"
								name="email"
								placeholder="email"
								padding={"12px 20px 12px 15px"}
							></InputItem>
						</FlexRow4>

						<Text10>
							Which of the following best describes you?
						</Text10>
						<FlexRow4>
							<SelectItem>
								<option value="">Select</option>
								<option value="Doctor">Doctor</option>
								<option value="Software Engineer">
									Software Engineer
								</option>
								<option value="Other Engineer">
									Other Engineer
								</option>
								<option value="Nurse">Nurse</option>
								<option value="Student">Student</option>
								<option value="Housewife">Housewife</option>
								<option value="Other">Other</option>
							</SelectItem>
						</FlexRow4>
						<Text10>What language(s) do you speak at home?</Text10>
						<FlexRow4>
							<ReactSelect
								options={colourOptions}
								isMulti
								closeMenuOnSelect={false}
								hideSelectedOptions={false}
								components={{
									Option,
								}}
								onChange={handleChange}
								allowSelectAll={true}
								value={optionSelected}
								className="react-select"
							/>
						</FlexRow4>
						<Text10>Have you taken the test before?</Text10>
						{/* <FlexRow3>
				
							<label className="test-check">
								Yes
								<CheckBoxItem
									value={0}
									type="checkbox"
									checked={checkedState[0]}
									onChange={CheckCheckBox}
								/>
							</label>
							<label className="test-check">
								No
								<CheckBoxItem
									value={1}
									type="checkbox"
									checked={checkedState[1]}
									onChange={CheckCheckBox}
								/>
							</label>
						</FlexRow3> */}
					</WhiteFlexColumn>
				</FlexColumn>
			</div>
		</DemoQuestionStyled>
	);
};

const FlexColumn = styled.div`
	margin-top: 116px;
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	@media screen and (max-width: 650px) {
		margin-top: 50px;
	}
`;

const WhiteFlexColumn = styled.div`
	box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.2);
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 16px;
	padding: 71px 165px 61.5px 165px;

	@media screen and (max-width: 650px) {
		padding: 50px 20px;
	}
`;
const FlexRow3 = styled.div`
	align-self: stretch;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 185px;
	align-items: center;
	padding: 0px 2px;
	margin: 0px 0px 27px 0px;
`;
const FlexRow4 = styled.div`
	align-self: stretch;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 17px;
	align-items: center;
	margin: 0px 0px 27px 0px;
`;
const Text10 = styled.div`
	font-size: 14px;
	font-family: Montserrat;
	font-weight: 600;
	margin: 0px 0px 27px 2px;
`;

const DemoQuestionStyled = styled.div`
	.header-top {
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;
		img {
			width: 190px;
			height: 22px;
		}
	}
	.header-inner {
		width: 1110px;
	}

	@media screen and (max-width: 850px) and (min-width: 650px) {
		.header-inner {
			width: 620px;
		}
	}

	@media screen and (max-width: 650px) {
		.header-top {
			img {
				width: 160px;
				height: 21px;
			}
		}
		.header-inner {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.react-select {
		width: 100%;
	}
	.test-check {
		position: relative;
		cursor: pointer;
		font-size: 1.1em;

		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
`;

const mapStateToProps = (state) => ({
	guestDetails: state.auth?.guest_regester,
	auth: state.auth,
	class_link_details: state.auth.link_details,
	error: state.auth.error,
});

export default connect(mapStateToProps, {
	getSlotBookingQuestions,
})(DemoQuestion);
