import React from "react";
import { useState } from "react";
import styles from "../../styles/css/common/card.module.css"; 

const Card = ({ children }) => {
  return (
    <>
      <div className={`${styles.card_wrapper}`}>
        <div className={`${styles.card_content}`}>{children}</div>
      </div>
    </>
  );
};

export default Card;
