import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy,faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import styled from "styled-components";
import Loading from "./Loading";
import { unstable_HistoryRouter, useNavigate, useParams } from "react-router-dom";
import { validateClassLink } from "../../actions/auth.js";
import { useEffect } from "react";

const MeetingInfo = ({ meeting_details, user_details,validateClassLink }) => {
  console.log("MEETING")
  const { id } = useParams();

	useEffect(() => {
		validateClassLink(id);
	}, [id, validateClassLink]);

  const url = meeting_details?.zoom_link;
  const userName = user_details?.username;

  const joinClass = (url) => {
    window.open(url,"_blank")
  }

  let navigate = useNavigate()
  // let history = unstable_HistoryRouter()


  if (!meeting_details?.status) {
    return <Loading />;
  }
  return (
    <MeetingInfoStyle>
      {meeting_details?.status === "ERROR" ? (
        <div>
          Welcome {userName} , {meeting_details.error}
        </div>
      ) : (
        <>
          {meeting_details?.link_generated === true ? (
              <div>
                <button className="back-btn" onClick={()=>navigate(-1)}><FontAwesomeIcon className="icon"
                  icon={faArrowLeft}/>Back</button>
              <div className="meeting-header">
                <h3>Welcome {userName} Your meeting's ready</h3>
              </div>
              {/* <p className="info-text">
                Please copy the the link below and start your meeting.
              </p> */}
              <button className="join_class_btn" onClick={() => joinClass(url)}>
                Join Class
              </button>
              {/* <div className="meet-link">
                <span>{url}</span>
                <FontAwesomeIcon
                  className="icon"
                  icon={faCopy}
                  onClick={() => navigator.clipboard.writeText(url)}
                />
              </div> */}
              <div className="permission-text">
                <p className="small-text">
                  Please Follow the instructions to join the zoom meeting.
                </p>
                {/*zoom instruction  list */}
                <ol>
                  {/* <li>
                      <span>1. </span>
                      <span>Copy the Zoom Link and paste into new tab.</span>
                    </li> */}
                  <li>
                    <span>1. </span>
                    <span>Click on the "Join" button.</span>
                  </li>
                  <li>
                    <span>2. </span>
                    <span>Click on the "Start Meeting" button.</span>
                  </li>
                </ol>
              </div>
              <p className="small-text">
                In case of any technical difficulties and if you are not able to
                access the zoom link, via support@ufaber.com{" "}
              </p>
            </div>
          ) : (
            <>
              <div className="meeting-header">
                <h3>
                  Welcome {userName} , your meeting is scheduled at{" "}
                  {meeting_details?.start_date} at {meeting_details?.slot}
                </h3>
              </div>
            </>
          )}
        </>
      )}
    </MeetingInfoStyle>
  );
};

const MeetingInfoStyle = styled.div`
  // position: absolute;
  /* top: 50px; */
  background: #fff;
  border-radius: 10px;
  padding: 25px;
  line-height: 1.9;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 90%; */
  margin: 63px 10px;

  .meeting-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #222;
    h3 {
      margin: 0;
      font-size: 18px;
      font-weight: 400;
    }
    .icon {
      cursor: pointer;
      font-size: 20px;
    }
  }
  .add-people-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #04796a;
    color: #fff;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    border: none;
    margin-top: 20px;
    .icon {
      font-size: 12px;
      margin-right: 10px;
    }
  }
  .info-text {
    color: #222;
    font-size: 14px;
  }
  .meet-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f1f3f5;
    padding: 15px;
    border-radius: 5px;
    span {
      font-size: 14px;
      font-weight: 600;
      border: none;
      outline: none;
      background: none;
      flex: 1;
      color: #555;
    }
    .icon {
      cursor: pointer;
      color: #555;
    }
  }
  .permission-text {
    display: flex;
    flex-direction: column;
  }
  .small-text {
    font-size: 13px;
    color: #222;
  }

  .permission-text ol {
    font-size: 13px;
    color: #222;
  }

  .join_class_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 20px;
    margin: 10px 0px;
    outline: none;
    border: none;
    background-color: #ab0000;
    color: #ffffff;
    border-radius: 40px;
    transition: all 0.4s;
  }

  .join_class_btn: hover {
    transform: translateY(-4px);
  }

  .back-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px;
    margin: 10px 0px;
    border: none;
    outline: none;
    width: 80px;
    border-radius: 8px;
    color: #ffffff;
    background-color: #404bd9;
  }
`;
const mapStateToProps = (state) => ({
  meeting_details: state.auth.meeting_details,
  user_details: state.auth.user,
});

export default connect(mapStateToProps, {validateClassLink})(MeetingInfo);
