import axios from "axios";
// Create an instance of axios
//baseURL: 'https://classroom.ufaber.com/api',
const api = axios.create({
	// baseURL: "http://127.0.0.1:8001/api",
	baseURL: "https://classroom.ufaber.com/api",
	headers: {
		"Content-Type": "application/json",
	},
});

api.interceptors.response.use(
	(res) => {
		return res;
	},
	(err) => {
		if (err.response.status === 401) {
			console.log("Token has expired or user is no longer authenticated");
		}
		return Promise.reject(err);
	}
);

export default api;
