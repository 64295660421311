import React from 'react'

import styled from 'styled-components';
import OptionList from '../common/OptionList';
import { faEdit, faShare } from "@fortawesome/free-solid-svg-icons";
const MoreOption = () => {

  const options = [
    { id:'WhiteBoard', title: 'WhiteBoard', icon: faEdit ,component:''},
    { id:'ScreenSharing', title: 'ScreenSharing', icon: faShare , component:''},
    { id:'more1', title: '', icon: '', component:''},
    { id:'more2', title: '', icon: '', component: ''},
    { id:'more3', title: '', icon: '', component:'' },
  ]
  return (
    <MoreOptionStyle>
        <OptionList options={options}>
        </OptionList>
    </MoreOptionStyle>
  )
}
const MoreOptionStyle = styled.div`
    position: absolute;
    border-radius: 15px 15px 0 15px;
    bottom: 70px;
    right: 220px;
    background: rgb(255, 255, 255);
    // height: calc(36vh - 94px);
    width: 273px;
    box-sizing: border-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-direction: column;
    max-height: 873px;
    min-width: 260px;
    
`;

export default MoreOption