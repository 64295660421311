import React from "react";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { SlotBookingBrandColor } from "../../utils/slotBookingBrandUtilDetails";
import { useNavigate } from "react-router-dom";
import CorouselContentFactory from "./CorouselContent";


const SlotBookingHomeDesktop = ({ demoData }) => {

	console.log(demoData," DEMO DATA")
	let navigate = useNavigate();
	let brandName = demoData?.pre_demo_brand_name || "IELTS NINJA";
	// if (brandName != "IELTS NINJA") {
	// 	if (brandName != "THE FLUENTLIFE") {
	// 		setDisableCarousel(true)
	// 	}
	// }
	const brandArray = ["IELTS NINJA", "THE FLUENTLIFE"]
	
	let name = demoData?.name || "Demo";
	let pre_demo_package_id = demoData?.pre_demo_package_id ?? "";
	const noClickChooseSlot = () => {
		if (pre_demo_package_id !== "") {
			let path = `/demo-slots`;
			navigate(path);
		} else {
			toast.warning("Package is not present", {
				position: toast.POSITION.TOP_LEFT,
			});
			return false;
		}
	};
	let { primaryColor, secondaryColor, buttonColor, logo } =
		SlotBookingBrandColor.getColor(brandName);
	let isCourouselPresent = brandArray.includes(brandName)

	return (
    <SlotBookingHomeDesktopStyled
      style={{
        backgroundColor: primaryColor,
      }}
    >
      <ToastContainer />
      <div className="row">
        <div className={isCourouselPresent ? "col-6 left" : "col-12"}>
          <div
            className="row left-top"
            style={{
              backgroundColor: secondaryColor,
            }}
          >
            <div className="left-sec1">
              <h1 className="mb-3">Hello {name} !</h1>
              <h2>Greetings from</h2>
              <img src={logo} alt="" />
              <div className="left-sec1-msg">
                <p className="p1">We are glad to offer you a</p>
                <p>
                  <span> FREE TRIAL CLASS </span>
                </p>
              </div>
            </div>
          </div>
          <div
            className="row left-bottom"
            // style={{
            // 	backgroundColor: secondaryColor,
            // }}
          >
            <div className="panel panel-primary">
              <div className="panel-body">
                <button
                  onClick={noClickChooseSlot}
                  className="text-on-pannel text-primary"
                  style={{ background: buttonColor }}
                >
                  Choose a slot
                </button>
              </div>
            </div>
            <div
              className="left-bottom"
              style={{ backgroundColor: primaryColor }}
            >
              <p>
                {new Date().getFullYear()} © uFaber, All rights reserved uFaber
                Edutech
              </p>
            </div>
          </div>
        </div>
		<div className="col-6 right" style={{ display: isCourouselPresent ? "" : "none" }}>
          <div className="right-over">
            <div
              className="row right-top"
              style={{
                backgroundColor: secondaryColor,
              }}
            >
              <div className="right-sec1">
                <div className="right-sec1-inner">
                  {/* <p className="right-heading">
										Here is what you can expect in this
										class
									</p> */}
                  <OwlCarousel
                    className="owl-carousel owl-theme owl-loaded"
                    loop
                    items={1}
                    center={true}
                    autoplay={true}
                  >
                    {CorouselContentFactory(brandName)}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SlotBookingHomeDesktopStyled>
  );
};

const SlotBookingHomeDesktopStyled = styled.div`
	box-sizing: border-box;

	height: 100vh;

	.carousel .slider-wrapper {
		width: 25% !important;
	}
	.text-on-pannel {
		height: auto;
		position: relative;
		margin-top: -47px;
		border-radius: 5px;
		width: 350px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 20px;
		cursor: pointer;
		color: white !important;
		text-decoration: none;
	}

	.panel {
		background: transparent;
		box-shadow: none;
	}
	.panel-body {
		border: none;
		padding-top: 02px !important;
	}
	.panel-primary {
		border: none !important;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		border: none;
	}

	.row {
		/* margin-right: 0px; */
	}
	.left {
		/* height: 100vh; */
	}
	.left-top {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 441px;

		a {
			font-size: 21px;
			width: 100%;
			height: 60px;
			text-align: center;
			text-decoration: none;
			color: white;

			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			box-shadow: 2px 2px 0px #00000029;
		}
	}
	.left-sec1 {
		width: 381px;
		border: 1px;
		margin-left: 20px;
		.left-sec1-msg {
			margin-top: 50px;
		}
		h1 {
			font-size: 16px;
			font-family: "Montserrat";
			font-weight: 500;
		}
		p {
			span {
				font-size: 21px;
				font-family: "Montserrat";
				font-weight: 600;
			}
			font-size: 21px;
			font-family: "Montserrat";
			font-weight: 400 !important;
		}
		h2 {
			font-size: 26px;
			font-family: "Montserrat";
			font-weight: 700;
		}
		img {
			height: 36px;
			width: 212px;
			margin-bottom: 30px;
			margin-top: 14px;
		}
	}
	.p1 {
		margin-bottom: 0px;
		width: 80%;
	}
	.fp {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700 !important;
		font-size: 24px !important;
		line-height: 29px !important;
	}
	.fp2 {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 500 !important;
		font-size: 16px !important;
		line-height: 20px !important;
		margin-top: 0px !important;
	}
	.left-bottom {
		display: flex;
		align-items: center;
		justify-content: center;
		p {
			margin-top: 233px;
			font: normal normal normal 13px/41px Gotham;
			letter-spacing: 0.65px;
			text-align: center;
			color: #242424;
		}
	}
	.left-sec2 {
		width: 380px;
		margin-left: 20px;
		position: relative;
		border: solid;
	}
	.right-top {
		height: 441px;

		width: 51vw;
	}
	.right-bottom {
		background-color: black;
	}
	.right-sec1 {
		width: 540px;
		height: 100vh;
		position: absolute;
		z-index: 1;
		background-color: white;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.item {
			img {
				width: 375px;
				height: 233px;
			}
		}
	}
	.item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		p {
			font-family: "Montserrat";
			font-size: 28px;
			font-weight: 500;
			text-align: center;
			margin-top: 20px;
		}
	}
	.right-heading {
		font-family: "Montserrat";
		font-size: 18px;
		font-weight: 500;
		text-align: center;
		margin-top: 90px;
		margin-bottom: 50px;
	}
	.p2 {
		width: 70%;
	}
	.p3 {
		width: 60%;
	}
	.right-sec1-inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 380px;
	}
	.owl-dots {
		/* margin-top: 135px !important;
		margin-bottom: 50px !important; */
	}

	.left-sec1-msg {
		/* width: 381px;
		border: 1px;
		margin-left: 20px;
		h1 {
			font-size: 16px;
			font-family: "Montserrat";
			font-weight: 500;
		}
		p {
			span {
				font-size: 21px;
				font-family: "Montserrat";
				font-weight: 600;
			}
			font-size: 21px;
			font-family: "Montserrat";
			font-weight: 400 !important;
		}
		h2 {
			font-size: 26px;
			font-family: "Montserrat";
			font-weight: 700;
		} */
	}
`;
export default SlotBookingHomeDesktop;
