import styled from "styled-components";

export const PrimaryButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.padding || "10px 25px"};
  background: ${(props) => props.background || "#007bff"};
  color: ${(props) => props.color || "#ffffff"};
  border-radius: 10px;
  border: ${(props) => props.border || "none"};
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  font-size: ${(props) => props.size || "auto"};
  opacity: ${(props) => props.opacity || 1};
  margin: ${(props) => props.margin || ""};
  cursor: ${(props) => props.cursor || "pointer"};
`;
