import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import setAuthToken from "../utils/setAuthToken";
import store from "../store";
import { loadUser } from "../actions/auth";
import Loading from "../components/layouts/Loading";

const AuthInit = ({ children, auth }) => {
	const [showSplashScreen, setShowSplashScreen] = useState(true);
	useEffect(() => {
		setShowSplashScreen(true);
		if (localStorage.token) {
			setAuthToken(localStorage.token);
		}
		store.dispatch(loadUser());
		if (!auth.loading) {
			setShowSplashScreen(false);
		}

		if(localStorage.token === "undefined" || localStorage.token === ""){
			localStorage.clear()
		}

		if (!localStorage.token && auth.loading && !auth.isAuthenticated) {
			setShowSplashScreen(false);
		}
	}, [auth.loading]);


	return showSplashScreen ? (<Loading/>) : <>{children}</>;
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, {})(AuthInit);
