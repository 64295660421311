import React from "react";
import styled from "styled-components";
import OptionList from "../common/OptionList";
import {
	faRightFromBracket,
	faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import Profile from "./Profile";
import Logout from "./Logout";
const ProfileOption = () => {
	const options = [
		// {
		// 	id: "profile",
		// 	title: "",
		// 	icon: faUserCircle,
		// 	component: <Profile />,
		// },
		{
			id: "logout",
			title: "",
			icon: faRightFromBracket,
			component: <Logout />,
		},
	];
	return (
		<ProfileOptionStyle>
			<OptionList options={options}></OptionList>
		</ProfileOptionStyle>
	);
};

const ProfileOptionStyle = styled.div`
	position: absolute;
	border-radius: 15px 0px 15px 15px;
	top: 51px;
	background: rgb(255, 255, 255);
	width: 220px;
	box-sizing: border-box;
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	flex-direction: column;
	max-height: 873px;
	min-width: 220px;
	right: 0px;
`;

export default ProfileOption;
