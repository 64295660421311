import React from "react";
import styled from "styled-components";
import { P4 } from "../../common/Text";
import { ConfirmationPopUpData } from "../../../assets/data/demoApp";
const DemoConfirm = ({ brandName }) => {
	const subHeading = ConfirmationPopUpData[brandName];
	return (
		<DemoConfirmStyled className="container">
			<div className="inner-Container">
				<img
					src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/9fced129522f128b2445a41fb0b6ef9f.svg"
					alt="confirm svg"
				/>
				<P4 weight="600" lineHeight="122.19%" opacity={"0.75"} dangerouslySetInnerHTML={{ __html: subHeading }}>
					{/* {subHeading} */}
				</P4>
				{/* <div dangerouslySetInnerHTML={{ __html: subHeading }}></div> */}
			</div>
		</DemoConfirmStyled>
	);
};
const DemoConfirmStyled = styled.div`
	margin-top: 1rem !important;
	width: 100%;
	margin: 2% auto;
	.inner-Container {
		display: flex;
		gap: 0.7rem;
		padding: 1rem;
		background: var(--white-color);
		border: 1px solid #c4c4c4;
		border-radius: 15px;
	}
`;
export default DemoConfirm;
