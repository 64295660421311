import React, { useEffect } from "react";
import { useWindowDimensions } from "../utils";
import BookDemoDesktop from "../components/layouts/BookDemoDesktop";
import BookDemoMobile from "../components/layouts/BookDemoMobile";
import { connect } from "react-redux";
import NoMatch from "./NoMatch";
import { useParams } from "react-router-dom";
import { getBookedDemoDetails, getBookMyDemo } from "../actions/slotBooking";
import LoadingSpinner from "../components/common/LoadingSpinner";
import NewBookDemoDesktop from "../components/layouts/NewBookDemoDesktop";

const BookDemo = ({
	getBookedDemoDetails,
	brandDetails,
	bookedDemoDetails,
	getBookMyDemo,
}) => {
	const { id } = useParams();
	useEffect(() => {
		localStorage.removeItem("brand_details");
		localStorage.removeItem("slot_time_detail");
		getBookMyDemo(id);
	}, [getBookMyDemo, id]);

	let brandDetail = brandDetails;
	let brandName = brandDetail?.pre_demo_brand_name || "IELTS NINJA";
	let hex_lead_id = brandDetail?.hex_lead_id || "";
	let leadID = brandDetails?.id ? brandDetails?.id : "";
	let demoId = brandDetails?.existing_demos
		? brandDetails?.existing_demos[0].id
		: "";


	useEffect(() => {
		demoId && getBookedDemoDetails(leadID, demoId);
	}, [getBookedDemoDetails, leadID, demoId]);

	const { width } = useWindowDimensions();
	if (leadID === "" || hex_lead_id === "") {
		return <NoMatch />;
	}

	if (!demoId) {
		return bookedDemoDetails === undefined ? (
			<LoadingSpinner />
		) : (
			<h2>No active Demos present.</h2>
		);
	}
	if (bookedDemoDetails === undefined) {
		return <LoadingSpinner />;
	}

	if (brandName === "THE FLUENTLIFE") {
		return (
			<NewBookDemoDesktop
				bookedDemoDetails={bookedDemoDetails}
				brandName={brandName}
			/>
		);
	}

	return bookedDemoDetails === undefined ? (
		<LoadingSpinner />
	) : width > 950 ? (
		<NewBookDemoDesktop
			bookedDemoDetails={bookedDemoDetails}
			brandName={brandName}
		/>
	) : (
		<NewBookDemoDesktop
			bookedDemoDetails={bookedDemoDetails}
			brandName={brandName}
		/>
	);
};

const mapStateToProps = (state) => ({
	brandDetails: state.slotBooking?.brand_detail,
	bookedDemoDetails: state.slotBooking?.booked_demo_details,
});

export default connect(mapStateToProps, {
	getBookedDemoDetails,
	getBookMyDemo,
})(BookDemo);
