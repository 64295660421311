import React from "react";
import { useState } from "react";
import styles from "../../styles/css/otpPopUp.module.css";
import { faEdit, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  gettingStartedLogin,
  gettingStartedVerifyOtp,
  validateClassLink,
  guestRegister,
  verifyOtpGuestLogin,
  demoGuestLogin,
} from "../../actions/auth";
import { useEffect } from "react";
import SignUp from "./SignUp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { demoLoginBrandDetails } from "../../utils/brandUtilDetails";

const OtpPopUp = ({
  show,
  close,
  userDetails,
  gettingStartedLogin,
  gettingStartedVerifyOtp,
  auth: { isAuthenticated },
  otpDetails,
  verifyOtpGuestLogin,
  guestOtpDetails,
  classType,
  brandDetails,
  demoGuestLogin
}) => {
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [otpVerification, setOtpVerification] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  const {brandColor,brandLogo } = demoLoginBrandDetails.getDemoLoginBrandDetails(brandDetails)


  const access_type = "WEB";
  const brand = "THE REAL SCHOOL";
  let phone = localStorage.getItem("phone");
  let email = localStorage.getItem("email");

  const resendOtp = (e) => {
    e.preventDefault();
    setMinutes(1);
    setSeconds(30);
    if (classType === "Demo") {
      guestRegister(phone);
    } else {
      gettingStartedLogin(access_type, brand, phone, email);
    }
  };

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([
      ...otp.map((otpArrayElement, idx) =>
        idx === index ? element.value : otpArrayElement
      ),
    ]);

    // Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  useEffect(() => {
    let interval;
    if (show) {
      interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [seconds, show]);

  const navigate = useNavigate();

  const onOtpVerify = (e) => {
    e.preventDefault();
    if (classType==="Demo") {
      verifyOtpGuestLogin(phone, otp.join(""));

    } else {
      gettingStartedVerifyOtp(
        userDetails.session_id,
        userDetails.message,
        otp.join("")
      );
    }
    setOtpVerification(!otpVerification);
  };

  useEffect(() => {
    if (classType === "Demo") {
      
      if (guestOtpDetails?.status === "SUCCESS") {
   
        demoGuestLogin("username", phone, "DEMO")
  
      }
    }
    else {
      if (otpDetails?.status === true) {
        navigate("/regular-and-batch-dashboard");
      }
    }
  
},[otpDetails?.status,guestOtpDetails?.status])

  useEffect(()=>{
    if(isAuthenticated && classType=== "Demo"){
      navigate("/demo-dashboard")
    }
  },[isAuthenticated])
  return (
    <>
      {show ? (
        <div className={`${styles.otpPopUpWrapper}`}>
          <div className={`${styles.prShow}`}>
            <div className={`${styles.prInner}`}>
              <div className={`${styles.otpPopUpHeader}`}>
                <div className={`${styles.closeButtonWrapper}`} onClick={close}>
                  <img
                    src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/45709f040f7ab4e8100070bde31734f5.1673598678610130"
                    alt="Arrow-Image"
                  />
                </div>
                <div className={`${styles.otpPopUpBrandLogo}`}>
                  <img
                    src={brandLogo}
                    alt="Brand-Logo"
                  />
                </div>
              </div>
              <div className={`${styles.otpBoxDetails}`}>
                <h1>OTP</h1>
                <div className={`${styles.otpBoxDetailsEdit}`}>
                  <p>
                    {localStorage.getItem("phone") != null
                      ? localStorage.getItem("phone")
                      : localStorage.getItem("email")}
                  </p>
                  <FontAwesomeIcon
                    className={`${styles.faEditIcon}`}
                    icon={faEdit}
                    onClick={close}
                    color={brandColor}
                  />
                </div>
              </div>
              <div className={`${styles.otpInputWrapper}`}>
                {" "}
                {otp.map((data, index) => {
                  return (
                    <input
                      className={`${styles.otpField}`}
                      type="text"
                      name="otp"
                      maxLength="1"
                      key={index}
                      value={data}
                      autoFocus={index === 0}
                      onChange={(e) => handleOtpChange(e.target, index)}
                      onFocus={(e) => e.target.select()}
                      style={{borderBottomColor:`${brandColor}`}}
                    />
                  );
                })}
              </div>
              <div className={`${styles.resendOtpWrapper}`}>
                {classType === "Demo" ? (
                  guestOtpDetails?.status === "ERROR" ? (
                    <p id={`${styles.error_msg}`}>Incorrect OTP</p>
                  ) : null
                ) : otpDetails?.status === false ? (
                  <p id={`${styles.error_msg}`}>Incorrect OTP</p>
                ) : null}
                {seconds > 0 || minutes > 0 ? (
                  <p id={`${styles.timerWrapper}`}>
                    Resent OTP in : {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <button
                    id={`${styles.resendOtpButton}`}
                    onClick={(e) => resendOtp(e)}
                  >
                    Resend OTP
                  </button>
                )}
              </div>

              <div className={`${styles.otpButtonWrapper}`}>
                <button
                  className={`${styles.otpButtonWrapperVerify}`}
                  onClick={(e) => onOtpVerify(e)}
                  style={{background:`${brandColor}`}}
                >
                  Verify OTP
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  guestDetails: state.auth?.guest_regester,
  userDetails: state.auth.user_login_details,
  auth: state.auth,
  error: state.auth.error,
  otpDetails: state.auth.user_login_getting_started_otp,
  guestOtpDetails:state.auth?.guest_login_otp,
});

export default connect(mapStateToProps, {
  gettingStartedLogin,
  gettingStartedVerifyOtp,
  guestRegister,
  verifyOtpGuestLogin,
  demoGuestLogin,
})(OtpPopUp);
