import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserFriends,
	faClock,
  faRightFromBracket,
  faCaretDown,
	faBars,
  faXmark,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { HeaderItem } from "../common/HeaderItem";
import styled from "styled-components";
import { useState } from "react";
import ProfileOption from "./ProfileOption";
import styles from "../../styles/css/layouts/dashboardHeader.module.css";
import { useEffect } from "react";
import { connect } from "react-redux";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../actions/auth";

const DashBoardHeader = ({ logOut }) => {

  const [showProfile, setShowProfile] = useState(false);
  const [activeSidebar, setActiveSidebar] = useState(false);
  const handleSidebarClick = () => {
    setActiveSidebar(!activeSidebar);
  };

  let navigate = useNavigate()
  const onClickLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("phone");
    localStorage.clear();
    sessionStorage.clear();
    if (window.location.href.split("/")[3] === "demo-dashboard") {
      // window.location.href = "/demo";
      logOut()
      // navigate("/demo")
    } else {
      // window.location.href = "/classroom";
      logOut()

      // navigate("/myclass")
    }
  };
	

  return (
    <div>
      <div
        onClick={handleSidebarClick}
        className={
          activeSidebar ? `${styles.activebackdrop}` : `${styles.backdrop}`
        }
      ></div>
      <div className={styles.dashboardHeader}>
        <div
          id={styles.side}
          className={activeSidebar ? `${styles.active}` : `${styles.sidebar}`}
        >
          <div className={styles.sidebarContent}>
            <div className={styles.sidebarContentMain}>
              <div className={styles.dashboardLogo}>
                <img
                  src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/98998c630e221e3ffef5b3de4a60eb27.16722253852112811"
                  alt="Classroom Logo"
                />
              </div>
              <FontAwesomeIcon
                onClick={handleSidebarClick}
                className={styles.sideBarCloseBtn}
                icon={faXmark}
              />
            </div>
            <div className={styles.sidebarContentMainTwo}>
              <FontAwesomeIcon
                className={styles.sidebarProfileIcon}
                icon={faUser}
              />
              <p>{localStorage.getItem("name")}</p>
            </div>
            <div className={styles.sidebarContentSub}>
              <button onClick={onClickLogout} className={styles.logoutBtn}>
                <FontAwesomeIcon
                  className={styles.dashboardHamburger}
                  icon={faRightFromBracket}
                />
                <div>Log out</div>
              </button>
            </div>
          </div>
        </div>
        <div className={styles.dashboardContentLeft}>
          <FontAwesomeIcon
            onClick={handleSidebarClick}
            className={styles.dashboardHamburger}
            icon={faBars}
          />
          <div className={styles.dashboardLogo}>
            <img
              src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/98998c630e221e3ffef5b3de4a60eb27.16722253852112811"
              alt="Classroom Logo"
            />
          </div>
        </div>
        <div className={styles.dashboardContent}>
          <div className={styles.headerItems}>
            <FontAwesomeIcon
              className={styles.dashboardHamburger}
              icon={faUser}
            />
            <p>{localStorage.getItem("name")}</p>
          </div>
          <div className={styles.headerItems}>
            <button onClick={onClickLogout} className={styles.logoutBtn}>
              <FontAwesomeIcon
                className={styles.dashboardHamburger}
                icon={faRightFromBracket}
              />
              <div>Log out</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = (state) => ({
  userDetails: state.auth.user_login_details,
  auth: state.auth,
  classDetails: state.auth.class_details,
  error: state.auth.error,
});

export default connect(mapStateToProps, {
  logOut
})(DashBoardHeader);
