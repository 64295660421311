import React from "react";
import { useState } from "react";
import OtpPopUp from "../components/layouts/OtpPopUp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import {
  gettingStartedLogin,
  gettingStartedVerifyOtp,
  validateClassLink,
} from "../actions/auth";
import { validate } from "../utils/validation";
import GoogleLoginAuthHooks from "../components/layouts/GoogleLoginAuthHooks";
import styles from "../styles/css/layouts/loginPage.module.css";
import { useEffect } from "react";

const RegularBatchClassLogin = ({ gettingStartedLogin }) => {
      const [usePhoneEmail, setUsePhoneEmail] = useState(true);
      const [otpPopUp, setOtpPopUp] = useState(false);

      const otpPopUpToggle = () => setOtpPopUp(!otpPopUp);

      const domainName = window.location.hostname.split(".")[1];

      const [loginFormData, setLoginFormData] = useState({
        access_type: "WEB",
        brand: "THE REAL SCHOOL",
        phone: "",
        email: "",
      });

      const buttonSelectionToggle = (e) => {
        e.preventDefault();
        setUsePhoneEmail(!usePhoneEmail);
      };

      const { access_type, brand, phone, email } = loginFormData;

      const onLoginChange = (e) => {
        setLoginFormData({ ...loginFormData, [e.target.name]: e.target.value });
      };

      const validateEmail = validate(loginFormData, "gettingStartedLoginEmail");
      const onLoginSubmitEmail = (e) => {
        e.preventDefault();
        if (Object.keys(validateEmail).length === 0) {
          gettingStartedLogin(access_type, brand, phone, email);
          localStorage.clear();
          localStorage.setItem("email", email);
          otpPopUpToggle();
        } else {
          toast.error(validateEmail.email, {
            position: toast.POSITION.TOP_LEFT,
          });
        }
      };

      const validatePhone = validate(loginFormData, "gettingStartedLoginPhone");
      const onLoginSubmitPhone = (e) => {
        e.preventDefault();
        if (Object.keys(validatePhone).length === 0) {
          gettingStartedLogin(access_type, brand, phone, email);
          localStorage.clear();
          localStorage.setItem("phone", phone);
          otpPopUpToggle();
        } else {
          toast.error(validatePhone.phone, {
            position: toast.POSITION.TOP_LEFT,
          });
        }
      };

  return (
    <>
      <div className={`${styles.loginMain}`}>
        <ToastContainer />
        <div className={`${styles.loginMainOne}`}>
          <div className={`${styles.loginMainWrapper}`}>
            <div className={`${styles.loginLeftWrapper}`}>
              <h1>Experience</h1>
              <div className={`${styles.loginBrandLogo}`}>
                <img
                  src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/60b7df8bd06d3565248899365762e4e5.1673848839846160"
                  alt="Brand-Logo"
                />
              </div>
              <h1>Exclusive Live Session!</h1>
              <div className={`${styles.loginBrandImage}`}>
                <img
                  src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/95409bf0d78a7828b10b1e8da188155c.svg"
                  alt="Brand-Image"
                />
              </div>
            </div>
            <div className={`${styles.loginRightWrapper}`}>
              {usePhoneEmail ? (
                <>
                  <form
                    className={`${styles.loginForm}`}
                    onSubmit={onLoginSubmitPhone}
                  >
                    <div className={`${styles.loginFormBrandLogo}`}>
                      <img
                        src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/60b7df8bd06d3565248899365762e4e5.1673848839846160"
                        alt="Brand-Logo"
                      />
                    </div>
                    <h2>Login</h2>
                    <p>to see class details </p>
                    <GoogleLoginAuthHooks />
                    <p id={`${styles.sectionSeparated}`}>or</p>
                    <input
                      className={`${styles.formInputs}`}
                      type="phone"
                      name="phone"
                      placeholder="Phone Number"
                      onChange={onLoginChange}
                      value={loginFormData.phone}
                    />
                    <button
                      className={`${styles.formButtons}`}
                      id={`${styles.toggleEmailButton}`}
                      onClick={(e) => buttonSelectionToggle(e)}
                    >
                      Use Registered Email instead?
                    </button>
                    <button
                      className={`${styles.formButtons}`}
                      id={`${styles.joinClassroomButton}`}
                      type="submit"
                    >
                      <span className={`${styles.joinClassroomIcon}`}>
                        <img
                          src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/5c279f51a264fdade78fe622ea8ade78.1673589918561130"
                          alt="Join-Classroom-Icon"
                        />
                      </span>{" "}
                      Join Classroom
                    </button>
                  </form>
                </>
              ) : (
                <>
                  <form
                    className={`${styles.loginForm}`}
                    onSubmit={onLoginSubmitEmail}
                  >
                    <div className={`${styles.loginFormBrandLogo}`}>
                      <img
                        src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/60b7df8bd06d3565248899365762e4e5.1673848839846160"
                        alt="Brand-Logo"
                      />
                    </div>
                    <h2>Login</h2>
                    <p>to see class details </p>
                    <GoogleLoginAuthHooks />
                    <p id={`${styles.sectionSeparated}`}>or</p>
                    <input
                      className={`${styles.formInputs}`}
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      onChange={onLoginChange}
                      value={loginFormData.email}
                    />
                    <button
                      className={`${styles.formButtons}`}
                      id={`${styles.toggleEmailButton}`}
                      onClick={(e) => buttonSelectionToggle(e)}
                    >
                      Use Registered Phone Number instead?
                    </button>
                    <button
                      className={`${styles.formButtons}`}
                      id={`${styles.joinClassroomButton}`}
                      type="submit"
                    >
                      <span className={`${styles.joinClassroomIcon}`}>
                        <img
                          src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/5c279f51a264fdade78fe622ea8ade78.1673589918561130"
                          alt="Join-Classroom-Icon"
                        />
                      </span>{" "}
                      Join Classroom
                    </button>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <OtpPopUp show={otpPopUp} close={otpPopUpToggle} />
    </>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.auth.user_login_details,
  auth: state.auth,
  class_link_details: state.auth.link_details,
  error: state.auth.error,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {
  gettingStartedLogin,
  gettingStartedVerifyOtp,
  validateClassLink,
})(RegularBatchClassLogin);
