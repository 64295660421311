import React, { useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCommentAlt,
	faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import MoreOption from "./MoreOption";

const DashBoardFooter = ({ setIsMessenger, isMessenger , setIsActiveUser}) => {

	const [isMoreOptionVisible, setIsMoreOptionVisible] = useState(false);
	return (
		<DashBoardFooterStyle>
			<div className="right-item">
				<div className="icon-block" onClick={() => {
						setIsMoreOptionVisible(!isMoreOptionVisible);
					}}>
					{ isMoreOptionVisible && <MoreOption />}
					<FontAwesomeIcon
						className="icon"
						icon={faEllipsisVertical}
					/>
					<p className="title">More</p>
				</div>
				<div
					className={`icon-block ${isMessenger ? "active" : ""}`}
					onClick={() => {
						setIsMessenger(!isMessenger);
						setIsActiveUser(false);
					}}
				>
					<FontAwesomeIcon className="icon" icon={faCommentAlt} />
					<p className="title">Chat</p>
				</div>
			</div>
		</DashBoardFooterStyle>
	);
};

const DashBoardFooterStyle = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 65px;
	background: #ffffff;
	z-index: 1;
	.right-item {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 100%;
		width: 350px;
		position: absolute;
		right: 0;
		.icon-block {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			height: 100%;
			width: 146px;
			color: #555;
			.icon {
				font-size: 23px;
				margin-bottom: 10px;
			}
			.title {
				margin: 0;
				font-size: 14px;
				font-weight: 500;
			}
			&:hover {
				cursor: pointer;
				background: #eee;
			}
		}
		.active {
			background: #eee;
			.icon {
				font-size: 28px;
				color: #8ab4f8;
			}
		}
	}
`;
export default DashBoardFooter;
