import styled from "styled-components";

export const SelectItem = styled.select`
	width: 100%;
	height: 45px;

	color: gray;
	padding-left: 5px;
	font-size: 14px;
	border: none;

	font-weight: ${(props) => props.fontWeight || "normal"};
	background-color: ${(props) => props.backgroundColor || "#ffffff"};
	color: ${(props) => props.color || "#000000"};
	border-radius: 5px;
	border: 1px solid #d2d8d8;

	option {
		color: black;
		background: white;
		display: flex;
		white-space: pre;
		min-height: 20px;
		padding: 0px 2px 1px;
	}
`;
