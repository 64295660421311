export const DemoAppFluentDetails = {
	id: "1",
	img: "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/f0ea33311fff4b09b276904cf5de9ce0.1658769937928256",
	header: "Fluent Life app",
	detail: "Download and improve your communication skills with the <strong>fluent life</strong> app",
	playStoreLink: "https://fluentlife.page.link/S4i7",
	appStoreLink: "https://fluentlife.page.link/S4i7",
};

export const DemoAppIeltsDetails = {
	id: "1",
	img: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/0afc1ba0ee663348e10c2b879137371e.svg",
	header: "IELTS Ninja app",
	detail: "Download and get your desired band with the IELLTS Ninja App",
	playStoreLink: "https://ieltsninjaapp.page.link/JoinIELTSNinja",
	appStoreLink: "https://ieltsninjaapp.page.link/JoinIELTSNinja",
};

export const DemoAppUpscDetails = {
	id: "1",
	img: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/7819b33912b8dcbf8d94976d9a0d1071.svg",
	header: "UPSC Pathshala",
	detail: "Download and get your desired band with the UPSC Pathshala App",
	playStoreLink: "https://upscpathshala.page.link/JoinUPSPathshala",
	appStoreLink: "https://upscpathshala.page.link/JoinUPSPathshala",
};

export const DemoAppEmaesterDetails = {
  id: "1",
  img: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/7819b33912b8dcbf8d94976d9a0d1071.svg",
  header: "UPSC Pathshala",
  detail: "Download and get your desired band with the UPSC Pathshala App",
  playStoreLink: "https://upscpathshala.page.link/JoinUPSPathshala",
  appStoreLink: "https://upscpathshala.page.link/JoinUPSPathshala",
};

export const BookMyDemoAppDetails = {
	"IELTS NINJA": DemoAppIeltsDetails,
	"THE FLUENTLIFE": DemoAppFluentDetails,
	"UPSC PATHSHALA": DemoAppUpscDetails,
	"EMAESTER" : DemoAppEmaesterDetails,
};
// https://ieltsninjaapp.page.link/rScv
export const ConfirmationPopUpData = {
	"THE FLUENTLIFE":
		"Your demo is confirmed. Meanwhile, click <a href='https://fluentlife.page.link/G663'> here </a>to download the app and practice your conversation skills with Speaking Partner for Free.",
	"IELTS NINJA":
		"Your demo is confirmed. Meanwhile, click <a href='https://ieltsninjaapp.page.link/rScv'>here </a>to download the app and practice your conversation skills with Speaking Partner for Free.",
	"UPSC PATHSHALA":
		"Your demo is confirmed. Meanwhile, click <a href='https://upscpathshala.page.link/sKW9'>here </a>to download the app and practice current affairs for free",
	"EMAESTER":
		"Your demo is confirmed.",
	"THE REAL SCHOOL":
		"Your demo is confirmed.",
	"Abroad Ninja":
		"Your demo is confirmed."
};

export const JoinSectionData = {
  "THE FLUENTLIFE": {
    heading: "Speaking Partner",
    subHeading:
      "Speak with your peers on topics and practice your conversational english",
    buttonName: "Join Now",
    buttonColor: "#FFC727",
    buttonTextColor: "#263238",
    redirectLink: "https://fluentlife.page.link/5btp",
  },
  "IELTS NINJA": {
    heading: "Band Predictor Test",
    subHeading:
      "Put yourself to the test and find out how much you would score in the IELTS!",
    buttonName: "Attempt Now",
    buttonColor: "#D23939",
    buttonTextColor: "#FFFFFF",
    redirectLink: "https://ieltsninjaapp.page.link/BandPredictorTest",
  },

  "UPSC PATHSHALA": {
    heading: "Current Affairs",
    subHeading: "Daily magazine, Quiz, video analysis and more…",
    buttonName: "View Now",
    buttonColor: "#8B16FE",
    buttonTextColor: "#FFFFFF",
    redirectLink: "https://upscpathshala.page.link/CurrentAffairs",
  },

  "EMAESTER": {
    heading: "Current Affairs",
    subHeading: "Daily magazine, Quiz, video analysis and more…",
    buttonName: "View Now",
    buttonColor: "#8B16FE",
    buttonTextColor: "#FFFFFF",
    redirectLink: "https://upscpathshala.page.link/CurrentAffairs",
  },
};
