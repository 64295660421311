import api from "../utils/api";
import gettingStartedApi from "../utils/gettingStartedApi";
import testapi from "../utils/testapi";

import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  GUEST_REGISTER_SUCCESS,
  GUEST_REGISTER_FAIL,
  VALIDATE_CLASS_LINK_SUCCESS,
  VALIDATE_CLASS_LINK_FAIL,
  MEETING_DETAILS_SUCCESS,
  MEETING_DETAILS_FAIL,
  VERIFY_OTP_GUEST_LOGIN_SUCCESS,
  VERIFY_OTP_GUEST_LOGIN_FAIL,
  GETTING_STARTED_LOGIN_SUCCESS,
  GETTING_STARTED_LOGIN_FAIL,
  GETTING_STARTED_VERIFY_OTP_SUCCESS,
  GETTING_STARTED_VERIFY_OTP_FAIL,
  GETTING_STARTED_SIGNUP_SUCCESS,
  GETTING_STARTED_SIGNUP_FAIL,
  GETTING_REGULAR_AND_BATCH_CLASS_DETAILS_SUCCESS,
  GETTING_REGULAR_AND_BATCH_CLASS_DETAILS_FAIL,
  GETTING_DEMO_CLASS_DETAILS_SUCCESS,
  GETTING_DEMO_CLASS_DETAILS_FAIL,
} from "./types";

export const loadUser = (userName) => async (dispatch) => {
  const body = { token: localStorage.token };
  try {
    const res = await testapi.post("/user/details/", body);
    const data = { ...res.data };
    if (res.data.status === "SUCCESS") {
      const data =
        res.data.username === undefined
          ? { ...res.data, username: userName }
          : res.data;
      dispatch({
        type: USER_LOADED,
        payload: data,
      });
    } else {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Login User
export const login = (email, password) => async (dispatch) => {
  const body = { email, password };

  try {
    const res = await testapi.post("/authenticate/auth/", body);
    if (res.data.status === "SUCCESS") {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(loadUser());
    } else {
      dispatch({
        type: LOGIN_FAIL,
      });
    }
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      console.log(errors);
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const guestRegister = (phone) => async (dispatch) => {
    const body = {
      user_phone: phone,
    };

    try {
      const res = await testapi.post("/auth/guests/", body);

      if (res.data.status === "SUCCESS") {
        localStorage.setItem("name",res.data.data.name)
        dispatch({
          type: GUEST_REGISTER_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GUEST_REGISTER_FAIL,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: GUEST_REGISTER_FAIL,
      });
    }
  };
export const verifyOtpGuestLogin = (phone, otp) => async (dispatch) => {
  try {
    const res = await testapi.get(
      "auth/guests/?user_phone=" + phone + "&otp=" + otp
    );

    if (res.data.status === "SUCCESS") {
      dispatch({
        type: VERIFY_OTP_GUEST_LOGIN_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: VERIFY_OTP_GUEST_LOGIN_FAIL,
        payload:res.data,
      });
    }
  } catch (err) {
    const errors = err.response.data;
    if (errors) {
      dispatch({
        type: VERIFY_OTP_GUEST_LOGIN_FAIL,
        payload:errors,
      });
      console.log(errors);
    }

    dispatch({
      type: VERIFY_OTP_GUEST_LOGIN_FAIL,
      payload: errors,
    });
  }
};

export const guestLogin =
  (username, phone, class_id, class_type) => async (dispatch) => {
    const body = {
      user_phone: phone,
      class_type: class_type,
      class_id: class_id,
    };
    try {
      const res = await testapi.post("/class/oauth/guests/", body);

      if (res.data.status === "SUCCESS") {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
        dispatch(loadUser(username));
      } else {
        dispatch({
          type: LOGIN_FAIL,
          payload: res.data,
        });
      }
    } catch (err) {
      const errors = err.response.data;

      dispatch({
        type: LOGIN_FAIL,
        payload: errors,
      });
    }
  };

export const validateClassLink = (class_link) => async (dispatch) => {
  const body = { class_link };
  try {
    const res = await testapi.post("/class/type/", body);
    if (res.data.status === "SUCCESS" && body.class_link !== undefined) {
      const data = { ...res.data, class_link };
      dispatch({
        type: VALIDATE_CLASS_LINK_SUCCESS,
        payload: data,
      });

      dispatch(getMeetingDetals(data.classroom_id, data.class_type));
    } else {
      dispatch({
        type: VALIDATE_CLASS_LINK_FAIL,
      });
    }
  } catch (err) {
    const errors = err.response.data;

    if (errors) {
      console.log(errors);
    }

    dispatch({
      type: VALIDATE_CLASS_LINK_FAIL,
      payload: errors,
    });
  }
};

export const getMeetingDetals =
  (classRoomID, classType) => async (dispatch) => {
    const body = { classroom_id: classRoomID, class_type: classType };
    // const body= {
    // 	classroom_id: 39473,
    // 	class_type: "BATCH CLASS"
    // }
    try {
      const res = await testapi.post("/meeting/details/", body);

      if (res.data.status === "SUCCESS") {
        dispatch({
          type: MEETING_DETAILS_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: MEETING_DETAILS_FAIL,
          payload: res.data,
        });
      }
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        console.log(errors);
      }

      dispatch({
        type: VALIDATE_CLASS_LINK_FAIL,
      });
    }
  };

// Logout
export const logOut = () => async(dispatch)=> (dispatch({ type: LOGOUT }));

export const demoGuestLogin = (username, phone, class_type) => async (dispatch) => {
    const body = {
      user_phone: phone,
      class_type: class_type,
    };
    try {
      const res = await testapi.post("/demo-class/oauth/guests/", body);
      if (res.data.status === "SUCCESS") {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
        localStorage.setItem("token",res.data.token)
        // localStorage.setItem("name",res.data.data.name)
        dispatch(loadUser(username));
      } else {
        dispatch({
          type: LOGIN_FAIL,
          payload: res.data,
        });
      }
    } catch (err) {
      const errors = err.response.data;

      dispatch({
        type: LOGIN_FAIL,
        payload: errors,
      });
    }
  };

// Getting Started Login
export const gettingStartedLogin =(access_type, brand, phone, email, gauth_token) => async (dispatch) => {
    const body = new FormData();
  let isGoogleLogin = false;
    body.append("access_type", access_type);
	  body.append("brand", brand);
	  
    if (phone === null && email === null) {
      body.append("gauth-token", gauth_token)
      isGoogleLogin = true
    }
    else {
      	  if (phone === "" || phone === null) {
            body.append("email", email);
          } else {
            body.append("phone", phone);
          }
   }
    try {
      const res = await gettingStartedApi.post("/entry/", body);
      if (res.data.status) {
        localStorage.setItem("name", res.data.name)
        if (isGoogleLogin) {
                  dispatch({
                    type: GETTING_STARTED_LOGIN_SUCCESS,
                    payload: {...res.data, "isGoogleLogin": isGoogleLogin },
                  });
        }
        else {
                  dispatch({
                    type: GETTING_STARTED_LOGIN_SUCCESS,
                    payload: res.data,
                  });
        }

      } else {
        dispatch({
          type: GETTING_STARTED_LOGIN_FAIL,
          payload: res.data,
        });
      }
    } catch (err) {
      const errors = err.response.data;

      if (errors) {
        console.log(errors);
      }

      dispatch({
        type: GETTING_STARTED_LOGIN_FAIL,
        payload: errors,
      });
    }
  };

// Getting Started Otp Verification
export const gettingStartedVerifyOtp =(session,message,otp) => async (dispatch) => {
    const body = new FormData();

    body.append("session", session);
    if (message === "Email OTP sent successfully") {
      body.append("email_otp", otp);
    }
    else {
      body.append("phone_otp", otp);
    }

    try {
      const res = await gettingStartedApi.post("/verify/", body);
      if (res.data.status) {
        dispatch({
          type: GETTING_STARTED_VERIFY_OTP_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GETTING_STARTED_VERIFY_OTP_FAIL,
          payload: res.data,
        });
      }
    } catch (err) {
      const errors = err.response.data;

      if (errors) {
        console.log(errors);
      }

      dispatch({
        type: GETTING_STARTED_VERIFY_OTP_FAIL,
        payload: errors
      });
    }
  };

// Getting Started Sign Up After OTP Verification
export const gettingStartedSignUp =(session, username, brand, phone, email,target) => async (dispatch) => {
    const body = new FormData();
    body.append("session", session);
    body.append("username", username);
	  body.append("brand", brand);
	  body.append("phone", phone);
		body.append("email", email);
		body.append("target", target);

    try {
      const res = await gettingStartedApi.post("/final/", body);

      if (res.data.status) {
        dispatch({
          type: GETTING_STARTED_SIGNUP_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GETTING_STARTED_SIGNUP_FAIL,
          payload: res.data,
        });
      }
    } catch (err) {
      const errors = err.response.data.message;

      if (errors) {
        console.log(errors);
      }

      dispatch({
        type: GETTING_STARTED_SIGNUP_FAIL,
        payload:errors,
      });
    }
  };

// Getting Regular and Batch Class Details
export const gettingRegularAndBatchClassDetails = (user_phone,user_email) => async (dispatch) => {
  let user_query;
  let user_query_value;
  if (user_email === null) {
    user_query = "user_phone";
    user_query_value = user_phone
    localStorage.removeItem("email")
    } else {
    user_query = "user_email";
    user_query_value = user_email
    localStorage.removeItem("phone");
  }
  try {
     const res = await testapi.get(
       `/get-class-details/?${user_query}=${user_query_value}`,
       {}
     );
    if (res.data.status === "SUCCESS") {
      localStorage.setItem("token",res.data.token)
      dispatch({
        type: GETTING_REGULAR_AND_BATCH_CLASS_DETAILS_SUCCESS,
        payload: res.data
      });
    }
    else {
      dispatch({
        type: GETTING_REGULAR_AND_BATCH_CLASS_DETAILS_FAIL,
        payload: res.data,
      });
    }
  }
  catch(err) {
          const errors = err.response.data.errors;

          if (errors) {
            console.log(errors);
          }

          dispatch({
            type: GETTING_REGULAR_AND_BATCH_CLASS_DETAILS_FAIL,
          });
  }
}
  
// Getting Demo Class Details
export const gettingDemoClassDetails = (tokenValue) => async (dispatch) => {
  const body = {token: tokenValue}
  try {
    const res = await testapi.post("/demo-meeting/details/", body)
    if (res.data.status === "SUCCESS") {
      dispatch({
        type: GETTING_DEMO_CLASS_DETAILS_SUCCESS,
        payload: res.data,
      });
    }
    else {
      dispatch({
        type: GETTING_DEMO_CLASS_DETAILS_FAIL,
        payload: res.data,
      });
    }
  }
  catch(err) {
    const errors = err.response.data

    if (errors) {
      console.log(errors)
    }
    dispatch({
      type: GETTING_DEMO_CLASS_DETAILS_FAIL,
      payload: errors,
    });
  }
}
