import React, { useEffect } from "react";
import styled from "styled-components";
import afterProcess from "../../assets/images/after-proceed.png";
import { connect } from "react-redux";
import { formatDateToDisplay, convert24hourTo12HourFormat, validateDateTime } from "../../utils";
import { bookSlot, postSlotBookQuestion } from "../../actions/slotBooking";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BookDemoDesktop from "./BookDemoDesktop";
import { useNavigate } from "react-router-dom";
import { SlotBookingBrandColor } from "../../utils/slotBookingBrandUtilDetails";

const SlotBookingPopup = ({
	setShowPopup,
	finalQuestionInput,
	bookSlot,
	slot_booked,
	postSlotBookQuestion,
	slot_booked_question,
	brandName,
	hex_lead_id,
	state,
}) => {
	console.log(state,"state")
	let navigate = useNavigate();
	const [isConfirm, setIsConfirm] = React.useState(false);
	const closeSlotBookingPopup = () => {
		if(isConfirm){
			toast.error("Your slot is been process.", {
				position: toast.POSITION.TOP_LEFT,
			});
		}else{
			setShowPopup(false);
			setIsConfirm(false);
		}
	
	};

	//get slot_time_detail from local storage
	const slot_time_detail = JSON.parse(
		localStorage.getItem("slot_time_detail")
	);
	let date = slot_time_detail?.slot_booking_date ?? "";
	let time = slot_time_detail?.slot_booking_time ?? "";
	let id = slot_time_detail?.id ?? "";

	let format_date = formatDateToDisplay(date);
	let format_time = convert24hourTo12HourFormat(time);
	const confirmSlotBooking = () => {
		if(!isConfirm){
			console.log('click')
			if (!validateDateTime(date, time)) {
				toast.error("Please refresh the page and then submit the form.", {
					position: toast.POSITION.TOP_LEFT,
				});
				return
			}
			postSlotBookQuestion(finalQuestionInput, id);
			setIsConfirm(true);
			return;
		}
	};

	useEffect(() => {
		console.log(slot_booked_question,"SLOT BOOK QUESTION")
		if (slot_booked_question?.status === "success") {
			bookSlot(date, time, id);
		} else if (slot_booked_question?.status === "failure") {
			console.log(slot_booked_question?.message,"ERROR")
			toast.error(slot_booked_question?.message, {
				position: toast.POSITION.TOP_LEFT,
			});
		}
	}, [slot_booked_question, bookSlot]);

	useEffect(() => {
		if (slot_booked?.status === "success") {
			toast.success("Slot Booked Successfully", {
				position: toast.POSITION.TOP_LEFT,
			});
			setShowPopup(false);
			setIsConfirm(false);
			let path = `/${hex_lead_id}/book-demo`;
			navigate(path);
		} else if (slot_booked?.status === "failure") {
			toast.error(slot_booked?.message, {
				position: toast.POSITION.TOP_LEFT,
			});
		}
	}, [slot_booked, hex_lead_id, navigate, setShowPopup]);

	if (date === "" || time === "" || id === "") {
		toast.error("Please Select the Proper Slot", {
			position: toast.POSITION.TOP_LEFT,
		});
		navigate("/demo-slots");
		return false;
	}

	const { buttonColor } = SlotBookingBrandColor.getColor(brandName);

	return (
		<SlotBookingPopupStyled>
			<ToastContainer />
			<div className="pr-head pr-show">
				<div className="pr-inner">
					<img src={afterProcess} alt="" />
					<p className="p1">You have selected:</p>
					<p className="p2">
						{format_date} {format_time}
					</p>
					<p className="p3">Confirm Slot?</p>
					<div className="last-btn">
						<button
							className="bt1 confirm_demo"
							onClick={confirmSlotBooking}
							style={{ background: buttonColor }}
						>
							 {isConfirm ? "Confirming..." : "Confirm"}
						</button>
						<button
							className="bt2"
							onClick={closeSlotBookingPopup}
							style={{
								border: `1px solid  ${buttonColor}`,
								color: buttonColor,
							}}
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</SlotBookingPopupStyled>
	);
};
const SlotBookingPopupStyled = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0, 0, 0, 0.5);
	.pr-show {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.pr-inner {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		background-color: white;
		border-radius: 10px;
		width: 410px;
		height: 380px;
		margin: 100px 0;
	}
	.pr-head {
		img {
			width: 140px;
			height: 104px;
		}
		.p1 {
			font-size: 16px;
			font-family: "Montserrat";
			font-weight: 300;
		}
		.p2 {
			font-size: 18px;
			font-family: "Montserrat";
			font-weight: 600;
		}
		.p3 {
			font-size: 18px;
			font-family: "Montserrat";
			font-weight: 600;
		}
	}
	.last-btn {
		button {
			width: 160px;
			height: 45px;
			border-radius: 5px;
		}
		.bt1 {
			background-color: #ff1d1d;
			color: white;
			border: none;
		}
		.bt2 {
			border: 1px solid #ff1d1d;
			color: #ff1d1d;
			background-color: white;
			margin-left: 20px;
		}
	}
	@media screen and (max-width: 450px) {
		.pr-inner {
			width: 90%;
		}
		.last-btn {
			button {
				width: 110px;
			}
			.bt2 {
				margin-left: 10px;
			}
		}
	}
`;

const mapStateToProps = (state) => ({
	questions: state.slotBooking?.slot_booking_questions,
	brandDetails: state.slotBooking?.brand_detail,
	slot_booked: state.slotBooking?.slot_booked,
	slot_booked_question: state.slotBooking?.slot_booked_question,
});

export default connect(mapStateToProps, {
	bookSlot,
	postSlotBookQuestion,
})(SlotBookingPopup);
