import React from 'react'
import { useState } from 'react';
import { connect } from 'react-redux';
import styles from "../../styles/css/signUp.module.css";
import {
  gettingStartedLogin,
  gettingStartedSignUp,
  gettingStartedVerifyOtp,
  validateClassLink,
} from "../../actions/auth";
import { validate } from '../../utils/validation';

const SignUp = ({ show, sessionId }) => {

  const [loginFormData, setLoginFormData] = useState({
    session: sessionId,
    username: "",
    phone: "",
    email: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const { session, brand, username, phone, email,target } = loginFormData;
  const onSignUpChange = (e) => {
    setFormErrors({ ...formErrors, [e.target.name]: "" });
    setLoginFormData({ ...loginFormData, [e.target.name]: e.target.value });
  };

  const onSignUpSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(loginFormData, "gettingStartedLogin"));
    gettingStartedSignUp(session, username, brand, phone, email,target);
  };
  return (
    <>
      {show ? (
        <div className={`${styles.signUpWrapper}`}>
          <div className={`${styles.prShow}`}>
            {" "}
            <div className={`${styles.prInner}`}>
              <div className={`${styles.signUpWrapperOne}`}>
                <form
                  className={`${styles.formWrapper}`}
                  onSubmit={onSignUpSubmit}
                >
                  <h1>One Last Step</h1>
                  <p>
                    Enter some additional information so we can best cater to
                    your needs.
                  </p>
                  <input
                    type="username"
                    name="username"
                    placeholder="Enter Your Full Name"
                    onChange={onSignUpChange}
                  />
                  <input
                    type="phone"
                    name="phone"
                    placeholder="Enter Your Phone Number"
                    onChange={onSignUpChange}
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter Your Email ID"
                    onChange={onSignUpChange}
                  />
                  <input
                    id={`${styles.oneLastStepButton}`}
                    type="submit"
                    value="Start Learning"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.auth.user_login_details,
  auth: state.auth,
  class_link_details: state.auth.link_details,
  error: state.auth.error,
});

export default connect(mapStateToProps, {
  gettingStartedLogin,
  gettingStartedVerifyOtp,
  validateClassLink,
  gettingStartedSignUp,
})(SignUp);