import React from "react";
import styled from "styled-components";
import { P1, P4 } from "../../common/Text";
import { PrimaryButton } from "../../common/PrimaryButton";
import { JoinSectionData } from "../../../assets/data/demoApp";
const JoinSection = ({ brandName }) => {
	const { heading, subHeading, buttonName, buttonColor, buttonTextColor, redirectLink } = JoinSectionData[brandName];
	const onClickRedirect = () => {
		redirectLink && window.open(redirectLink, "_blank");
	};
	return (
		<JoinSectionStyled className="container">
			<div className="IneerContainer">
				<P1 weight="900" size="40px" lineHeight="36px">
					{heading}
				</P1>
				<P4 lineHeight="122.19%" opacity={"0.75"} margin="1.3rem 0 0  0">
					{subHeading}
				</P4>
				<PrimaryButton
					height={"50px"}
					size={"1.2rem"}
					padding={"20px 45px"}
					width="100%"
					margin="1.8rem 0 0 0 "
					color={buttonTextColor}
					background={buttonColor}
					onClick={onClickRedirect}
					// opacity={buttonOpacity}
				>
					{buttonName}
				</PrimaryButton>
			</div>
		</JoinSectionStyled>
	);
};

const JoinSectionStyled = styled.div``;

export default JoinSection;
