import React from "react";
import { connect } from "react-redux";
const Profile = ({ user_details: { username } }) => {
	return <>{username}</>;
};

const mapStateToProps = (state) => ({
	user_details: state.auth.user,
});
export default connect(mapStateToProps, {})(Profile);
