import {
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  GUEST_REGISTER_SUCCESS,
  VALIDATE_CLASS_LINK_SUCCESS,
  MEETING_DETAILS_SUCCESS,
  MEETING_DETAILS_FAIL,
  GUEST_REGISTER_FAIL,
  VERIFY_OTP_GUEST_LOGIN_SUCCESS,
  VERIFY_OTP_GUEST_LOGIN_FAIL,
  GETTING_STARTED_LOGIN_SUCCESS,
  GETTING_STARTED_LOGIN_FAIL,
  GETTING_STARTED_VERIFY_OTP_SUCCESS,
  GETTING_STARTED_VERIFY_OTP_FAIL,
  USER_LOGIN_DETAILS_LOADED,
  GETTING_STARTED_SIGNUP_SUCCESS,
  GETTING_STARTED_SIGNUP_FAIL,
  GETTING_REGULAR_AND_BATCH_CLASS_DETAILS_SUCCESS,
  GETTING_REGULAR_AND_BATCH_CLASS_DETAILS_FAIL,
  GETTING_DEMO_CLASS_DETAILS_SUCCESS,
  GETTING_DEMO_CLASS_DETAILS_FAIL,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  user: null,
  link_details: null,
  user_login_details: null,
  user_login_getting_started_otp: null,
  guest_login_otp: null,
};

function authReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        error: payload,
        isAuthenticated: false,
        loading: false,
      };
    case GUEST_REGISTER_SUCCESS:
      return {
        ...state,
        guest_regester: payload,
        loading: false,
      };
    case GUEST_REGISTER_FAIL:
      return {
        ...state,
        guest_regester: payload,
        loading: false,
      };
    case VALIDATE_CLASS_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        link_details: payload,
      };
    case MEETING_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        meeting_details: payload,
      };
    case MEETING_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        meeting_details: payload,
      };

    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        user_login_details: null,
        user_login_getting_started_otp: null,
        guest_login_otp: null,
        class_details: null,
      };
    case VERIFY_OTP_GUEST_LOGIN_SUCCESS:
      return {
        ...state,
        guest_login_otp: payload,
        loading: false,
      };
    case VERIFY_OTP_GUEST_LOGIN_FAIL:
      return {
        ...state,
        guest_login_otp: payload,
        loading: false,
      };
    case GETTING_STARTED_LOGIN_SUCCESS:
      if (payload.isGoogleLogin) {
        return {
          ...state,
          user_login_details: payload,
          isAuthenticated: true,
          loading: false,
        };
      } else {
        return {
          ...state,
          user_login_details: payload,
          isAuthenticated: false,
          loading: false,
        };
      }
    case GETTING_STARTED_LOGIN_FAIL:
      return {
        ...state,
        user_login_details: payload,
        isAuthenticated: false,
        loading: false,
      };
    case GETTING_STARTED_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        user_login_getting_started_otp: payload,
        isAuthenticated: true,
        loading: false,
      };
    case GETTING_STARTED_VERIFY_OTP_FAIL:
      return {
        ...state,
        user_login_getting_started_otp: payload,
        isAuthenticated: false,
        loading: false,
      };
    case GETTING_STARTED_SIGNUP_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user_login_details: payload,
      };
    }
    case GETTING_STARTED_SIGNUP_FAIL: {
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: payload,
      };
    }
    case GETTING_REGULAR_AND_BATCH_CLASS_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        class_details: payload,
      };
    }
    case GETTING_REGULAR_AND_BATCH_CLASS_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    }
    case GETTING_DEMO_CLASS_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        class_details: payload,
      };
    }
    case GETTING_DEMO_CLASS_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    }
    default:
      return state;
  }
}

export default authReducer;
