import axios from "axios";
// Create an instance of axios

const gettingStartedApi = axios.create({
  baseURL: "https://api.ufaber.com/api/accounts/stud-auth",
  headers: {
    "Content-Type": "application/json",
  },
});

gettingStartedApi.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 401) {
      console.log("Token has expired or user is no longer authenticated");
    }
    return Promise.reject(err);
  }
);

export default gettingStartedApi;