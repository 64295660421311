import { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTimes,
	faUserFriends,
	faCommentAlt,
	faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { addMessage, getRealtimeConversations } from "../../actions/message";
import { connect } from "react-redux";

const MessengerSlider = ({
	setIsMessenger,
	setIsActiveUser,
	isMessenger,
	addMessage,
	messages: { messages },
	users: { username, user_id },
	link_details :{class_link},
	getRealtimeConversations,
}) => {

	const [msg, setMsg] = useState("");
	const [showMsg, setShowMsg] = useState([]);
	const [activeChat, setactiveChat] = useState(false);
	const [people, setPeople] = useState({})
	//get all messages
	useEffect(() => {
		getRealtimeConversations(class_link);

	}, [getRealtimeConversations,class_link]);

	// FOR AUTO SCROLLING
	const messagesEndRef = useRef(null);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		scrollToBottom();
	}, [showMsg]);

	useEffect(() => {
		setactiveChat(isMessenger);
	}, [isMessenger]);

	//FOR SENDING MESSAGE
	const handleChangeMsg = (e) => {
		setMsg(e.target.value);
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			addMessage(msg, username, user_id, class_link);
			setMsg("");
		}
	};

	const handleSendMsg = () => {
		addMessage(msg, username, user_id, class_link);
		setMsg("");
	};
	
	return (
		<MessengerStyle>
			<div className="messenger-header">
				<h3>Meeting details</h3>
				<FontAwesomeIcon
					className="icon"
					icon={faTimes}
					onClick={() => {
						setIsMessenger(false);
						setIsActiveUser(false);
					}}
				/>
			</div>

			<div className="messenger-header-tabs">
				<div
					className={`tab ${!activeChat ? "active" : ""}`}
					onClick={(e) => setactiveChat(!activeChat)}>
					<FontAwesomeIcon className="icon" icon={faUserFriends} />
					<p>People</p>
				</div>

				<div
					className={`tab ${activeChat ? "active" : ""}`}
					onClick={(e) => setactiveChat(!activeChat)}>
					<FontAwesomeIcon className="icon" icon={faCommentAlt} />
					<p>Chat</p>
				</div>
			</div>

			<div className="chat-section">
				{activeChat &&
					messages.slice(0).reverse().map((item, idx) =>(
						<div key={idx} className="chat-block">
							<div className="sender">
								{item.author} <small>{item.timestamp}</small>
							</div>
							<p className="msg">{item.message}</p>
							<div ref={messagesEndRef} />
						</div>
					))}
			</div>

			{activeChat && (
				<div className="send-msg-section">
					<input
						placeholder="Send a message to everyone"
						value={msg}
						onChange={(e) => handleChangeMsg(e)}
						onKeyDown={(e) => handleKeyDown(e)}
					/>
					<FontAwesomeIcon
						className="icon"
						icon={faPaperPlane}
						onClick={handleSendMsg}
					/>
				</div>
			)}
		</MessengerStyle>
	);
};
const MessengerStyle = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	background: #fff;
	height: calc(100vh - 65px);
	width: 350px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	.messenger-header {
		padding: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 10px 0;
		h3 {
			margin: 0;
			font-weight: 400;
		}
		.icon {
			cursor: pointer;
			font-size: 20px;
		}
	}
	.messenger-header-tabs {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #eee;
		.tab {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			color: #555;
			p {
				margin-left: 10px;
			}
			&.active {
				border-bottom: 2px solid #04796a;
				color: #04796a;
			}
			&:hover {
				cursor: pointer;
				background: rgba(0, 121, 107, 0.039);
			}
		}
	}
	.chat-section {
		padding: 20px;
		flex: 1;
		overflow-y: scroll;
		display: flex;
		flex-direction: column-reverse;
		.chat-block {
			margin-bottom: 30px;
			.sender {
				font-weight: 500;
				font-size: 14px;
				small {
					margin-left: 5px;
					font-weight: 300;
					font-size: 12px;
				}
			}
			.msg {
				margin: 0;
				padding-top: 5px;
				color: #555;
				font-size: 14px;
			}
		}
	}
	.send-msg-section {
		padding: 20px;
		border-top: 1px solid #eee;
		border-bottom: 1px solid #eee;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #555;
		input {
			padding: 5px;
			border: none;
			outline: none;
			border-bottom: 1px solid #eee;
			width: 80%;
		}
		.icon {
			cursor: pointer;
			&:hover {
				color: #04796a;
			}
		}
	}
`;

const mapStateToProps = (state) => {
	return {
		messages: state,
		users: state.auth.user,
		link_details : state.auth.link_details
	};
};
export default connect(mapStateToProps, {
	addMessage,
	getRealtimeConversations,
})(MessengerSlider);
