import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const OptionList = ({ options }) => {
    const navigate = useNavigate()
    const onClickevent = (e) => {
        if(e.target.innerText === 'Logout'){
            localStorage.removeItem('token');
            localStorage.removeItem("email");
            localStorage.removeItem("phone");
            localStorage.clear()
            sessionStorage.clear()
            if (window.location.href.split("/")[3] === "demo-dashboard") {
            //    window.location.href = "/demo";  
                navigate("/demo")
            }
            else {
            //    window.location.href = "/myclass";  
            navigate("/myclass")    
            }
           
        }
    }

    
             
	return (
        <OptionListStyle>
            <ul className="option-item" >
                {options.map((option) => {
                    return (
                        <li className="option-item-item" key={option.id} onClick={onClickevent}>
                            <span className="option-item-icon">
                                <FontAwesomeIcon className="icon"  icon={option.icon}  /> 
                               
                            </span>
                            {option.component} 
                            <span className="option-item-title">{option.title}</span>
                        </li>
                    );
                })}
            </ul>
        </OptionListStyle>
	);
};

const OptionListStyle = styled.div`
    .option-item-item{
        height: 45px;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
        padding: 25px 25px 25px 25px;
   
        .option-item-icon{
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            fill: currentColor;
            object-fit: cover;
            margin-left: 0;
            margin-right: 32px;
            width: 24px;
            height: 24px;
            .icon{
                font-size: 22px;
            }
        }
        &:hover {
            cursor: pointer;
            background: #eee;
            border-radius: 15px 15px 0 15px;
        }
    }

`;
export default OptionList;
