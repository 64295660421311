import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import OtpPopUp from "../components/layouts/OtpPopUp";
import styles from "../styles/css/login.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import {
  gettingStartedLogin,
  gettingStartedVerifyOtp,
  validateClassLink,
} from "../actions/auth";
import { validate } from "../utils/validation";
import GoogleLoginAuthHooks from "../components/layouts/GoogleLoginAuthHooks";

const Login = ({ gettingStartedLogin, error, userDetails }) => {
  const [usePhoneEmail, setUsePhoneEmail] = useState(true);
  const [otpPopUp, setOtpPopUp] = useState(false);

  const otpPopUpToggle = () => setOtpPopUp(!otpPopUp);

  const domainName = window.location.hostname.split(".")[1];

  const [loginFormData, setLoginFormData] = useState({
    access_type: "WEB",
    brand: "THE REAL SCHOOL",
    phone: "",
    email: "",
  });

  const buttonSelectionToggle = (e) => {
    e.preventDefault();
    setUsePhoneEmail(!usePhoneEmail);
  };

  const { access_type, brand, phone, email } = loginFormData;

  const onLoginChange = (e) => {
    setLoginFormData({ ...loginFormData, [e.target.name]: e.target.value });
  };

  const validateEmail = validate(loginFormData, "gettingStartedLoginEmail");
  const onLoginSubmitEmail = (e) => {
    e.preventDefault();
    if (Object.keys(validateEmail).length === 0) {
      gettingStartedLogin(access_type, brand, phone, email);
      localStorage.clear();
      localStorage.setItem("email", email);
      otpPopUpToggle();
    } else {
      toast.error(validateEmail.email, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  const validatePhone = validate(loginFormData, "gettingStartedLoginPhone");
  const onLoginSubmitPhone = (e) => {
    e.preventDefault();
    if (Object.keys(validatePhone).length === 0) {
      gettingStartedLogin(access_type, brand, phone, email);
      localStorage.clear();
      localStorage.setItem("phone", phone);
      otpPopUpToggle();
    } else {
      toast.error(validatePhone.phone, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  return (
    <>
      <div className={`${styles.loginWrapper}`}>
        <ToastContainer />
        <div className={`${styles.loginWrapperOne}`}>
          <div className={`${styles.buttonWrapperOne}`}>
            <button
              id={
                usePhoneEmail
                  ? `${styles.selectedButton}`
                  : `${styles.notSelectedButton}`
              }
              onClick={(e) => buttonSelectionToggle(e)}
            >
              Phone
            </button>
            <button
              id={
                usePhoneEmail
                  ? `${styles.notSelectedButton}`
                  : `${styles.selectedButton}`
              }
              onClick={(e) => buttonSelectionToggle(e)}
            >
              Email
            </button>
          </div>

          {usePhoneEmail ? (
            <>
              <form
                className={`${styles.formWrapper}`}
                onSubmit={onLoginSubmitPhone}
              >
                <input
                  type="phone"
                  name="phone"
                  placeholder="Enter Your Phone Number"
                  onChange={onLoginChange}
                  value={loginFormData.phone}
                />
                <input
                  id={`${styles.gettingStartedButton}`}
                  type="submit"
                  value="Getting Started"
                />
                <span>OR</span>
                {/* <button value="Google Login">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#fbc02d"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                    <path
                      fill="#e53935"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    ></path>
                    <path
                      fill="#4caf50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    ></path>
                    <path
                      fill="#1565c0"
                      d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                  </svg>
                </span>
                Continue with Google
              </button>
              <button
                id={`${styles.facebookLoginButton}`}
                value="Facebook Login"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#3F51B5"
                      d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                    ></path>
                    <path
                      fill="#FFF"
                      d="M34.368,25H31v13h-5V25h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H35v4h-2.287C31.104,17,31,17.6,31,18.723V21h4L34.368,25z"
                    ></path>
                  </svg>
                </span>
                Continue with Facebook
              </button> */}
              </form>
              <GoogleLoginAuthHooks />
            </>
          ) : (
            <>
              <form
                className={`${styles.formWrapper}`}
                onSubmit={onLoginSubmitEmail}
              >
                <input
                  type="email"
                  name="email"
                  placeholder="Enter Your E-mail ID"
                  onChange={onLoginChange}
                  value={loginFormData.email}
                />
                <input
                  id={`${styles.gettingStartedButton}`}
                  type="submit"
                  value="Getting Started"
                />
                <span>OR</span>
                {/* <button value="Google Login" type="submit">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#fbc02d"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                    <path
                      fill="#e53935"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    ></path>
                    <path
                      fill="#4caf50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    ></path>
                    <path
                      fill="#1565c0"
                      d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                  </svg>
                </span>
                Continue with Google
              </button>
              <button
                id={`${styles.facebookLoginButton}`}
                value="Facebook Login"
                type="submit"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#3F51B5"
                      d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                    ></path>
                    <path
                      fill="#FFF"
                      d="M34.368,25H31v13h-5V25h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H35v4h-2.287C31.104,17,31,17.6,31,18.723V21h4L34.368,25z"
                    ></path>
                  </svg>
                </span>
                Continue with Facebook
              </button> */}
              </form>
              <GoogleLoginAuthHooks />
            </>
          )}
        </div>
      </div>

      <OtpPopUp show={otpPopUp} close={otpPopUpToggle} />
    </>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.auth.user_login_details,
  auth: state.auth,
  class_link_details: state.auth.link_details,
  error: state.auth.error,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {
  gettingStartedLogin,
  gettingStartedVerifyOtp,
  validateClassLink,
})(Login);
