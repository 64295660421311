import React from "react";
import styled from "styled-components";
import { Text } from "../common/Text";
import Loading from "./Loading";
import Card from "../common/Card";
import styles from "../../styles/css/layouts/regularAndBatchTable.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faClock,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { gettingRegularAndBatchClassDetails } from "../../actions/auth.js";
import { useEffect } from "react";

const RegularAndBatchClassTable = ({
  classDetails,
  gettingRegularAndBatchClassDetails,
  userDetails,
}) => {
    let RegularAndBatchClassDetails = classDetails?.details;
    
  let userPhoneNumber = localStorage.getItem("phone");
  useEffect(() => {
    if (userPhoneNumber != undefined) {
      gettingRegularAndBatchClassDetails(userPhoneNumber);
    }
  }, [userPhoneNumber]);

  console.log(classDetails, "TANBLE");
  const joinMeeting = (meeting_id) => {
    window.location.href = `/meeting/${meeting_id}`;
  };


};

const DemoMessageWrapper = styled.div`
  padding: 30px;
  background: #fff;
  border-radius: 8px;
  margin: 20px;
  p {
    font-size: 22px;
    color: #000;
    font-weight: 500;
    text-align: left;
    margin-bottom: 0px;
  }
`;

const mapStateToProps = (state) => ({
  userDetails: state.auth.user_login_details,
  auth: state.auth,
  classDetails: state.auth.class_details,
  error: state.auth.error,
});

export default connect(mapStateToProps, {
  gettingRegularAndBatchClassDetails,
})(RegularAndBatchClassTable);
