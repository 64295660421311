import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { padZeroToNumber } from "../../utils";
import { bookSlot, postSlotBookQuestion } from "../../actions/slotBooking";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import { SlotBookingBrandColor } from "../../utils/slotBookingBrandUtilDetails";
import { Flexbox } from "../common/FLexbox";
import { TextBox } from "../common/TextBox";

const CountdownPopUp = ({ showPopup, brandName, timestamp }) => {
	let navigate = useNavigate();
	const closeCountdownPopUp = () => {
		showPopup(false);
	};

	//get slot_time_detail from local storage
	const { buttonColor } = SlotBookingBrandColor.getColor(brandName);
	const distance = timestamp - new Date().getTime();
	const [timeData, setTimeData] = useState({
		hours: Math.floor(distance / (1000 * 60 * 60)),
		minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
		seconds: Math.floor((distance % (1000 * 60)) / 1000),
	});

	useEffect(() => {
		const interval = setInterval(() => {
			let { hours, minutes, seconds } = timeData;
			const distance = timestamp - new Date().getTime() - 1;
			hours = Math.floor(distance / (1000 * 60 * 60));

			minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			seconds = Math.floor((distance % (1000 * 60)) / 1000);
			setTimeData({ hours, minutes, seconds });
			if (hours == 0 && minutes == 5 && seconds == 0) {
				clearInterval(interval);
				console.log("clear interval");
				showPopup(false);
			}
		}, 1000);
		if (
			timeData.hours <= 0 &&
			timeData.minutes <= 5 &&
			timeData.seconds <= 0
		) {
			clearInterval(interval);
		}
	}, []);
	console.log("timestamp", timeData);
	return (
		<CountdownPopUpStyled>
			<ToastContainer />
			<div className="pr-head pr-show">
				<div className="pr-inner">
					<p className="p1">
						The Class has not started yet. It will start in ...
					</p>
					{/* Time blocks main wrapper */}
					<Flexbox gap={"12px"} margin={"0px 0px 20px 0px"}>
						{/* Time blocks + label wrapper */}
						<Flexbox direction={"column"}>
							{/* Hour block wrapper */}
							<Flexbox
								gap={"clamp(5px, 6px, 8px)"}
								margin={"0px 0px 7px 0px"}
							>
								<TextBox
									type="text"
									height={"clamp(10px, 40px, 110px)"}
									width={"clamp(10px, 40px, 85px)"}
									value={padZeroToNumber(timeData.hours)}
									padding={"7px 8px"}
									textAlign={"center"}
									className="flex-center"
									fontSize={"20px"}
									margin={"0px 6px 0px 0px"}
									disabled={true}
								/>

								<Flexbox
									direction={"column"}
									align="center"
									gap={"6px"}
								>
									<Circle />
									<Circle />
								</Flexbox>
							</Flexbox>
						</Flexbox>

						<Flexbox direction={"column"}>
							{/* Minutes block wrapper */}
							<Flexbox
								gap={"clamp(5px, 6px, 8px)"}
								margin={"0px 0px 7px 0px"}
							>
								<TextBox
									type="text"
									height={"clamp(10px, 40px, 110px)"}
									width={"clamp(10px, 40px, 85px)"}
									value={padZeroToNumber(timeData.minutes)}
									padding={"7px 8px"}
									textAlign={"center"}
									className="flex-center"
									fontSize={"20px"}
									margin={"0px 6px 0px 0px"}
									disabled={true}
								/>

								<Flexbox
									direction={"column"}
									align="center"
									gap={"6px"}
								>
									<Circle />
									<Circle />
								</Flexbox>
							</Flexbox>
						</Flexbox>

						<Flexbox direction={"column"}>
							{/* Hour block wrapper */}
							<Flexbox
								gap={"clamp(5px, 6px, 8px)"}
								margin={"0px 0px 7px 0px"}
							>
								<TextBox
									type="text"
									height={"clamp(10px, 40px, 110px)"}
									width={"clamp(10px, 40px, 85px)"}
									value={padZeroToNumber(timeData.seconds)}
									padding={"7px 8px"}
									textAlign={"center"}
									className="flex-center"
									fontSize={"20px"}
									disabled={true}
								/>
							</Flexbox>
						</Flexbox>
					</Flexbox>
					<p className="p1">
						Download the Zoom app to get ready for the class.
					</p>
					<div className="last-btn">
						<button
							className="bt1"
							style={{ backgroundColor: buttonColor }}
							onClick={() =>
								(window.location.href =
									"https://zoom.us/download")
							}
						>
							Download Zoom
						</button>
					</div>
				</div>
			</div>
		</CountdownPopUpStyled>
	);
};

const CountdownPopUpStyled = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0, 0, 0, 0.5);
	.pr-show {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 60%;
	}
	.pr-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		background-color: white;
		border-radius: 10px;
		margin: 100px 0;
		height: 100%;
		left: 15px;
		border-radius: 5px;
		padding: 30px 45px;
	}
	.pr-head {
		.p1 {
			font-size: 18px;
			font-weight: 400;
			line-height: 1.2;
			letter-spacing: 0em;
			text-align: left;
		}
	}
	.last-btn {
		width: 100%;
		button {
			width: 100%;
			height: 45px;
			border-radius: 5px;
			padding: 10px 0px;
		}
		.bt1 {
			color: white;
			border: none;
		}
	}
	@media screen and (max-width: 450px) {
		.pr-inner {
			width: 90%;
		}
		.last-btn {
			button {
				width: 100%;
			}
		}
	}
`;
const Circle = styled.span`
	display: block;
	width: 3px;
	height: 3px;
	border-radius: 50%;
	background-color: #000000;
`;

const mapStateToProps = (state) => ({
	questions: state.slotBooking?.slot_booking_questions,
	brandDetails: state.slotBooking?.brand_detail,
	slot_booked: state.slotBooking?.slot_booked,
	slot_booked_question: state.slotBooking?.slot_booked_question,
});

export default connect(mapStateToProps, {
	bookSlot,
	postSlotBookQuestion,
})(CountdownPopUp);
