import React from "react";
import styled from "styled-components";
import img1 from "../../assets/images/img1.png";
import img2 from "../../assets/images/img2.png";
import img3 from "../../assets/images/img3.png";
import img4 from "../../assets/images/img4.png";
import flimg1 from "../../assets/images/flimg1.png";
import flimg2 from "../../assets/images/flimg2.png";
import flimg3 from "../../assets/images/flimg3.png";

const IELTSCorouselContent = () => (
    <React.Fragment>
        <div className="item">
            <img src={img1} alt="" />
            <p className="p1">
                Experience the quality of 1-on-1
                Training
            </p>
        </div>
        <div className="item">
            <img src={img2} alt="" />
            <p className="p2">
                Explore the path to get 8+ band
                in IELTS
            </p>
        </div>
        <div className="item">
            <img src={img3} alt="" />
            <p className="p3">
                Understand your current weakness
            </p>
        </div>
        <div className="item">
            <img src={img4} alt="" />
            <p className="p4">
                Get a suitable study plan to
                achieve your target band
            </p>
        </div>
    </React.Fragment>
)

const FLUENTCorouselContent = () => (
    <React.Fragment>
        <div className="item">
            <p className="fp">
                Dedicated AI Personalities
            </p>
            <p className="fp2" >
                For every professional, culture and situations
            </p>
            <img style={{height:'100%'}} src={flimg1} alt="" />
        </div>
        <div className="item">
            <p className="fp">
                Meaningful Conversations
            </p>
            <p className="fp2" >
                That build contextual vocabulary, fluency & style
            </p>
            <img style={{height:'100%'}} src={flimg2} alt="" />
        </div>
        <div className="item">
            <p className="fp">
                Daily masterclass with expert trainers
            </p>
            <p className="fp2" >
                Attend interactive & informative masterclasses
            </p>
            <img style={{height:'100%'}} src={flimg3} alt="" />
        </div>
    </React.Fragment>
)

// const UPSCPATHSHALACorouselContent = () => (
//   <React.Fragment>
//     <div className="item">
//       <p className="fp">Dedicated AI Personalities</p>
//       <p className="fp2">For every professional, culture and situations</p>
//       <img style={{ height: "100%" }} src={flimg1} alt="" />
//     </div>
//     <div className="item">
//       <p className="fp">Meaningful Conversations</p>
//       <p className="fp2">That build contextual vocabulary, fluency & style</p>
//       <img style={{ height: "100%" }} src={flimg2} alt="" />
//     </div>
//     <div className="item">
//       <p className="fp">Daily masterclass with expert trainers</p>
//       <p className="fp2">Attend interactive & informative masterclasses</p>
//       <img style={{ height: "100%" }} src={flimg3} alt="" />
//     </div>
//   </React.Fragment>
// );

const CorouselContentFactory = (brandName) => {
    const brandToCourouselMapping = {
        'IELTS NINJA':<IELTSCorouselContent/>,
        'THE FLUENTLIFE': <FLUENTCorouselContent />,
        // 'UPSC PATHSHALA':<UPSCPATHSHALACorouselContent/>
    }
    return brandToCourouselMapping[brandName]
}

export default CorouselContentFactory
