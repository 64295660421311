import styled from 'styled-components'
export const HeaderItem = styled.div`
        display: flex;
        align-items: ${props => props.align || 'center'};
        justify-content: ${props => props.justify || 'center'};
        height: ${props => props.height || "50px"};
        border-radius: ${(props) => props.radius || "0px"};
        flex: 1;
        position: relative;
        &.icon-block {
            .icon {
                color: #505357;
                font-size: 22px;
                &.profile {
					font-size: 32px;
					color: #04796a;
				}
            }
            &:hover {
                cursor: pointer;
                background: #eee;
            }
        }
        &.time-block {
			font-size: 22px;
			color: #5f6368;
			min-width: 120px;
			font-weight: 400;
			.icon {
				margin-right: 5px;
			}
		}
`